import React, { useState, useEffect } from 'react';
import {
  Button,
  Flex,
  Spacer,
  Tooltip,
  useClipboard,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Text,
  Textarea,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import { FaClipboard, FaEdit, FaThumbsDown, FaThumbsUp } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { updateRightQuestionToAnswerAsync } from '../../../features/Answers/AnswersAction';

export const AnswerButtons = ({ message, chatId, sessionId, inputMessage }) => {
  const { hasCopied, onCopy } = useClipboard(message);
  const [feedbackGiven, setFeedbackGiven] = useState(false);
  const [thumbsUpTooltip, setThumbsUpTooltip] = useState('');
  const [showThumbsUpTooltip, setShowThumbsUpTooltip] = useState(false);
  const [thumbsDownTooltip, setThumbsDownTooltip] = useState('');
  const [showThumbsDownTooltip, setShowThumbsDownTooltip] = useState(false);
  const [correctionMessage, setCorrectionMessage] = useState(message);
  const [currentChatId, setCurrentChatId] = useState(chatId);

  const projectId = useSelector(state => state.assistants.details?.project_id || "ID");
  const dispatch = useDispatch();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (chatId) {
      setCurrentChatId(chatId);
    }
  }, [chatId]);

  const handleCopy = () => {
    onCopy();
  };

  const sendFeedback = async (feedbackValue) => {
    if (feedbackGiven || !currentChatId) return;

    try {
      await fetch("https://cognitive.nextai.co.in/askgpt/chat/feedback/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          session_id: sessionId,
          feedback: feedbackValue,
          chat_id: currentChatId,
        }),
      });

      setFeedbackGiven(true);
      if (feedbackValue === 1) {
        setThumbsUpTooltip('Your feedback has been received. Thank you!');
        setShowThumbsUpTooltip(true);
      } else {
        setThumbsDownTooltip('Your feedback has been received. Thank you!');
        setShowThumbsDownTooltip(true);
      }
    } catch (error) {
      console.error("Failed to send feedback:", error);
      if (feedbackValue === 1) {
        setThumbsUpTooltip('Failed to send feedback. Please try again.');
        setShowThumbsUpTooltip(true);
      } else {
        setThumbsDownTooltip('Failed to send feedback. Please try again.');
        setShowThumbsDownTooltip(true);
      }
    }

    setTimeout(() => {
      setShowThumbsUpTooltip(false);
      setShowThumbsDownTooltip(false);
    }, 3000);
  };

  const handleSave = async () => {
    try {
      await dispatch(updateRightQuestionToAnswerAsync({ project_id: projectId, question: inputMessage, answer: correctionMessage }));
      toast({
        title: "Correction Made",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      toast({
        title: "Error",
        description: "Unable to add correction.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex mb={1}>
      <Popover placement='right' size='xs' isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Button rightIcon={<FaEdit />} variant='unstyled' size='sm' color='gray.500' onClick={onOpen}>
            Correction
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent width='450px'>
            <PopoverArrow />
            <PopoverHeader border='0'>
              <Text fontWeight='semibold' fontSize='lg'>Correction</Text>
              <Text fontSize='sm'>This Correction will be applied to all similar questions</Text>
            </PopoverHeader>
            <PopoverCloseButton />
            <PopoverBody>
              <Text mb={2} fontSize='sm' fontWeight='semibold'>{inputMessage}</Text>
              <Textarea 
                rows='5' 
                mb={2} 
                value={correctionMessage} 
                fontSize='sm' 
                onChange={(e) => setCorrectionMessage(e.target.value)} 
              />
              <Button colorScheme='blue' size='sm' float='right' onClick={handleSave}>Save</Button>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <Spacer />
      <Tooltip label={thumbsUpTooltip} isOpen={showThumbsUpTooltip} fontSize='xs'>
        <Button variant='unstyled' size='sm' color='gray' _hover={{ color: 'black' }} onClick={() => sendFeedback(1)} isDisabled={feedbackGiven}>
          <FaThumbsUp />
        </Button>
      </Tooltip>
      <Tooltip label={thumbsDownTooltip} isOpen={showThumbsDownTooltip} fontSize='xs'>
        <Button variant='unstyled' size='sm' color='gray' _hover={{ color: 'black' }} onClick={() => sendFeedback(0)} isDisabled={feedbackGiven}>
          <FaThumbsDown />
        </Button>
      </Tooltip>
    </Flex>
  );
};
