import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchCorrections } from '../../apis/HistoryApi';

export const getCorrections = createAsyncThunk(
  'history/getCorrections',
  async ({ projectId, startDate, endDate }, thunkAPI) => {
    try {
      const corrections = await fetchCorrections(projectId, startDate, endDate);
      return corrections;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
