import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL;

export const webIngestionApi = async (data, authToken) => {
  return axios.post(
    `${baseURL}/ingestion/ingest/web/`,
    data,
    {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const directoryIngestionApi = async (projectId, url, authToken) => {
  return axios.post(`${baseURL}/ingestion/ingest/directory/`, { project_id: projectId, url }, {
    headers: { 'Authorization': `Bearer ${authToken}` }
  });
};

export const githubIngestionApi = async (data) => {
    const authToken = localStorage.getItem('token');
  return axios.post(`${baseURL}/ingestion/ingest/github/`, data, {
    headers: { 'Authorization': `Bearer ${authToken}` }
  });
};

export const postgresIngestionApi = async (data) => {
  const authToken = localStorage.getItem('token');
  return axios.post(`${baseURL}/ingestion/ingest/postgres/`, data, {
    headers: { 'Authorization': `Bearer ${authToken}` }
  });
};

export const confluenceIngestionApi = async (data) => {
    const authToken = localStorage.getItem('token');  
    return axios.post(`${baseURL}/ingestion/ingest/confluence`, data, {
      headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json' 
      }
    });
  };

export const notionIngestionApi = async (data) => {
  const authToken = localStorage.getItem('token');
  return axios.post(`${baseURL}/ingestion/ingest/notion`, data, {
    headers: { 'Authorization': `Bearer ${authToken}` }
  });
};

export const shopifyIngestionApi = async (data) => {
  const authToken = localStorage.getItem('token');
  return axios.post(`${baseURL}/ingestion/ingest/shopify/`, data, {
    headers: { 'Authorization': `Bearer ${authToken}` }
  });
};
