import React, { useEffect } from 'react';
import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  useColorMode,
  Button,
  MenuDivider,
  Box,
  Image,
  Text,
} from '@chakra-ui/react';
import { UserCog, SunDim, LogOut } from 'lucide-react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getUserDetails } from '../features/UserData/userActions';
import { useSelector, useDispatch } from 'react-redux';
import LogoLight from '../assets/img/searchgpt.png';
import LogoDark from '../assets/img/whitelogosg.png';
import NotificationsPopover from '../components/NotificationsPopover';

const Topbar = () => {
  const dispatch = useDispatch();
  const { userDetails, loading, error } = useSelector((state) => state.user);
  const { colorMode, toggleColorMode } = useColorMode();
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  useEffect(() => {
    if (!userDetails && !loading && !error) {
      dispatch(getUserDetails());
    }
  }, [dispatch, userDetails, loading, error]);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1rem"
      bg={colorMode === 'light' ? 'gray.200' : 'gray.800'}
      color={colorMode === 'light' ? 'black' : 'white'}
      position="fixed"
      top="0"
      left="0"
      right="0"
      zIndex="10"
      borderBottom="1px solid"
      borderColor={colorMode === 'light' ? 'gray.300' : 'gray.600'}
    >
      {/* Logo Section */}
      <Box mb="0" display="flex" alignItems="center">
        <Image
          src={colorMode === 'light' ? LogoLight : LogoDark}
          alt="SearchGPT Logo"
          height="38px"
          mr="2"
        />
      </Box>
      {/* Menu Section */}
      <Flex align="center">
        <NotificationsPopover />
        <Box mr={3}>
          <Text>Hey, {userDetails?.username}</Text>
        </Box>
        <Menu>
          <MenuButton
            as={Button}
            rounded="full"
            variant="link"
            cursor="pointer"
            minW={0}
          >
            <Avatar size={'md'} name={userDetails?.username} src={userDetails?.profile_image_url} />
          </MenuButton>
          <MenuList>
            <MenuItem>{userDetails?.email}</MenuItem>
            <MenuItem as={RouterLink} to="/settings" icon={<UserCog size={18} />}>
              Settings
            </MenuItem>
            <MenuDivider />
            <MenuItem icon={<SunDim size={18} />} onClick={toggleColorMode}>
              {colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
            </MenuItem>
            <MenuDivider />
            <MenuItem icon={<LogOut size={18} />} onClick={handleLogout}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
  );
};

export default Topbar;
