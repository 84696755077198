import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  HStack,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Lottie from 'react-lottie';
import uploadAnimation from '../../../assets/animation/upload.json';

const validationSchema = Yup.object({
  sourceName: Yup.string().required('Source Name is required'),
});

const DocumentsForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileValid, setIsFileValid] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const [isIngestionInProgress, setIsIngestionInProgress] = useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const projectId = useSelector((state) => state.assistants.details?.project_id || 'Bot_ID');
  const authToken = localStorage.getItem('token');
  const navigate = useNavigate();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: uploadAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const sanitizeFileName = (name) => {
    const nameWithoutExtension = name.replace(/\.[^/.]+$/, "");
    const extension = name.split('.').pop();
    const sanitizedFileName = nameWithoutExtension.replace(/[^a-zA-Z0-9]/g, '') + '.' + extension;
    return sanitizedFileName;
  };

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.pdf, .docx, .doc, .xlsx, .json, .csv, .txt',
    maxSize: 200 * 1024 * 1024
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsValidating(true); // Start validation loader

    if (!selectedFile) {
      toast({
        title: 'Error',
        description: 'Please select a file',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setIsValidating(false);
      return;
    }

    if (selectedFile.size > 200 * 1024 * 1024) {
      toast({
        title: 'Error',
        description: 'File size exceeds 200MB limit',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setIsValidating(false);
      return;
    }
    const sanitizedFileName = sanitizeFileName(selectedFile.name);

    const formData = new FormData();
    formData.append('file', new File([selectedFile], sanitizedFileName, { type: selectedFile.type }));

    try {
      const validationResponse = await axios.post(
        'https://cognitive.nextai.co.in/validation/validate-file/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (validationResponse.status === 200) {
        setIsFileValid(true);
        toast({
          title: 'Success',
          description: 'File validated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } else {
        setIsFileValid(false);
        toast({
          title: 'Error',
          description: validationResponse.data.detail,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsValidating(false); // Stop validation loader
    }
  };

  const handleSubmitData = async (sourceName) => {
    setIsIngestionInProgress(true);
    toast({
      title: 'Data Ingestion Started',
      description: 'This may take a while.',
      status: 'info',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });

    try {
      if (!selectedFile) {
        setIsIngestionInProgress(false);
        toast({
          title: 'Error',
          description: 'Please select a file',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        return;
      }

      const sanitizedFileName = sanitizeFileName(selectedFile.name);

      const presignedResponse = await axios.post(
        'https://cognitive.nextai.co.in/uploader/generate-presigned-url/',
        {
          file_name: sanitizedFileName,
          file_type: selectedFile.type,
        }
      );

      const { url: presignedURL } = presignedResponse.data;

      const uploadResponse = await axios.put(presignedURL, selectedFile, {
        headers: {
          'Content-Type': selectedFile.type,
        },
      });

      if (uploadResponse.status === 200) {
        const fileURL = presignedURL.split('?')[0];
        const jwtToken = localStorage.getItem('token');

        const ingestionResponse = await axios.post(
          'https://cognitive.nextai.co.in/ingestion/ingest/directory/',
          {
            source_name: sourceName,
            project_id: projectId,
            url: fileURL,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        );

        setIsIngestionInProgress(false);
        clearForm();

        if (ingestionResponse.status === 200) {
          toast({
            title: 'Success',
            description: 'Data ingested successfully. Please wait for email confirmation.',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
          onOpen();
        } else {
          toast({
            title: 'Error',
            description: ingestionResponse.data.detail || 'Failed to ingest data.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      }
    } catch (error) {
      setIsIngestionInProgress(false);
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const clearForm = () => {
    setSelectedFile(null);
    setIsFileValid(false);
  };

  const handleDone = () => {
    onClose();
    navigate('/resources');
  };

  const borderColor = useColorModeValue('gray.200', 'gray.700'); // Define borderColor here
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  return (
    <>
    <Box
      maxW={'full'}
      mt={4}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
    >
        <Heading fontSize="2xl" mb={5}>
          Documents Source
        </Heading>
        <Formik
          initialValues={{ sourceName: '' }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <Field name="sourceName">
                {({ field, form }) => (
                  <FormControl isInvalid={form.errors.sourceName && form.touched.sourceName} mt={4}>
                    <FormLabel htmlFor="sourceName">Source Name</FormLabel>
                    <Input {...field} id="sourceName" placeholder="Provide a name for your source" />
                    <FormErrorMessage>{form.errors.sourceName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <FormControl isRequired mt={4}>
                <FormLabel htmlFor="fileUpload">Select File</FormLabel>
                <Box
                  {...getRootProps()}
                  p={4}
                  border="2px dashed"
                  borderColor={borderColor}
                  borderRadius="md"
                  textAlign="center"
                  cursor="pointer"
                >
                  <input {...getInputProps()} id="fileUpload" />
                  {isDragActive ? (
                    <Text>Drop the files here...</Text>
                  ) : selectedFile ? (
                    <Text>{selectedFile.name}</Text>
                  ) : (
                    <Text>No file chosen</Text>
                  )}
                </Box>
              </FormControl>
              <HStack spacing={5} mt={5}>
                {isFileValid ? (
                  <Button
                    type="button"
                    colorScheme="teal"
                    width="full"
                    size={'sm'}
                    onClick={() => handleSubmitData(values.sourceName)}
                    isLoading={isIngestionInProgress}
                  >
                    Submit
                  </Button>
                ) : (
                  <Button
                    size={'sm'}
                    type="submit"
                    colorScheme="teal"
                    width="full"
                    isLoading={isValidating}
                  >
                    Validate File
                  </Button>
                )}
                <Button
                  type="button"
                  width="full"
                  onClick={clearForm}
                  isDisabled={isIngestionInProgress || isValidating}
                  size={'sm'}
                >
                  Reset
                </Button>
              </HStack>
            </Form>
          )}
        </Formik>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uploaded Successfully</ModalHeader>
          <ModalCloseButton />
          <ModalBody mt={-4}>
            <Box mb={4}>
              <Lottie options={defaultOptions} height={150} width={150} />
            </Box>
            <Text color={'black'}>Your data has been uploaded successfully.</Text>
          </ModalBody>
          <ModalFooter>
            <Button size={'sm'} colorScheme="teal" mr={3} onClick={handleDone}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </>
  );
};

export default DocumentsForm;