import { createSlice } from "@reduxjs/toolkit";
import { generateNewApiKeyAsync, deleteApiKeyAsync } from "./apiKeyAction";

const apiKeySlice = createSlice({
  name: "apiKey",
  initialState: {
    apiKey: null,
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetApiKey: (state) => {
      state.apiKey = null;
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(generateNewApiKeyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateNewApiKeyAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.apiKey = action.payload;
        state.success = "API key generated successfully";
      })
      .addCase(generateNewApiKeyAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteApiKeyAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteApiKeyAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.apiKey = null;
        state.success = "API key deleted successfully";
      })
      .addCase(deleteApiKeyAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetApiKey } = apiKeySlice.actions;
export default apiKeySlice.reducer;
