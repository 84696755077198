import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchMetrics = async (projectId, thunkAPI) => {
  if (!projectId) return thunkAPI.rejectWithValue('Project ID is required');
  
  try {
    const response = await axios.get(`${BASE_URL}/metrics/project/${projectId}`);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};

export const fetchGraphData = async (projectId, thunkAPI) => {
  if (!projectId) return thunkAPI.rejectWithValue('Project ID is required');
  
  try {
    const response = await axios.get(`${BASE_URL}/metrics/project/${projectId}/graph`);
    const graphData = response.data;
    const formattedData = {
      labels: graphData.data
        .slice(-10)
        .map((item) => item.date ? new Date(item.date).toLocaleDateString() : "Unknown"),
      data: graphData.data.slice(-10).map((item) => item.chats_count),
      label: "Chat Messages",
    };
    return formattedData;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};