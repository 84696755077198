import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  useColorModeValue,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ingestWeb } from '../../../features/Ingestion/IngestionAction';
import { useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import uploadAnimation from '../../../assets/animation/upload.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: uploadAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const validationSchema = Yup.object({
  sourceName: Yup.string().required('Source Name is required'),
  websiteUrl: Yup.string().url('Invalid URL').required('Website URL is required'),
  maxDepth: Yup.number().min(1).max(4).required('Max Depth is required'),
  includeOnly: Yup.string(),
  exclude: Yup.string(),
});

const WebsiteForm = () => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const cancelRef = useRef();
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const toast = useToast();
  const projectId = useSelector((state) => state.assistants.details?.project_id);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeAlert = () => setIsAlertOpen(false);

  const adjustPaths = (url, paths) => {
    const endsWithSlash = url.endsWith('/');
    return paths
      .split(',')
      .map((str) => str.trim())
      .map((str) => {
        if (str.includes('*')) {
          return endsWithSlash ? str.replace(/^\//, '') : (str.startsWith('/') ? str : `/${str}`);
        }
        return endsWithSlash ? str.replace(/^\//, '') : (str.startsWith('/') ? str : `/${str}`);
      });
  };

  if (!projectId) {
    toast({
      title: 'Error',
      description: 'Project ID is missing. Please check your project configuration.',
      status: 'error',
      duration: 1000,
      isClosable: true,
      position: 'top-right',
    });
    return null;
  }

  return (
    <Box
      maxW={'full'}
      mt={4}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
    >
      <Heading size="lg" mb={4}>
        Website Source
      </Heading>
      <Box mb={0} fontSize={'xs'} color={textColor}>
        Fill the below fields to ingest website data.
      </Box>
      <Divider borderColor={borderColor} mb={6} />
      <Formik
        initialValues={{
          sourceName: '',
          websiteUrl: '',
          maxDepth: 2,
          includeOnly: '',
          exclude: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = {
            projectId: projectId,
            url: values.websiteUrl,
            source_name: values.sourceName,
            max_depth: values.maxDepth,
          };

          if (values.includeOnly && values.exclude) {
            toast({
              title: 'Error',
              description: 'You cannot specify both Include Only and Exclude fields at the same time.',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
            setSubmitting(false);
            return;
          }

          if (values.includeOnly) {
            data.include_only = adjustPaths(values.websiteUrl, values.includeOnly);
          }
          if (values.exclude) {
            data.exclude = adjustPaths(values.websiteUrl, values.exclude);
          }

          dispatch(ingestWeb(data))
            .unwrap()
            .then(() => {
              setSubmitting(false);
              resetForm();
              toast({
                title: 'Success',
                description: 'Website data ingested successfully.',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              });
              setIsAlertOpen(true);
            })
            .catch((error) => {
              setSubmitting(false);
              toast({
                title: 'Error',
                description: error?.response?.data?.detail || error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              });
            });
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <Field name="sourceName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.sourceName && form.touched.sourceName} mt={4}>
                  <FormLabel htmlFor="sourceName">Source Name</FormLabel>
                  <Input {...field} id="sourceName" placeholder="Source Name" />
                  <FormErrorMessage>{form.errors.sourceName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="websiteUrl">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.websiteUrl && form.touched.websiteUrl} mt={4}>
                  <FormLabel htmlFor="websiteUrl">Website URL</FormLabel>
                  <Input {...field} id="websiteUrl" placeholder="Website URL" />
                  <FormErrorMessage>{form.errors.websiteUrl}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="maxDepth">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.maxDepth && form.touched.maxDepth} mt={4}>
                  <FormLabel htmlFor="maxDepth">Max Depth</FormLabel>
                  <Select {...field} id="maxDepth">
                    {[0, 1, 2, 3, 4].map((depth) => (
                      <option key={depth} value={depth}>
                        {depth}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.maxDepth}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="includeOnly">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.includeOnly && form.touched.includeOnly} mt={4}>
                  <FormLabel htmlFor="includeOnly">Include Only (comma-separated)</FormLabel>
                  <Input {...field} id="includeOnly" placeholder="about, faq (Optional)" />
                  <FormErrorMessage>{form.errors.includeOnly}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="exclude">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.exclude && form.touched.exclude} mt={4}>
                  <FormLabel htmlFor="exclude">Exclude (comma-separated)</FormLabel>
                  <Input {...field} id="exclude" placeholder="home, profile (Optional)" />
                  <FormErrorMessage>{form.errors.exclude}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            {errors.includeOnly && errors.exclude && (
              <Box color="red.500" mt={2} mb={4}>
                You cannot specify both Include Only and Exclude fields at the same time.
              </Box>
            )}
            <Flex mt={6} justifyContent="flex-end">
              <Button variant="outline" mr={4} onClick={() => console.log('Cancel')}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlert}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Ingestion Started
            </AlertDialogHeader>
            <AlertDialogBody>
              <Box mb={4}>
                <Lottie options={defaultOptions} height={150} width={150} />
              </Box>
              Website ingestion has started. View the Resource Hub for the status of your ingestion. You will also receive an email confirmation when the ingestion is completed.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={closeAlert}>
                Close
              </Button>
              <Button colorScheme="teal" onClick={() => navigate('/resources')} ml={3}>
                Go to Resources
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default WebsiteForm;
