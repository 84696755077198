import {
    Box,
    Flex,
    Heading,
    HStack,
    Text,
    IconButton,
    useColorModeValue,
    SimpleGrid,
    Divider,
    Image,
  } from '@chakra-ui/react';
  import { ArrowUpRight } from 'lucide-react';
  import Github from "../../assets/img/github_icon.svg"
  import Notion from "../../assets/img/notion_icon.svg"
  import Postgress from "../../assets/img/postgres.svg"
  import Confluence from "../../assets/img/confluence.png"
  import Documents from "../../assets/img/file_icon.svg"
  import Website from "../../assets/img/internet-icon.png"
  import Shopify from "../../assets/img/shopify_icon.svg"
  import { Link } from 'react-router-dom';
  
  const data = [
    {
      name: 'Postgres',
      imageUrl: Postgress,
      email: 'Give your agents tasks and monitor their progress.',
      textColor: 'text-pink-800 dark:text-pink-500',
      bgColor: 'bg-pink-100 dark:bg-pink-500/20',
      href: 'postgres',
    },
  ];
  
  export default function AgentsCard() {
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('gray.800', 'white');
    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const iconColor = useColorModeValue('gray.600', 'gray.400');
    const iconHoverColor = useColorModeValue('gray.800', 'white');
    const countBgColor = useColorModeValue('gray.100', 'gray.700');
  
    // Pre-compute light and dark mode colors
    const lightModeColors = data.map(member => ({
      bgColor: member.bgColor.split(' ')[0].replace('bg-', ''),
      textColor: member.textColor.split(' ')[0].replace('text-', ''),
    }));
  
    const darkModeColors = data.map(member => ({
      bgColor: member.bgColor.split(' ')[1].split('/')[0],
      textColor: member.textColor.split(' ')[1],
    }));
  
    // Use useColorModeValue to select between light and dark mode colors
    const memberColors = useColorModeValue(lightModeColors, darkModeColors);
  
    return (
      <>
        <HStack spacing={2} mt={4} alignItems="center">
          <Heading size="md">Agent Types</Heading>
          <Box
            display="inline-flex"
            alignItems="center"
            justifyContent="center"
            h={6}
            w={6}
            borderRadius="full"
            bg={countBgColor}
            color={textColor}
            fontWeight="medium"
          >
          </Box>
        </HStack>
        <Box mt={4} mb={4}>
          <Divider borderColor={borderColor} />
        </Box>
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={6}>
          {data.map((member, index) => {
            const { bgColor: memberBgColor, textColor: memberTextColor } = memberColors[index];
  
            return (
              <Box
                key={member.name}
                bg={bgColor}
                border="1px solid"
                borderColor={borderColor}
                borderRadius="md"
                p={4}
                position="relative"
                _hover={{ boxShadow: 'md' }}
                as={Link}
                to={member.href}
              >
                <Flex alignItems="center" spaceX={4}>
                  <Image
                    src={member.imageUrl}
                    alt={member.name}
                    h={12}
                    w={12}
                    borderRadius="full"
                    objectFit="cover"
                  />
                  <Box flex="1" ml={4} isTruncated>
                    <Text fontWeight="bold" isTruncated>
                      <a href={member.href}>{member.name}</a>
                    </Text>
                    <Text fontSize="sm" color={textColor} isTruncated>
                      {member.email}
                    </Text>
                  </Box>
                  <IconButton
                    icon={<ArrowUpRight />}
                    as={Link}
                    to={member.href}
                    aria-label="More"
                    variant="ghost"
                    position="absolute"
                    top={4}
                    right={4}
                    color={iconColor}
                    _hover={{ color: iconHoverColor }}
                  />
                </Flex>
              </Box>
            );
          })}
        </SimpleGrid>
      </>
    );
  }
  