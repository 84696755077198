import React, { useState } from 'react';
import { Box, Button, Tooltip, useClipboard, useColorMode } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

const CodeBlock = ({ code }) => {
  const { hasCopied, onCopy } = useClipboard(code);
  const [showTooltip, setShowTooltip] = useState(false);
  const { colorMode } = useColorMode();
  const bgColor = { light: "gray.900", dark: "gray.200" };
  const TextColor = { light: "gray.100", dark: "gray.800" };

  return (
    <Box
      position="relative"
      mt={2}
      p={3}
      overflowX="auto"
      bg={bgColor[colorMode]}
      borderRadius="md"
      fontSize="xs"
      color={TextColor[colorMode]}
      fontFamily="Menlo, monospace"
      style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
    >
      <Tooltip label={hasCopied ? 'Copied!' : 'Copy'} isOpen={showTooltip} fontSize='sm'>
        <Button
          position="absolute"
          top={1}
          right={1}
          size="xs"
          colorScheme="teal"
          onClick={onCopy}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <CopyIcon />
        </Button>
      </Tooltip>
      <code>{code}</code>
    </Box>
  );
};

export default CodeBlock;
