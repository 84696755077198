import React, { useCallback, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  useToast,
  HStack,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AWS from 'aws-sdk';

const validationSchema = Yup.object({
  sourceName: Yup.string().required('Source Name is required'),
  databaseURI: Yup.string().required('Database URI is required'),
});

const PostgresForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileValid, setIsFileValid] = useState(false);
  const [isIngestionInProgress, setIsIngestionInProgress] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const projectId = useSelector((state) => state.assistants.details?.project_id || 'Bot_ID');
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSubmitData = async (values) => {
    setIsIngestionInProgress(true);
    toast({
      title: "Data Ingestion Started",
      description: "This may take a while.",
      status: "info",
      duration: 5000,
      isClosable: true,
      position: "top-right",
    });

    try {
      const s3 = new AWS.S3({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        region: process.env.REACT_APP_AWS_REGION,
      });

      const bucketName = 'searchgpt-files';
      const randomNumber = Math.floor(Math.random() * 1000000000000000);
      const fileNameParts = selectedFile.name.split('.');
      const fileName = `${fileNameParts[0]}_${randomNumber}.${fileNameParts.slice(1).join('.')}`;

      const params = {
        Bucket: bucketName,
        Key: fileName,
        Body: selectedFile,
      };

      const uploadResult = await s3.upload(params).promise();

      const fileURL = uploadResult.Location;

      const jwtToken = localStorage.getItem('token');

      const ingestionResponse = await axios.post(
        "https://cognitive.nextai.co.in/ingestion/ingest/postgres",
        {
          project_id: projectId,
          uri: values.databaseURI,
          url: fileURL,
          source_name: values.sourceName,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      setIsIngestionInProgress(false);
      clearForm();

      if (ingestionResponse.status === 200) {
        toast({
          title: "Success",
          description: "Data ingested successfully. You will receive a confirmation email shortly.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        navigate("/resources");
      } else {
        toast({
          title: "Error",
          description: ingestionResponse.data.detail || "Failed to ingest data.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      setIsIngestionInProgress(false);
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  const clearForm = () => {
    setSelectedFile(null);
    setIsFileValid(false);
  };

  return (
    <Box
      maxW={'full'}
      mt={4}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
    >
      <Heading size="lg" mb={4}>
        Postgres Source
      </Heading>
      <Box mb={0} fontSize={'xs'} color={textColor}>
        Fill the below fields to ingest Postgres data.
      </Box>
      <Divider borderColor={borderColor} mb={6} />
      <Formik
        initialValues={{
          sourceName: '',
          databaseURI: '',
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (!selectedFile) {
            toast({
              title: "Error",
              description: "Please select a file",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            setSubmitting(false);
            return;
          }

          if (!selectedFile.name.endsWith(".json")) {
            toast({
              title: "Error",
              description: "Please select a JSON file",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            setSubmitting(false);
            return;
          }

          setIsIngestionInProgress(true);

          const formData = new FormData();
          formData.append("file", selectedFile);

          try {
            const validationResponse = await axios.post(
              "https://cognitive.nextai.co.in/validation/validate-file/",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            if (validationResponse.status === 200) {
              setIsFileValid(true);
              toast({
                title: "Success",
                description: "File validated successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
            } else {
              setIsFileValid(false);
              toast({
                title: "Error",
                description: validationResponse.data.detail,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              setIsIngestionInProgress(false);
              setSubmitting(false);
              return;
            }
          } catch (error) {
            console.error(error);
            toast({
              title: "Error",
              description: "An error occurred during file validation",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            setIsIngestionInProgress(false);
            setSubmitting(false);
            return;
          }

          await handleSubmitData(values);

          setSubmitting(false);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="sourceName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.sourceName && form.touched.sourceName} mt={4}>
                  <FormLabel htmlFor="sourceName">Source Name</FormLabel>
                  <Input {...field} id="sourceName" placeholder="Source Name" />
                  <FormErrorMessage>{form.errors.sourceName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="databaseURI">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.databaseURI && form.touched.databaseURI} mt={4}>
                  <FormLabel htmlFor="databaseURI">Database URI</FormLabel>
                  <Input {...field} id="databaseURI" placeholder="Database URI" />
                  <FormErrorMessage>{form.errors.databaseURI}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <FormControl mt={4}>
              <FormLabel htmlFor="fileUpload">Select File</FormLabel>
              <Box
                {...getRootProps()}
                p={4}
                border="2px dashed"
                borderColor={borderColor}
                borderRadius="md"
                textAlign="center"
                cursor="pointer"
              >
                <input {...getInputProps()} id="fileUpload" />
                {isDragActive ? (
                  <Text>Drop the files here...</Text>
                ) : selectedFile ? (
                  <Text>{selectedFile.name}</Text>
                ) : (
                  <Text>No file chosen</Text>
                )}
              </Box>
            </FormControl>
            <HStack spacing={5} mt={5}>
              {isFileValid ? (
                <Button
                  type="button"
                  colorScheme="teal"
                  width="full"
                  onClick={() => handleSubmitData()}
                  isLoading={isIngestionInProgress}
                >
                  {isIngestionInProgress ? 'Ingesting...' : 'Submit'}
                </Button>
              ) : (
                <Button type="submit" size={'sm'} colorScheme="teal" width="full">
                  Validate File
                </Button>
              )}
              <Button
                type="button"
                width="full"
                onClick={() => {
                  setSelectedFile(null);
                  setIsFileValid(false);
                }}
                isDisabled={isIngestionInProgress}
                size={'sm'}
              >
                Reset
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PostgresForm;
