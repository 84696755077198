import React from 'react';
import { Box, SimpleGrid, Text, Heading, Button, VStack, HStack, Badge, Flex, useColorModeValue } from '@chakra-ui/react';
import { ArrowRightIcon } from '@chakra-ui/icons';
import { FaSlack, FaDiscord, FaGlobe, FaWhatsapp, FaShopify, FaSalesforce } from 'react-icons/fa';
import { TbApi } from "react-icons/tb";

const templates = [
  {
    title: 'Slack',
    description: 'AI Assistant connection to this bot',
    icon: <FaSlack />,
    upcoming: false,
    link: 'https://docs.searchgpt.dev/Integrations/Slack',
  },
  {
    title: 'Discord',
    description: 'AI Assistant connection to this bot',
    icon: <FaDiscord />,
    upcoming: false,
    link: 'https://docs.searchgpt.dev/Integrations/Discord',
  },
  {
    title: 'Website',
    description: 'AI Assistant connection to this bot',
    icon: <FaGlobe />,
    upcoming: false,
    link: 'https://docs.searchgpt.dev/Data-Sources/Website',
  },
  {
    title: 'WhatsApp',
    description: 'AI Assistant connection to this bot',
    icon: <FaWhatsapp />,
    upcoming: true,
    link: '',
  },
  {
    title: 'Shopify',
    description: 'AI Assistant connection to this bot',
    icon: <FaShopify />,
    upcoming: false,
    link: 'https://docs.searchgpt.dev/Integrations/Shopify',
  },
  {
    title: 'APIs',
    description: 'AI Assistant connection to this bot',
    icon: <TbApi />,
    upcoming: false,
    link: 'https://docs.searchgpt.dev/Introduction/meet-searchgpt',
  },
  {
    title: 'Salesforce',
    description: 'AI Assistant connection to this bot',
    icon: <FaSalesforce />,
    upcoming: true,
    link: '',
  },
];

const IntegrationPage = () => {
  const borderColor = useColorModeValue('gray.400', 'gray.700');

  return (
    <Box p={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading>Our Integration templates</Heading>
        <a href="https://form.jotform.com/240412348109449" target="_blank" rel="noopener noreferrer">
          <Button colorScheme="teal">Request Integration</Button>
        </a>
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
        {templates.map((template, index) => (
          <Box
            key={index}
            p={5}
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="md"
            position="relative"
          >
            {template.upcoming && (
              <Badge position="absolute" top={2} right={2} colorScheme="red">
                Upcoming
              </Badge>
            )}
            <HStack mb={4}>
              <Text fontSize="2xl">{template.icon}</Text>
              <VStack align="start">
                <Heading size="md">{template.title}</Heading>
                <Text>{template.description}</Text>
              </VStack>
            </HStack>
            <a href={template.link} target="_blank" rel="noopener noreferrer">
              <Button size={'xs'} rightIcon={<ArrowRightIcon />} variant="outline" colorScheme="cyan.200">
                Use template
              </Button>
            </a>
          </Box>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default IntegrationPage;