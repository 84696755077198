import React, { useState, useEffect } from 'react';
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorMode,
} from "@chakra-ui/react";
import { Field, Formik, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { ArrowRightIcon, EyeIcon, EyeOffIcon } from "lucide-react";
import GoogleIcon from "../../assets/img/google.svg";
import axios from 'axios';
import Lottie from 'react-lottie';
import animationData from '../../assets/animation/Signup.json';
import { useAuth } from '../../contexts/AuthContext';
import upgradeAnimation from '../../assets/animation/email.json';

export default function SignUp() {
  const [isVisible, { toggle: toggleVisibility }] = useBoolean(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [errorMessage, setErrorMessage] = useState('');
  const { login } = useAuth();
  const { colorMode } = useColorMode();

  const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: upgradeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = "https://cognitive.nextai.co.in/auth/login/google";
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    if (token) {
      localStorage.setItem("token", token);
      login(token);
      navigate('/get-started', { replace: true });
    }
  }, [location, login, navigate]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Flex direction={"row-reverse"} justifyContent={"space-between"}>
      <Stack bg={'white'} borderLeft={"1px solid #f4f4f4"} flex={[0, 0.5]} position="relative">
        <Box p={8} borderRadius="md" >
          <Heading mt={10} mb={2} fontWeight="bold" textAlign="center" color="black">Welcome!</Heading>
          <Lottie options={defaultOptions} height={400} width={400} />
          <Text mt={4} fontWeight="bold" fontSize="lg" textAlign="center" color="black">
            We are excited to have you onboarded!
          </Text>
          <Text mt={2} fontSize="md" textAlign="center" color="black">
            Seamlessly manage and integrate your AI-driven solutions.
          </Text>
        </Box>
      </Stack>
      <Center height={"100vh"} flex={[1, 0.6]}>
        <Stack
          alignItems={"center"}
          borderRadius={8}
          py={8}
          px={[2, 12]}
          w={["80vw", "35vw"]}
        >
          <Heading w={"full"} fontSize={28} variant={"h3"}>
            Create an account
          </Heading>
          <Formik
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                const response = await axios.post(`https://cognitive.nextai.co.in/auth/signup`, {
                  username: values.name,
                  organization: values.organization,
                  email: values.email,
                  password: values.password,
                });
                onOpen();
              } catch (error) {
                const errorDetails = error.response.data.detail;
                if (Array.isArray(errorDetails)) {
                  setErrorMessage(errorDetails.map(e => e.msg).join(', '));
                } else {
                  setErrorMessage(errorDetails || 'An unexpected error occurred');
                }
                setSubmitting(false);
              }
            }}
            initialValues={{
              name: "",
              organization: "",
              email: "",
              password: "",
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form
                style={{
                  width: "100%",
                }}
              >
                <Stack gap={4}>
                  <FormControl isInvalid={!!errors.name && touched.name}>
                    <FormLabel fontSize={"sm"}>Name</FormLabel>
                    <Field
                      fontSize={"sm"}
                      validate={(value) => {
                        let error;
                        if (!value) {
                          return "Name is required";
                        }
                        return error;
                      }}
                      as={Input}
                      name="name"
                      rounded={6}
                      placeholder="Enter your name"
                      borderColor="teal.500"
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.organization && touched.organization}>
                    <FormLabel fontSize={"sm"}>Organization</FormLabel>
                    <Field
                      fontSize={"sm"}
                      validate={(value) => {
                        let error;
                        if (!value) {
                          return "Organization name is required";
                        }
                        return error;
                      }}
                      as={Input}
                      name="organization"
                      rounded={6}
                      placeholder="NextAI"
                      borderColor="teal.500"
                    />
                    <FormErrorMessage>{errors.organization}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.email && touched.email}>
                    <FormLabel fontSize={"sm"}>Email address</FormLabel>
                    <Field
                      fontSize={"sm"}
                      validate={(value) => {
                        let error;
                        if (!value) {
                          return "Email address is required";
                        }
                        if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
                        ) {
                          return "Invalid email address";
                        }
                        return error;
                      }}
                      as={Input}
                      name="email"
                      rounded={6}
                      placeholder="Enter your email address"
                      type="email"
                      borderColor="teal.500"
                    />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={!!errors.password && touched.password}>
                    <FormLabel fontSize={"sm"}>Password</FormLabel>
                    <InputGroup>
                      <Field
                        fontSize={"sm"}
                        validate={(value) => {
                          let error;
                          if (!value) {
                            error = "Password is required";
                          }
                          return error;
                        }}
                        as={Input}
                        name="password"
                        rounded={6}
                        placeholder="Enter password"
                        type={isVisible ? "text" : "password"}
                        borderColor="teal.500"
                      />
                      <InputRightElement>
                        <IconButton
                          variant={"none"}
                          size={"sm"}
                          aria-label=""
                          icon={
                            isVisible ? (
                              <EyeOffIcon size={20} />
                            ) : (
                              <EyeIcon size={20} />
                            )
                          }
                          onClick={toggleVisibility}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{errors.password}</FormErrorMessage>
                  </FormControl>
                </Stack>
                {errorMessage && (
                  <Alert status="error" mt={4} rounded={6}>
                    <AlertIcon />
                    <AlertTitle></AlertTitle>
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                )}
                <Button
                  fontSize={"sm"}
                  mt={4}
                  variant={"solid"}
                  rightIcon={<ArrowRightIcon size={16} />}
                  colorScheme={"teal"}
                  rounded={8}
                  w={"full"}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
          <Box w={"full"} position="relative" padding="5">
            <Divider borderColor="gray.500" />
            <AbsoluteCenter bg={colorMode === 'light' ? 'gray.200' : 'gray.800'} px='4'>
              OR
            </AbsoluteCenter>
          </Box>
          <Button
            fontSize={"sm"}
            alignItems={"center"}
            leftIcon={
              <Image verticalAlign={"middle"} src={GoogleIcon} boxSize={4} />
            }
            variant={"outline"}
            rounded={8}
            w={"full"}
            colorScheme="teal"
            onClick={handleGoogleLogin}
          >
            Sign in with Google
          </Button>
          <Text mt={4} fontSize={"sm"}>
            Already have an account?{" "}
            <Link
              href="/login"
              textDecor={"underline"}
              color="teal.500"
            >
              Login now
            </Link>
          </Text>
        </Stack>
      </Center>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Email Verification</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Box mb={4}>
              <Lottie options={defaultOption} height={150} width={150} />
            </Box>
            <Text>Please verify your email to complete the signup process.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="teal" mr={3} onClick={() => navigate('/login')}>
              Login
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}
