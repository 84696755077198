import { createSlice } from '@reduxjs/toolkit';
import { fetchEvaluationMetrics } from './EvalutionAction';

const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState: {
    metrics: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvaluationMetrics.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchEvaluationMetrics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.metrics = action.payload;
      })
      .addCase(fetchEvaluationMetrics.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default evaluationSlice.reducer;
