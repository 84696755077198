import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react';
import { FaChevronLeft } from 'react-icons/fa';
import { SlideProducts } from './SlideProducts';
import { InputArea } from './InputArea';

export const ProductListingModal = ({ isOpen, onClose, products: initialProducts }) => {
    const [inputMessage, setInputMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [products, setProducts] = useState(initialProducts);
    const ref = useRef(null);
    const toast = useToast();

    useEffect(() => {
        setProducts(initialProducts); // Initialize with the products passed as props
    }, [initialProducts]);

    const sendMessage = async () => {
        if (inputMessage.trim() === '') return;
        setLoading(true);
        
        try {
            const response = await fetch("https://cognitive.nextai.co.in/demo/message/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    project_id: "73",
                    session_id: "12345678",
                    user_message: inputMessage.trim(),
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok.");
            }

            const responseData = await response.json();
            
            if (responseData.intermediate_steps && responseData.intermediate_steps.length > 0) {
                const dataString = responseData.intermediate_steps[0][1].sources;
                try {
                    const parsedProducts = JSON.parse(dataString);
                    setProducts(parsedProducts); // Update the products to display
                } catch (error) {
                    console.error("Error parsing products data:", error);
                    toast({
                        title: "Parsing Error",
                        description: "Failed to parse products data.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                }
            } else {
                toast({
                    title: "No products found",
                    description: "No new products data received from the server.",
                    status: "warning",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } catch (error) {
            console.error("Fetching Error:", error);
            toast({
                title: "Fetching Error",
                description: "An error occurred while fetching products.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
            setInputMessage(''); // Clear the input field
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="full">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <Flex align="center">
                        <Button onClick={onClose} p={0} borderRadius="55%" variant="outline" colorScheme="red" mr={5}>
                            <FaChevronLeft />
                        </Button>
                        <Heading size="md" fontWeight="400">More Products</Heading>
                    </Flex>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box ref={ref}>
                        {loading ? <p>Loading...</p> : <SlideProducts products={products} />}
                        {/* <InputArea 
                            inputMessage={inputMessage} 
                            setInputMessage={setInputMessage} 
                            sendMessage={sendMessage} 
                            placeholder="Ask follow-up questions here..."
                        /> */}
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
