import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => localStorage.getItem('token');

const getAuthHeaders = () => ({
  'Authorization': `Bearer ${getAuthToken()}`,
  'Content-Type': 'application/json',
});

export const getUserDetailsApi = async () => {
  return axios.get(
    `${BASE_URL}/assistant/user-details`,
    { headers: getAuthHeaders() }
  );
};

export const updateUserDetailsApi = async (data) => {
  return axios.patch(
    `${BASE_URL}/assistant/update-user-details`,
    data,
    { headers: getAuthHeaders() }
  );
};
