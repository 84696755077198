import { createAsyncThunk } from '@reduxjs/toolkit';
import * as ingestionApi from '../../apis/InjestionApi';

export const ingestWeb = createAsyncThunk(
  'ingestion/web',
  async ({ projectId, url, source_name, max_depth, include_only, exclude }, thunkAPI) => {
    try {
      const authToken = localStorage.getItem('token');
      const data = {
        project_id: projectId,
        source_name,
        url,
        max_depth,
        include_only,
        exclude,
      };
      const response = await ingestionApi.webIngestionApi(data, authToken);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.detail || error.message);
    }
  }
);

export const ingestDirectory = createAsyncThunk('ingestion/directory', async ({ projectId, url, authToken }, thunkAPI) => {
  try {
    const response = await ingestionApi.directoryIngestionApi(projectId, url, authToken);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const ingestGithub = createAsyncThunk('ingestion/github', async ({ data, authToken }, thunkAPI) => {
  try {
    const response = await ingestionApi.githubIngestionApi(data, authToken);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const ingestPostgres = createAsyncThunk('ingestion/postgres', async ({ data, authToken }, thunkAPI) => {
  try {
    const response = await ingestionApi.postgresIngestionApi(data, authToken);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const ingestConfluence = createAsyncThunk('ingestion/confluence', async (formData, thunkAPI) => {
    try {
      const response = await ingestionApi.confluenceIngestionApi(formData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.detail || 'Failed to ingest data');
    }
  });
  

export const ingestNotion = createAsyncThunk('ingestion/notion', async ({ data, authToken }, thunkAPI) => {
  try {
    const response = await ingestionApi.notionIngestionApi(data, authToken);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const ingestShopify = createAsyncThunk('ingestion/shopify', async ({ data, authToken }, thunkAPI) => {
  try {
    const response = await ingestionApi.shopifyIngestionApi(data, authToken);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response?.data?.message || error.message);
  }
});