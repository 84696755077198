import React, { createContext, useState, useEffect, useContext } from 'react';
import {jwtDecode} from 'jwt-decode';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../features/UserData/userActions';
import { useLocation } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    if (token) {
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
      dispatch(getUserDetails()).finally(() => {
        setIsLoading(false);
        // Set URL to / and reload the page to fetch user details
        window.history.replaceState({}, document.title, "/");
        window.location.reload();
      });
    } else {
      const storedToken = localStorage.getItem('token');
      if (storedToken && isTokenValid(storedToken)) {
        setIsAuthenticated(true);
        dispatch(getUserDetails()).finally(() => setIsLoading(false));
      } else {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    }
  }, [location.search, dispatch]);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    dispatch(getUserDetails());
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  const isTokenValid = (token) => {
    try {
      const { exp } = jwtDecode(token);
      return Date.now() < exp * 1000;
    } catch (e) {
      return false;
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, isLoading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
