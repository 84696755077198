import React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ingestGithub } from '../../../features/Ingestion/IngestionAction';

const validationSchema = Yup.object({
  sourceName: Yup.string().required('Source Name is required'),
  githubToken: Yup.string().required('Github Token is required'),
  ownerName: Yup.string().required('Owner Name is required'),
  repository: Yup.string().required('Repository is required'),
  branchName: Yup.string().required('Branch Name is required'),
  filterFileExtensions: Yup.string().required('Filter File Extensions are required'),
});

const GithubForm = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const isIngestionInProgress = useSelector((state) => state.ingestion.loading);
  const projectId = useSelector((state) => state.assistants.details?.project_id || 'Bot_ID');

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const input = {
      project_id: projectId,
      github_token: values.githubToken,
      source_name: values.sourceName,
      owner: values.ownerName,
      repo: values.repository,
      branch: values.branchName,
      filter_file_extensions: values.filterFileExtensions.split(',').map((ext) => ext.trim()),
    };

    dispatch(ingestGithub({ data: input }))
      .unwrap()
      .then((response) => {
        resetForm();
        toast({
          title: 'Data Ingested',
          description: 'Your GitHub data has been successfully ingested.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        navigate('/resources');
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: 'Data Ingestion Failed',
          description: error.message || 'An error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      });
    setSubmitting(false);
  };

  return (
    <Box
      maxW={'full'}
      mt={4}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
    >
      <Heading size="lg" mb={4}>
        GitHub Source
      </Heading>
      <Box mb={0} fontSize={'xs'} color={textColor}>
        Fill the below fields to ingest GitHub data.
      </Box>
      <Divider borderColor={borderColor} mb={6} />
      <Formik
        initialValues={{
          sourceName: '',
          githubToken: '',
          ownerName: '',
          repository: '',
          branchName: '',
          filterFileExtensions: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="sourceName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.sourceName && form.touched.sourceName} mt={4}>
                  <FormLabel htmlFor="sourceName">Source Name</FormLabel>
                  <Input {...field} id="sourceName" placeholder="Source Name" />
                  <FormErrorMessage>{form.errors.sourceName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="githubToken">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.githubToken && form.touched.githubToken} mt={4}>
                  <FormLabel htmlFor="githubToken">GitHub Token</FormLabel>
                  <Input {...field} id="githubToken" placeholder="GitHub Token" />
                  <FormErrorMessage>{form.errors.githubToken}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="ownerName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.ownerName && form.touched.ownerName} mt={4}>
                  <FormLabel htmlFor="ownerName">Owner Name</FormLabel>
                  <Input {...field} id="ownerName" placeholder="Owner Name" />
                  <FormErrorMessage>{form.errors.ownerName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="repository">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.repository && form.touched.repository} mt={4}>
                  <FormLabel htmlFor="repository">Repository</FormLabel>
                  <Input {...field} id="repository" placeholder="Repository" />
                  <FormErrorMessage>{form.errors.repository}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="branchName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.branchName && form.touched.branchName} mt={4}>
                  <FormLabel htmlFor="branchName">Branch Name</FormLabel>
                  <Input {...field} id="branchName" placeholder="Branch Name" />
                  <FormErrorMessage>{form.errors.branchName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="filterFileExtensions">
              {({ field, form }) => (
                <FormControl
                  isInvalid={form.errors.filterFileExtensions && form.touched.filterFileExtensions}
                  mt={4}
                >
                  <FormLabel htmlFor="filterFileExtensions">Filter File Extensions</FormLabel>
                  <Input {...field} id="filterFileExtensions" placeholder="Filter File Extensions" />
                  <FormErrorMessage>{form.errors.filterFileExtensions}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex mt={6} justifyContent="flex-end">
              <Button variant="outline" mr={4} onClick={() => console.log('Cancel')}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="blue" isLoading={isSubmitting || isIngestionInProgress}>
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default GithubForm;
