import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { updateAgentConfig } from "../../features/Agents/AgentsAction";

const EditAgentModal = ({ isOpen, onClose, agent }) => {
  const [uri, setUri] = useState(agent.uri);
  const [schemaTemplate, setSchemaTemplate] = useState(agent.schemaTemplate);
  const [textToSqlTemplate, setTextToSqlTemplate] = useState(
    agent.textToSqlTemplate
  );
  const [sqlOutputPrompt, setSqlOutputPrompt] = useState(agent.sqlOutputPrompt);
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("token");
  const projectId = useSelector(
    (state) => state.assistants.details?.project_id || "Bot_ID"
  );
  const toast = useToast();

  useEffect(() => {
    if (agent) {
      setUri(agent.uri);
      setSchemaTemplate(agent.schemaTemplate);
      setTextToSqlTemplate(agent.textToSqlTemplate);
      setSqlOutputPrompt(agent.sqlOutputPrompt);
    }
  }, [agent]);

  const handleUpdate = () => {
    const updatedAgent = {
      agent_id: agent.agentId,
      project_id: projectId,
      agent_type: "postgres",
      postgres_uri: uri,
      extract_info_from_schema_template: schemaTemplate,
      text_to_sql_template: textToSqlTemplate,
      sql_to_final_output_prompt: sqlOutputPrompt,
    };

    dispatch(
      updateAgentConfig({
        agentId: agent.agentId,
        data: updatedAgent,
        authToken,
      })
    )
      .unwrap()
      .then(() => {
        toast({
          title: "Success",
          description: "Agent updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        onClose();
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Agent</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>URI</FormLabel>
            <Input
              _focus={{
                borderColor: "purple.300",
              }}
              mt={-5}
              size="sm"
              borderRadius="10"
              value={uri}
              onChange={(e) => setUri(e.target.value)}
            />
          </FormControl>
          <FormControl mt={2}>
            <FormLabel>Schema Template</FormLabel>
            <Input
              _focus={{
                borderColor: "purple.300",
              }}
              mt={-5}
              size="sm"
              borderRadius="10"
              value={schemaTemplate}
              onChange={(e) => setSchemaTemplate(e.target.value)}
            />
          </FormControl>
          <FormControl mt={2}>
            <FormLabel>Text to SQL Template</FormLabel>
            <Input
              _focus={{
                borderColor: "purple.300",
              }}
              mt={-5}
              size="sm"
              borderRadius="10"
              value={textToSqlTemplate}
              onChange={(e) => setTextToSqlTemplate(e.target.value)}
            />
          </FormControl>
          <FormControl mt={2}>
            <FormLabel>SQL Output Prompt</FormLabel>
            <Input
              _focus={{
                borderColor: "purple.300",
              }}
              mt={-5}
              size="sm"
              borderRadius="10"
              value={sqlOutputPrompt}
              onChange={(e) => setSqlOutputPrompt(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            size={"sm"}
            colorScheme="purple"
            mr={3}
            onClick={handleUpdate}
          >
            Update
          </Button>
          <Button size={'sm'} variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditAgentModal;
