import React, { useEffect } from 'react';
import { Box, Card, Avatar, HStack, Text, Img, useColorMode } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';  
import { SearchPanel } from '../../components/Widget/Chat/SearchmatePanel';
import { PrivatePanel } from '../../components/Widget/Chat/PrivatePanel';
import { getAgentConfig } from '../../features/Agents/AgentsAction';

export const Playground = () => {
  const dispatch = useDispatch();
  const assistantName = useSelector(state => state.assistants.details?.name || "Default Name");
  const assistantImage = useSelector(state => state.assistants.details?.widget_image || "Bot_Avatar");
  const assistantType = useSelector(state => state.assistants.details?.type || "askgpt");
  const projectId = useSelector((state) => state.assistants.details?.project_id || "Bot_ID");
  const authToken = localStorage.getItem("token");
  const { colorMode } = useColorMode();

  useEffect(() => {
    dispatch(getAgentConfig({ projectId, authToken }));
  }, [dispatch, projectId, authToken]);

  return (
    <Box py={3} px={4}>
      <Card bg={colorMode === 'light' ? 'gray.100' : 'gray.800'} p={5}>
        <Avatar src={assistantImage} alignSelf='center' />
        <HStack alignSelf='center' mb={10}>
          <Text>{assistantName}</Text>
          <Img src="https://widgetresources.s3.ap-south-1.amazonaws.com/VerifiedBadge.svg" height='15px' width='15px' />
        </HStack>
        {assistantType === 'askgpt' || assistantType === 'agent' ? <PrivatePanel /> : <SearchPanel />}
      </Card>
    </Box>
  );
};
