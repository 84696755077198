import React from 'react';
import {
  Box,
  Heading,
  Button,
  Flex,
  Text,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  Badge,
  Checkbox,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon, SettingsIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FaDownload, FaPlus } from 'react-icons/fa';

const invoices = [
  // Example data
  // { id: 'INV-0001', client: 'Ethan Mitchell', email: 'ethanmitchell@gmail.com', date: '20 Nov, 2023', amount: '$632', status: 'Paid' },
  // { id: 'INV-0002', client: 'Adrian Carter', email: 'adriancarter@gmail.com', date: '21 Nov, 2023', amount: '$632', status: 'Pending' },
  // { id: 'INV-0003', client: 'Marcus Turner', email: 'marcusturner@gmail.com', date: '22 Nov, 2023', amount: '$632', status: 'Unpaid' },
];

const statusColor = {
  Paid: 'green',
  Pending: 'yellow',
  Unpaid: 'red',
};

const InvoicesPage = () => {
  const tableHeaderBg = useColorModeValue('gray.100', 'gray.700');

  return (
    <Box p={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading>Invoices</Heading>
        <HStack spacing={4}>
          <Button leftIcon={<FaDownload />} colorScheme="gray" variant="outline">
            Download
          </Button>
        </HStack>
      </Flex>

      <HStack spacing={4} mb={4}>
        <Button variant="ghost">All Invoices</Button>
        <Button variant="ghost">Drafts</Button>
        <Button variant="ghost">Unpaid</Button>
        <Button variant="ghost">Paid</Button>
        <Button variant="ghost">Pending</Button>
      </HStack>

      <Flex mb={4}>
        <InputGroup w="30%">
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input type="text" placeholder="Search..." />
        </InputGroup>
        <Spacer />
        <Button leftIcon={<SettingsIcon />} variant="outline" colorScheme="gray">
          Filter
        </Button>
      </Flex>

      <Box borderWidth="1px" borderRadius="md" borderColor="gray.400" overflow="hidden">
        {invoices.length === 0 ? (
          <Flex justifyContent="center" alignItems="center" p={6}>
            <Text>No invoices found</Text>
          </Flex>
        ) : (
          <Table variant="simple">
            <Thead bg={tableHeaderBg}>
              <Tr>
                <Th>
                  <Checkbox />
                </Th>
                <Th>Invoice ID</Th>
                <Th>Client</Th>
                <Th>Email</Th>
                <Th>Date</Th>
                <Th>Amount</Th>
                <Th>Status</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {invoices.map((invoice) => (
                <Tr key={invoice.id}>
                  <Td>
                    <Checkbox />
                  </Td>
                  <Td>{invoice.id}</Td>
                  <Td>{invoice.client}</Td>
                  <Td>{invoice.email}</Td>
                  <Td>{invoice.date}</Td>
                  <Td>{invoice.amount}</Td>
                  <Td>
                    <Badge colorScheme={statusColor[invoice.status]}>{invoice.status}</Badge>
                  </Td>
                  <Td>
                    <Menu>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant="ghost">
                        Actions
                      </MenuButton>
                      <MenuList>
                        <MenuItem>Edit</MenuItem>
                        <MenuItem>Delete</MenuItem>
                      </MenuList>
                    </Menu>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}
      </Box>
    </Box>
  );
};

export default InvoicesPage;
