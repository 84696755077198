import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ingestShopify } from '../../../features/Ingestion/IngestionAction';

const validationSchema = Yup.object({
  sourceName: Yup.string().required('Source Name is required'),
  shopifyStore: Yup.string().required('Shopify Store is required'),
  apiToken: Yup.string().required('API Token is required'),
});

const ShopifyForm = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const { loading, error, success } = useSelector((state) => state.ingestion);
  const projectId = useSelector(
    (state) => state.assistants.details?.project_id || "Bot_ID"
  );

  useEffect(() => {
    if (success) {
      toast({
        title: "Ingestion started",
        description: "The Shopify source ingestion has started successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/resources");
    }
  }, [success, toast, navigate]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Ingestion failed",
        description: error || "An error occurred during ingestion.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [error, toast]);

  return (
    <Box
      maxW={'full'}
      mt={4}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
    >
      <Heading size="lg" mb={4}>
        Shopify Source
      </Heading>
      <Box mb={0} fontSize={'xs'} color={textColor}>
        Fill the below fields to ingest Shopify data.
      </Box>
      <Divider borderColor={borderColor} mb={6} />
      <Formik
        initialValues={{
          sourceName: '',
          shopifyStore: '',
          apiToken: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const data = {
            shop_url: values.shopifyStore,
            company_name: values.sourceName,
            shopify_api_key: values.apiToken,
            project_id: projectId,
          };
          dispatch(ingestShopify({ data, authToken: localStorage.getItem("token") }))
            .unwrap()
            .then(() => {
              resetForm();
              setSubmitting(false);
            })
            .catch(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="sourceName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.sourceName && form.touched.sourceName} mt={4}>
                  <FormLabel htmlFor="sourceName">Source Name</FormLabel>
                  <Input {...field} id="sourceName" placeholder="Provide a name for your source" />
                  <FormErrorMessage>{form.errors.sourceName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="shopifyStore">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.shopifyStore && form.touched.shopifyStore} mt={4}>
                  <FormLabel htmlFor="shopifyStore">Shopify Store Url</FormLabel>
                  <Input {...field} id="shopifyStore" placeholder="Shopify Store" />
                  <FormErrorMessage>{form.errors.shopifyStore}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="apiToken">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.apiToken && form.touched.apiToken} mt={4}>
                  <FormLabel htmlFor="apiToken">API Token</FormLabel>
                  <Input {...field} id="apiToken" placeholder="API Token" />
                  <FormErrorMessage>{form.errors.apiToken}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex mt={6} justifyContent="flex-end">
              <Button variant="outline" mr={4} onClick={() => navigate("/sources")}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="teal" isLoading={isSubmitting || loading}>
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ShopifyForm;
