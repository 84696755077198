import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import ProductCard from './ProductCards';

export const SlideProducts = ({ products }) => {
  return (
    <Flex wrap='wrap' mx='auto'>
      {products && products.map((product, index) => (
        <Box key={index} mb={5} px={{ base: '0', md: '2' }} width={{ base: '50%', sm: '33.3%', md: '25%' }} flexShrink={0}>
          <ProductCard product={product} />
        </Box>
      ))}
    </Flex>
  );
};
