import { createAsyncThunk } from '@reduxjs/toolkit';
import * as agentApi from '../../apis/AgentApi';

export const createAgentConfig = createAsyncThunk(
  'agent/create',
  async ({ data, authToken }, thunkAPI) => {
    try {
      const response = await agentApi.createAgentConfigApi(data, authToken);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAgentConfig = createAsyncThunk(
    'agent/get',
    async ({ projectId, authToken }, thunkAPI) => {
      try {
        const response = await agentApi.getAgentConfigApi(projectId, authToken);
        return response.data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );

export const updateAgentConfig = createAsyncThunk(
  'agent/update',
  async ({ agentId, data, authToken }, thunkAPI) => {
    try {
      const response = await agentApi.updateAgentConfigApi(agentId, data, authToken);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteAgentConfig = createAsyncThunk(
  'agent/delete',
  async ({ agentId, authToken }, thunkAPI) => {
    try {
      const response = await agentApi.deleteAgentConfigApi(agentId, authToken);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);