import React from 'react'
import AgentsCard from '../../components/Agents/AgentsType'

const AgentType = () => {
  return (
    <>
    <AgentsCard/>
    </>
  )
}

export default AgentType