import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchEvaluationMetricsApi } from '../../apis/EvalutionApi';

export const fetchEvaluationMetrics = createAsyncThunk(
  'evaluation/fetchEvaluationMetrics',
  async ({ project_id, startDate, endDate }, thunkAPI) => {
    try {
      const response = await fetchEvaluationMetricsApi({ project_id, startDate, endDate }, thunkAPI);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
