import { Flex, Img, Text } from '@chakra-ui/react'
import React from 'react'

export const CarouselProducts = ({image, name, price, link}) => {
  const handleCardClick = () => {
    window.open(link, '_blank');
  };

  return (
    <Flex
      direction='column'
      align='center'
      mr="20px"
      width='110px'
      flexShrink={0}
      onClick={handleCardClick}
      cursor='pointer'
    >
        <Img
            height='110px'
            width='110px'
            src={image}
            borderRadius="20px"
        />
                        <Text mt={2} px={1} fontSize='0.85rem' color='#808080' overflow='hidden' whiteSpace='nowrap' width="100%" textOverflow='ellipsis'>The Indian Garage Co.</Text>
        <Text fontSize='sm' textAlign='center' overflow='hidden' whiteSpace='nowrap' width="100%" textOverflow='ellipsis'>{name}</Text>
    </Flex>
  )
}
