import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './AuthContext';

const AuthenticatedRoute = ({ element }) => {
  const { isAuthenticated, isLoading } = useAuth();
  const location = useLocation();

  if (isLoading) {
    // Render a loading spinner or some placeholder content while checking authentication
    return <div>Loading...</div>;
  }

  return isAuthenticated ? element : <Navigate to="/login" state={{ from: location }} />;
};

export default AuthenticatedRoute;
