import React, { useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { InputArea } from "./InputArea";
import { Suggestions } from "./Suggestions";
import { MessagesSearch } from "./MessagesSearch";
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from "uuid";

export const SearchPanel = ({ setShowPanel }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [messageIndex, setMessageIndex] = useState(0);
  const [lastUserMessage, setLastUserMessage] = useState("");
  const [sessionId, setSessionId] = useState(uuidv4());
  const [cancelRequest, setCancelRequest] = useState(null);
  const [projectId, setProjectId] = useState("");
  const [projectUrl, setProjectUrl] = useState("");
  const [streamedResponse, setStreamedResponse] = useState("");
  const { details } = useSelector(state => state.assistants);
  const assistantId = details?.project_id || "Default Name";
  const useCases = details?.type || "";

  const messagesToShow = [
    "Searching the Inventory..",
    "Looking up for best deal..",
    "See the magic of search..",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => (prevIndex + 1) % messagesToShow.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  const sendMessage = async () => {
    const userMessage = inputMessage;
    setLastUserMessage(userMessage);
    setStreamedResponse("");

    setMessages((old) => [...old, { from: "user", text: userMessage }]);
    setLoading(true);
    const controller = new AbortController();
    const { signal } = controller;
    setCancelRequest(() => controller.abort.bind(controller));

    const requestBody = {
      project_id: assistantId,
      session_id: sessionId,
      user_message: userMessage,
    };

    const endpoint =
      useCases === "productgpt"
        ? "https://cognitive.nextai.co.in/productgpt/message"
        : "https://cognitive.nextai.co.in/searchgpt/message/";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
        signal,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const contentType = response.headers.get("Content-Type");
      if (contentType && contentType.includes("text/event-stream")) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let streamedText = "";
        let messageId = Date.now();

        setMessages((old) => [...old, { from: "bot", text: "", id: messageId }]);

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          let chunk = decoder.decode(value, { stream: true });
          chunk = chunk.replace(/^data: /gm, ''); // Remove the "data: " prefix

          streamedText += chunk;
          setStreamedResponse((prevStreamedResponse) => prevStreamedResponse + chunk);
          setMessages((old) => old.map((msg) => (msg.id === messageId ? { ...msg, text: streamedText } : msg)));
        }
      } else {
        const responseData = await response.json();
        if (responseData.output && responseData.output.data) {
          const newMessage = { from: "bot", text: responseData.output.data };
          if (responseData.output.clarify) {
            newMessage.clarify = responseData.output.clarify;
          }
          setMessages((old) => [...old, newMessage]);
        } else if (responseData.intermediate_steps && responseData.intermediate_steps.length > 0) {
          const step = responseData.intermediate_steps[0];
          if (step[1].answer && step[1].answer.data) {
            const newMessage = { from: "bot", text: step[1].answer.data };
            if (step[1].clarify) {
              newMessage.clarify = step[1].clarify;
            }
            setMessages((old) => [...old, newMessage]);
          } else if (step[1].message) {
            const productsData = step[1].sources;
            if (productsData && productsData.length > 0) {
              try {
                const parsedProducts = JSON.parse(productsData);
                setMessages((old) => [
                  ...old,
                  { from: "bot", text: step[1].message, products: parsedProducts },
                ]);
              } catch (error) {
                console.error("Error parsing products data:", error);
                setMessages((old) => [
                  ...old,
                  { from: "bot", text: step[1].message },
                ]);
              }
            } else {
              setMessages((old) => [
                ...old,
                { from: "bot", text: step[1].message },
              ]);
            }
          } else {
            console.error("Unexpected response format:", responseData);
          }

          if (responseData.output && responseData.output.data) {
            setMessages((old) => [
              ...old,
              { from: "bot", text: responseData.output.data },
            ]);
          }
        } else {
          console.error("Unexpected response format:", responseData);
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        setMessages((oldMessages) => [...oldMessages, { from: "bot", text: "Cancelled response generation." }]);
      } else {
        console.error("Error:", error);
        setMessages((oldMessages) => [...oldMessages, { from: "bot", text: "An error occurred. Please try again." }]);
      }
    } finally {
      setLoading(false);
      setCancelRequest(null);
      setInputMessage("");
    }
  };

  const handleSourceSelection = async (selectedSource) => {
    const combinedMessage = `${lastUserMessage} for - ${selectedSource}`;
    setMessages((messages) => [...messages, { from: "user", text: combinedMessage }]);
    setLoading(true);
    const controller = new AbortController();
    const { signal } = controller;
    setCancelRequest(() => controller.abort.bind(controller));

    const requestBody = {
      project_id: assistantId,
      session_id: sessionId,
      user_message: combinedMessage,
    };

    const endpoint =
      useCases === "productgpt"
        ? "https://cognitive.nextai.co.in/productgpt/message"
        : "https://cognitive.nextai.co.in/searchgpt/message/";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
        signal,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();

      if (responseData.output && responseData.output.data) {
        const newMessage = { from: "bot", text: responseData.output.data };
        if (responseData.output.clarify) {
          newMessage.clarify = responseData.output.clarify;
        }
        setMessages((old) => [...old, newMessage]);
      } else if (responseData.intermediate_steps && responseData.intermediate_steps.length > 0) {
        const step = responseData.intermediate_steps[0];
        if (step[1].answer && step[1].answer.data) {
          const newMessage = { from: "bot", text: step[1].answer.data };
          if (step[1].clarify) {
            newMessage.clarify = step[1].clarify;
          }
          setMessages((old) => [...old, newMessage]);
        } else if (step[1].message) {
          const productsData = step[1].sources;
          if (productsData && productsData.length > 0) {
            try {
              const parsedProducts = JSON.parse(productsData);
              setMessages((old) => [
                ...old,
                { from: "bot", text: step[1].message, products: parsedProducts },
              ]);
            } catch (error) {
              console.error("Error parsing products data:", error);
              setMessages((old) => [
                ...old,
                { from: "bot", text: step[1].message },
              ]);
            }
          } else {
            setMessages((old) => [
              ...old,
              { from: "bot", text: step[1].message },
            ]);
          }
        } else {
          console.error("Unexpected response format:", responseData);
        }

        if (responseData.output && responseData.output.data) {
          setMessages((old) => [
            ...old,
            { from: "bot", text: responseData.output.data },
          ]);
        }
      } else {
        console.error("Unexpected response format:", responseData);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        setMessages((oldMessages) => [...oldMessages, { from: "bot", text: "Cancelled response generation." }]);
      } else {
        console.error("Error:", error);
        setMessages((oldMessages) => [...oldMessages, { from: "bot", text: "An error occurred. Please try again." }]);
      }
    } finally {
      setLoading(false);
      setCancelRequest(null);
      setInputMessage("");
    }
  };

  return (
    <Flex direction="column" height={{ base: "100%", md: "auto" }} p={5} pt={0}>
      {messages.length > 0 ? (
        <MessagesSearch
          messages={messages}
          setShowPanel={setShowPanel}
          onSourceSelected={handleSourceSelection}
          sessionId={sessionId}
          isLoading={isLoading}
        />
      ) : (
        <Suggestions setInputMessage={setInputMessage} />
      )}
      <InputArea
        inputMessage={inputMessage}
        setInputMessage={setInputMessage}
        sendMessage={sendMessage}
        setMessages={setMessages}
        placeholder={"Ask questions regarding our platform..."}
        setSessionId={setSessionId}
        isLoading={isLoading}
        cancelRequest={cancelRequest}
      />
    </Flex>
  );
};
