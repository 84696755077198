import React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Textarea,
  useColorModeValue,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { createAgentConfig } from '../../../features/Agents/AgentsAction';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  databaseURI: Yup.string().required('Database URI is required'),
  schemaTemplate: Yup.string().required('Extract Info From Schema Template is required'),
  textToSQLTemplate: Yup.string().required('Text to SQL Template is required'),
  sqlToOutputPrompt: Yup.string().required('SQL to Final Output Prompt is required'),
});

const PostgresAgentForm = () => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const toast = useToast();
  const projectId = useSelector(state => state.assistants.details?.project_id || "Bot_ID");
  const authToken = localStorage.getItem("token");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box
      maxW={'full'}
      mt={4}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
    >
      <Heading size="lg" mb={4}>
        Create Postgres Agent
      </Heading>
      <Box mb={0} fontSize={'xs'} color={textColor}>
        Fill the below fields to create a Postgres agent.
      </Box>
      <Divider borderColor={borderColor} mb={6} />
      <Formik
        initialValues={{
          databaseURI: '',
          schemaTemplate: '',
          textToSQLTemplate: '',
          sqlToOutputPrompt: '',
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const agentId = uuidv4();

          const agentData = {
            project_id: projectId,
            agent_id: agentId,
            agent_type: "postgres",
            postgres_uri: values.databaseURI,
            extract_info_from_schema_template: values.schemaTemplate,
            text_to_sql_template: values.textToSQLTemplate,
            sql_to_final_output_prompt: values.sqlToOutputPrompt,
          };

          dispatch(createAgentConfig({ data: agentData, authToken }))
            .unwrap()
            .then(() => {
              setSubmitting(false);
              resetForm();
              toast({
                title: "Success",
                description: "Postgres agent created successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              navigate("/agents");
            })
            .catch((error) => {
              setSubmitting(false);
              toast({
                title: "Error",
                description: error?.response?.data?.detail || error.message,
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="databaseURI">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.databaseURI && form.touched.databaseURI} mt={4}>
                  <FormLabel htmlFor="databaseURI">Database URI</FormLabel>
                  <Input {...field} id="databaseURI" placeholder="Enter your Postgres database URI" />
                  <FormErrorMessage>{form.errors.databaseURI}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="schemaTemplate">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.schemaTemplate && form.touched.schemaTemplate} mt={4}>
                  <FormLabel htmlFor="schemaTemplate">Extract Info From Schema Template</FormLabel>
                  <Textarea {...field} id="schemaTemplate" placeholder="Provide a schema extraction template" />
                  <FormErrorMessage>{form.errors.schemaTemplate}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="textToSQLTemplate">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.textToSQLTemplate && form.touched.textToSQLTemplate} mt={4}>
                  <FormLabel htmlFor="textToSQLTemplate">Text to SQL Template</FormLabel>
                  <Textarea {...field} id="textToSQLTemplate" placeholder="Provide a text to SQL template" />
                  <FormErrorMessage>{form.errors.textToSQLTemplate}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="sqlToOutputPrompt">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.sqlToOutputPrompt && form.touched.sqlToOutputPrompt} mt={4}>
                  <FormLabel htmlFor="sqlToOutputPrompt">SQL to Final Output Prompt</FormLabel>
                  <Textarea {...field} id="sqlToOutputPrompt" placeholder="Provide a SQL to final output prompt" />
                  <FormErrorMessage>{form.errors.sqlToOutputPrompt}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex mt={6} justifyContent="flex-end">
              <Button variant="outline" mr={4} onClick={() => console.log('Cancel')}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default PostgresAgentForm;
