import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCorrectedAnswerApi, updateRightQuestionToAnswerApi } from "../../apis/AnswerApi";

export const getCorrectedAnswerAsync = createAsyncThunk(
    "answer/getCorrectedAnswer",
    async (projectId, thunkAPI) => {
      const response = await getCorrectedAnswerApi(projectId, thunkAPI);
      return response;
    }
  );

export const updateRightQuestionToAnswerAsync = createAsyncThunk(
  "answer/updateRightQuestionToAnswer",
  async ({ project_id, question, answer }, thunkAPI) => {
    const response = await updateRightQuestionToAnswerApi({ project_id, question, answer }, thunkAPI);
    return response;
  }
);
