import { createSlice } from '@reduxjs/toolkit';
import { createAgentConfig, updateAgentConfig, deleteAgentConfig, getAgentConfig } from './AgentsAction';

const initialState = {
  agentConfigs: [],
  loading: false,
  error: null,
};

const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    resetAgentState: (state) => {
      state.agentConfigs = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createAgentConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createAgentConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.agentConfigs.push(action.payload);
    });
    builder.addCase(createAgentConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getAgentConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAgentConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.agentConfigs = action.payload;
    });
    builder.addCase(getAgentConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateAgentConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAgentConfig.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.agentConfigs.findIndex(agent => agent.agent_id === action.payload.agent_id);
      if (index !== -1) {
        state.agentConfigs[index] = action.payload;
      }
    });
    builder.addCase(updateAgentConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteAgentConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteAgentConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.agentConfigs = state.agentConfigs.filter(agent => agent.agent_id !== action.payload.agent_id);
    });
    builder.addCase(deleteAgentConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { resetAgentState } = agentSlice.actions;
export default agentSlice.reducer;
