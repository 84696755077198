import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAssistantsApi, createAssistantApi , fetchAssistantDetailsApi, fetchProjectDetailsApi , updateAssistantApi, deleteAssistantApi, updateProjectDetailsApi} from '../../apis/AssistantApi';

export const fetchAssistantsAsync = createAsyncThunk(
  'assistants/fetchAssistants',
  async (_, thunkAPI) => {
    try {
      const data = await fetchAssistantsApi();
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const createAssistantAsync = createAsyncThunk(
    'assistants/create',
    async ({ assistantData, token }, thunkAPI) => {
      try {
        const data = await createAssistantApi(assistantData, token);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  export const fetchAssistantDetailsAsync = createAsyncThunk(
    'assistants/fetchDetails',
    async ({ projectId , token }, thunkAPI) => {
      try {
        const response = await fetchAssistantDetailsApi(projectId , token);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  export const fetchProjectDetailsAsync = createAsyncThunk(
    'assistants/fetchProjectDetails',
    async ({ projectId, token }, thunkAPI) => {
      try {
        const response = await fetchProjectDetailsApi(projectId, token);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  export const updateAssistantAsync = createAsyncThunk(
    'assistants/updateAssistant',
    async ({ projectId, assistantData, token }, thunkAPI) => {
      try {
        const response = await updateAssistantApi(projectId, assistantData, token);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  export const updateProjectDetailsAsync = createAsyncThunk(
    'assistants/updateProjectDetails',
    async ({ projectId, projectData, token }, thunkAPI) => {
      try {
        const data = await updateProjectDetailsApi(projectId, projectData, token);
        return data;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );

  export const deleteAssistantAsync = createAsyncThunk(
    'assistants/deleteAssistant',
    async (assistantId, thunkAPI) => {
      try {
        const response = await deleteAssistantApi(assistantId);
        return response;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data);
      }
    }
  );