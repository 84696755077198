import React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Text,
  useToast,
} from '@chakra-ui/react';
import { Formik, Field, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ingestNotion } from '../../../features/Ingestion/IngestionAction';

const validationSchema = Yup.object().shape({
  sourceName: Yup.string().required('Source Name is required'),
  integrationToken: Yup.string().required('Integration Token is required'),
  databaseId: Yup.string(),
  pageIds: Yup.array().of(Yup.string()),
});

const NotionForm = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const dispatch = useDispatch();
  const toast = useToast();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.ingestion.isLoading);
  const projectId = useSelector((state) => state.assistants.details?.project_id || 'Bot_ID');

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const { sourceName, integrationToken, databaseId, pageIds } = values;
    if (!databaseId && !pageIds.some(id => id.trim() !== "")) {
      toast({
        title: 'Validation Error',
        description: 'Either Database ID or at least one Page ID must be provided.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setSubmitting(false);
      return;
    }

    dispatch(
      ingestNotion({
        data: {
          notion_integration_token: integrationToken,
          notion_database_id: databaseId,
          source_name: sourceName,
          project_id: projectId,
          page_ids: pageIds.filter(id => id.trim() !== ""),
        },
      })
    )
      .unwrap()
      .then(() => {
        resetForm();
        toast({
          title: 'Data Ingested',
          description: 'You will receive a confirmation email shortly.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        navigate('/resources');
      })
      .catch((error) => {
        toast({
          title: 'Data Ingestion Failed',
          description: error || 'An error occurred',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      });
  };

  return (
    <Box
      maxW={'full'}
      mt={4}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
    >
      <Heading size="lg" mb={4}>
        Notion Source
      </Heading>
      <Box mb={0} fontSize={'xs'} color={textColor}>
        Fill the below fields to ingest Notion data.
      </Box>
      <Divider borderColor={borderColor} mb={6} />
      <Formik
        initialValues={{
          sourceName: '',
          integrationToken: '',
          databaseId: '',
          pageIds: [''],
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, isSubmitting, handleChange }) => (
          <Form>
            <Field name="sourceName">
              {({ field }) => (
                <FormControl isInvalid={errors.sourceName && touched.sourceName} mt={4}>
                  <FormLabel htmlFor="sourceName">Source Name</FormLabel>
                  <Input {...field} id="sourceName" placeholder="Source Name" />
                  <FormErrorMessage>{errors.sourceName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="integrationToken">
              {({ field }) => (
                <FormControl isInvalid={errors.integrationToken && touched.integrationToken} mt={4}>
                  <FormLabel htmlFor="integrationToken">Integration Token</FormLabel>
                  <Input {...field} id="integrationToken" placeholder="Integration Token" />
                  <FormErrorMessage>{errors.integrationToken}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="databaseId">
              {({ field }) => (
                <FormControl isInvalid={errors.databaseId && touched.databaseId} mt={4}>
                  <FormLabel htmlFor="databaseId">Database ID</FormLabel>
                  <Input {...field} id="databaseId" placeholder="Database ID" />
                  <FormErrorMessage>{errors.databaseId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <FieldArray name="pageIds">
              {({ push, remove }) => (
                <>
                  {values.pageIds.map((id, index) => (
                    <FormControl key={index} isInvalid={errors.pageIds && errors.pageIds[index] && touched.pageIds && touched.pageIds[index]} mt={4}>
                      <FormLabel htmlFor={`pageIds[${index}]`} fontSize={14}>
                        Page ID {index + 1}
                      </FormLabel>
                      <Input
                        id={`pageIds[${index}]`}
                        name={`pageIds[${index}]`}
                        placeholder={`Page ID ${index + 1}`}
                        value={id}
                        onChange={handleChange}
                        mt={-5}
                        size="sm"
                        borderRadius="10"
                        _focus={{
                          borderColor: 'purple.300',
                        }}
                      />
                      <FormErrorMessage>{errors.pageIds && errors.pageIds[index]}</FormErrorMessage>
                      {index > 0 && (
                        <Button
                          mt={2}
                          size="sm"
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      )}
                    </FormControl>
                  ))}
                  <Button
                    mt={2}
                    size="sm"
                    onClick={() => push('')}
                  >
                    Add Another Page ID
                  </Button>
                </>
              )}
            </FieldArray>
            <Flex mt={6} justifyContent="flex-end">
              <Button variant="outline" mr={4} onClick={() => console.log('Cancel')}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="teal" isLoading={isSubmitting || isLoading}>
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default NotionForm;
