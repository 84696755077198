import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as userApi from '../../apis/userApi';

const getAuthToken = () => localStorage.getItem('token');

export const getUserDetails = createAsyncThunk(
  'user/getDetails',
  async (_, thunkAPI) => {
    try {
      const response = await userApi.getUserDetailsApi();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateUserDetails = createAsyncThunk(
  'user/updateDetails',
  async (data, thunkAPI) => {
    try {
      const response = await userApi.updateUserDetailsApi(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const uploadProfileImage = createAsyncThunk(
  'user/uploadProfileImage',
  async (file, thunkAPI) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://cognitive.nextai.co.in/auth/upload-profile-image', formData, {
        headers: {
          'Authorization': `Bearer ${getAuthToken()}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
