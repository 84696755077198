import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMetrics, fetchGraphData } from '../../apis/Metrics';

export const getMetrics = createAsyncThunk(
  'metrics/getMetrics',
  async (projectId, thunkAPI) => {
    return await fetchMetrics(projectId, thunkAPI);
  }
);

export const getGraphData = createAsyncThunk(
  'metrics/getGraphData',
  async (projectId, thunkAPI) => {
    return await fetchGraphData(projectId, thunkAPI);
  }
);