import { createSlice } from "@reduxjs/toolkit";
import { getCorrectedAnswerAsync, updateRightQuestionToAnswerAsync } from "./AnswersAction";

const answerSlice = createSlice({
  name: "answer",
  initialState: {
    corrections: [],
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetAnswer: (state) => {
      state.corrections = [];
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCorrectedAnswerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCorrectedAnswerAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (Array.isArray(action.payload)) {
          state.corrections = action.payload; 
        } else {
          state.error = { message: "Invalid data received" };
        }
      })
      .addCase(getCorrectedAnswerAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateRightQuestionToAnswerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateRightQuestionToAnswerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = action.payload;
      })
      .addCase(updateRightQuestionToAnswerAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetAnswer } = answerSlice.actions;
export default answerSlice.reducer;
