import React from 'react'
import SourcesCard from '../../components/AddSources/SourcesCard'

const AddSources = () => {
  return (
    <>
    <SourcesCard/>
    </>
  )
}

export default AddSources