import { Box, Button, Wrap, WrapItem, Text } from "@chakra-ui/react";
import React from "react";

export const Sources = ({ sources = [], onSourceSelected }) => {
  
  const handleSourceClick = (source) => {
    if (onSourceSelected) {
      onSourceSelected(source);
    }
    window.open(source, "_blank");
  };

  return (
    <Box py={2}>
      {sources.length > 0 && (
        <>
          <Text fontSize="0.9rem" fontWeight="semibold">
            Sources
          </Text>
          <Wrap py={2}>
            {sources.map((source, index) => (
              <WrapItem key={index}>
                <Button
                  onClick={() => handleSourceClick(source)}
                  borderRadius="4px"
                  variant="outline"
                  colorScheme="purple"
                  size="xs"
                  p={3}
                >
                  {typeof source === "string"
                    ? source.toLowerCase()
                    : "Invalid Source"}
                </Button>
              </WrapItem>
            ))}
          </Wrap>
        </>
      )}
    </Box>
  );
};
