// src/api/assistantApi.js
import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchAssistantsApi = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/assistant/get-assistants`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createAssistantApi = async (assistantData, token) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/assistant/bots/create`,
        JSON.stringify(assistantData),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

export const fetchAssistantDetailsApi = async (projectId) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/projects/get-assistants?project_id=${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Cache-Control": "no-cache, no-store, must-revalidate", 
          Pragma: "no-cache", 
          Expires: "0",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch assistant details:", error);
    throw error;
  }
};

export const fetchProjectDetailsApi = async (projectId) => {
    try {
      const response = await axios.get(`${BASE_URL}/projects/get?project=${projectId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      });
      return response.data;
    } catch (error) {
      console.error("Failed to fetch project details:", error);
      throw error;
    }
  };

  export const updateAssistantApi = async (projectId, assistantData, token) => {
    try {
      const response = await axios.patch(`${BASE_URL}/assistant/update-assistants/${projectId}`, assistantData, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  export const updateProjectDetailsApi = async (projectId, projectData, token) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/projects/edit/${projectId}`,
        JSON.stringify(projectData),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to update project details:", error);
      throw error;
    }
  };

  export const deleteAssistantApi = async (assistantId) => {
    try {
      const response = await axios.delete(`${BASE_URL}/assistant/delete-assistant/${assistantId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };