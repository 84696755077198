// NotificationsPopover.jsx
import React, { useState } from 'react';
import {
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  List,
  ListItem,
  HStack,
  VStack,
  Avatar,
  Text,
  Button,
  Divider,
  Flex,
} from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';
import Logo from "../assets/img/icon.png"

const NotificationsPopover = () => {
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: 'mention',
      user: 'System',
      message: 'Welcome to SearchGPT! Start integrating GenAI into your spaces seamlessly. Happy creating!',
      time: '',
      team: 'System Message',
      avatar: Logo,
      action: null,
    },
    // {
    //   id: 2,
    //   type: 'request',
    //   user: 'Cameron William',
    //   message: 'requests to edit the file design.',
    //   time: '29 Mar at 9:00 PM',
    //   team: null,
    //   avatar: 'https://bit.ly/kent-c-dodds',
    //   action: (
    //     <>
    //       <Button size="sm" colorScheme="blue" mr="2">
    //         Approve
    //       </Button>
    //       <Button size="sm">Deny</Button>
    //     </>
    //   ),
    // },
    // {
    //   id: 3,
    //   type: 'file',
    //   user: 'Fahmida Juarez',
    //   message: 'added new file.jpg',
    //   time: '16 Mar at 7:47 PM',
    //   team: null,
    //   avatar: 'https://bit.ly/prosper-baba',
    //   action: null,
    // },
    // {
    //   id: 4,
    //   type: 'file',
    //   user: 'Steve Matheve',
    //   message: 'created Solar icon.',
    //   time: '16 Mar at 7:47 PM',
    //   team: null,
    //   avatar: 'https://bit.ly/ryan-florence',
    //   action: null,
    // },
  ]);

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <IconButton
          icon={<FiBell />}
          variant="ghost"
          aria-label="Notifications"
          fontSize="20px"
          mr="4"
        />
      </PopoverTrigger>
      <PopoverContent width="400px">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Flex justify="space-between" align="center">
            <Text>Notifications</Text>
            <Button mr={10} size="xs" variant="link">
              Mark all as read
            </Button>
          </Flex>
        </PopoverHeader>
        <PopoverBody>
          <Tabs variant="enclosed">
            <TabList>
              <Tab>All</Tab>
              {/* <Tab>Unread</Tab>
              <Tab>@mention</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <List spacing={3}>
                  {notifications.map((notification) => (
                    <ListItem key={notification.id}>
                      <HStack align="start" spacing="4">
                        <Avatar size="sm" src={notification.avatar} />
                        <VStack align="start" spacing="1">
                          <Text fontSize="sm">
                            {notification.message}
                          </Text>
                          <Text fontSize="xs" color="gray.500">
                            {notification.time} {notification.team && ` ${notification.team}`}
                          </Text>
                          {notification.action && (
                            <HStack spacing="2">{notification.action}</HStack>
                          )}
                        </VStack>
                      </HStack>
                      <Divider mt="2" />
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
              <TabPanel>
                <List spacing={3}>
                  {notifications
                    .filter((notification) => notification.type === 'mention')
                    .map((notification) => (
                      <ListItem key={notification.id}>
                        <HStack align="start" spacing="4">
                          <Avatar size="sm" src={notification.avatar} />
                          <VStack align="start" spacing="1">
                            <Text fontSize="sm">
                              <strong>{notification.user}</strong> {notification.message}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                              {notification.time} {notification.team && ` ${notification.team}`}
                            </Text>
                            {notification.action && (
                              <HStack spacing="2">{notification.action}</HStack>
                            )}
                          </VStack>
                        </HStack>
                        <Divider mt="2" />
                      </ListItem>
                    ))}
                </List>
              </TabPanel>
              <TabPanel>
                <List spacing={3}>
                  {notifications
                    .filter((notification) => notification.type === 'request')
                    .map((notification) => (
                      <ListItem key={notification.id}>
                        <HStack align="start" spacing="4">
                          <Avatar size="sm" src={notification.avatar} />
                          <VStack align="start" spacing="1">
                            <Text fontSize="sm">
                              <strong>{notification.user}</strong> {notification.message}
                            </Text>
                            <Text fontSize="xs" color="gray.500">
                              {notification.time} {notification.team && `| ${notification.team}`}
                            </Text>
                            {notification.action && (
                              <HStack spacing="2">{notification.action}</HStack>
                            )}
                          </VStack>
                        </HStack>
                        <Divider mt="2" />
                      </ListItem>
                    ))}
                </List>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationsPopover;
