import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

const theme = extendTheme({
  fonts: {
    heading: 'Satoshi, Arial, sans-serif',
    body: 'Satoshi, Arial, sans-serif',
  },
  colors: {
    gray: {
      50: '#f7fafc',
      100: '#edf2f7',
      200: '#e2e8f0',
      300: '#cbd5e0',
      400: '#a0aec0',
      500: '#718096',
      600: '#4a5568',
      700: '#2d3748',
      800: '#1a202c',
      900: '#171923',
    },
  },
  styles: {
    global: (props) => ({
      body: {
        bg: mode('gray.200', 'gray.800')(props),
        color: mode('black', 'white')(props),
        fontFamily: 'Satoshi, Arial, sans-serif',
      },
    }),
  },
});

export default theme;
