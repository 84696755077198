import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateNewApiKeyAsync, deleteApiKeyAsync } from "../../features/Api/apiKeyAction";
import { Box, Button, Input, Text, Spinner, HStack, useClipboard, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Table, Thead, Tbody, Tr, Th, Td, IconButton, useToast, useColorMode, useColorModeValue, Flex, VStack } from '@chakra-ui/react';
import { resetApiKey } from '../../features/Api/apiKeySlice';
import { DeleteIcon, SunIcon, MoonIcon } from '@chakra-ui/icons';
import { getUserDetails } from '../../features/UserData/userActions';

const ApiKeyGeneration = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [name, setName] = useState('');
  const { apiKey, loading, error, success } = useSelector((state) => state.apiKey);
  const { userDetails } = useSelector(state => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasCopied, onCopy } = useClipboard(apiKey?.new_api_key || '');
  const { toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('black', 'white');
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (success) {
      toast({
        title: success,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }

    if (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [success, error, toast]);

  const handleGenerateApiKey = () => {
    dispatch(generateNewApiKeyAsync({ name }));
    onOpen();
  };

  const handleReset = () => {
    setName('');
    dispatch(resetApiKey());
  };

  const handleDeleteApiKey = (key,token) => {
    dispatch(deleteApiKeyAsync(key));
    dispatch(getUserDetails(token));
  };

  const handleCloseModal = () => {
    onClose();
    handleReset();
  };

  return (
    <Box bg={bgColor} p={6} borderRadius="md">
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Text fontSize="2xl" fontWeight="bold" color={textColor}>Generate New API Key</Text>
      </Flex>
      <VStack spacing={4}>
        <Input
          placeholder="Enter name for the API key"
          value={name}
          onChange={(e) => setName(e.target.value)}
          bg={useColorModeValue('white', 'gray.600')}
          color={textColor}
        />
        <HStack>
          <Button 
            onClick={handleGenerateApiKey} 
            colorScheme="teal" 
            isDisabled={loading}
            width="100%"
          >
            {loading ? <Spinner size="sm" /> : 'Generate Key'}
          </Button>
          <Button onClick={handleReset} colorScheme="red" width="100%">
            Reset
          </Button>
        </HStack>
        {error && (
          <Text color="red.500">Error: {error.message}</Text>
        )}
      </VStack>

      {apiKey && (
        <Modal isOpen={isOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>API Key Generated</ModalHeader>
            <ModalBody>
              <Text color="green.500" mb={2}>API Key generated successfully!</Text>
              <Text>This API key is only visible once. Please copy it and store it securely.</Text>
              <Box mt={4} p={2} border="1px" borderColor="gray.200" borderRadius="md" bg="gray.50">
                <Text>{apiKey.api_key}</Text>
              </Box>
              <Button size={'sm'} onClick={onCopy} mt={4} colorScheme="teal">
                {hasCopied ? 'Copied' : 'Copy API Key'}
              </Button>
            </ModalBody>
            <ModalFooter>
              <Button onClick={handleCloseModal} colorScheme="teal" size={'sm'} mr={3}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <Table variant="simple" mt={8} bg={bgColor}>
        <Thead>
          <Tr>
            <Th color={textColor}>Name</Th>
            <Th color={textColor}>Key</Th>
            <Th color={textColor}>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
        {userDetails?.api_keys && userDetails.api_keys.length > 0 ? (
            userDetails.api_keys.map((apiKey, index) => (
              <Tr key={index}>
                <Td>{apiKey.name}</Td>
                <Td>{apiKey.key}</Td>
                <Td>
                  <IconButton
                    aria-label="Delete API Key"
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    size={'sm'}
                    onClick={() => handleDeleteApiKey(apiKey.key)}
                  />
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={3}>
                <Text textAlign="center" color={textColor}>No API key generated</Text>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default ApiKeyGeneration;
