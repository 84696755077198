import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchCorrections = async (projectId, startDate, endDate) => {
  try {
    let url = `${BASE_URL}/metrics/project/${projectId}/conversations`;
    if (startDate && endDate) {
      const start = startDate.toISOString();
      const end = endDate.toISOString();
      url += `?start_date=${start}&end_date=${end}`;
    }
    const response = await axios.get(url);
    return response.data.map((item) => ({
      question: item.chat_input.content,
      correction: item.chat_response.content,
      dateCreated: item.date,
      feedback: item.feedback === 1 ? "Positive" : item.feedback === 0 ? "Negative" : "Neutral",
    }));
  } catch (error) {
    if (error.response && error.response.status === 404) {
      throw new Error('No conversations found for the specified date range.');
    }
    throw error;
  }
};
