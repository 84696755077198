import React from "react";
import {
  Box,
  Card,
  Divider,
  Heading,
  Icon,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { CopyBlock, dracula, a11yDark } from "react-code-blocks";
import { FaCode } from "react-icons/fa";
import { TbSourceCode } from "react-icons/tb";
import { Link } from "react-router-dom";

export const WidgetInstallation = () => {
  const assistantType = useSelector(
    (state) => state.assistants.details?.type || null
  );
  const projectId = useSelector(
    (state) => state.assistants.details?.project_id || null
  );

  if (!assistantType || !projectId) {
    return (
      <Card border="1px solid" p={6} maxW="100%">
        <Heading fontSize="1.2rem" fontWeight="semibold" mb={4}>
          Create an Assistant
        </Heading>
        <Text mb={4}>
          To use the widget installation feature, you need to create an
          assistant first.
        </Text>
        <Button colorScheme="teal" as={Link} to="/new-assistant">
          Create Assistant
        </Button>
      </Card>
    );
  }

  const searchWidgetCode = `<script src="https://cdn.jsdelivr.net/npm/@nextai/searchmate@0.1.16/build/static/js/main.js?key=${projectId}"></script>`;
  const privateWidgetCode = `<script src="https://cdn.jsdelivr.net/npm/@nextai/cognitive@0.0.34/build/static/js/main.js?key=${projectId}"></script>`;

  const widgetAPIUrlSearch = `https://cognitive.nextai.co.in/searchgpt/${projectId}`;
  const widgetAPIUrlPrivate = `
curl --location 'https://cognitive.nextai.co.in/askgpt/message/' \\
--header 'Content-Type: application/json' \\
--header 'Authorization: Bearer Token' \\
--data '{
  "project_id": "${projectId}",
  "session_id": "199902",
  "user_message": "what is this about?"
}'`;

  const widgetCode =
    assistantType === "askgpt" || assistantType === "agent"
      ? privateWidgetCode
      : searchWidgetCode;

  const ApiCode =
    assistantType === "askgpt" ? widgetAPIUrlPrivate : widgetAPIUrlSearch;

  return (
    <Flex direction={"column"}>
      <Box border={"1px solid"} p={6} maxW="100%">
        <Heading fontSize="1rem" fontWeight="semibold" mb={2}>
          <Icon as={FaCode} mr={2} verticalAlign="middle" />
          Widget Code
        </Heading>
        <CopyBlock
          language="javascript"
          text={widgetCode}
          theme={a11yDark}
          wrapLongLines={true}
          showLineNumbers={false}
          codeBlock
        />
      </Box>
    </Flex>
  );
};
