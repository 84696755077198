import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  VStack,
  Link,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  MenuDivider,
  useColorMode,
  Divider,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import {
  FiHome,
  FiUsers,
  FiCreditCard,
  FiClipboard,
  FiGrid,
  FiHelpCircle,
  FiSettings,
  FiFileText,
  FiChevronDown,
  FiChevronRight,
  FiCheck,
} from "react-icons/fi";
import {
  LayoutDashboard,
  Play,
  SearchCheck,
  Boxes,
  Bot,
  SquarePlus,
  Book,
  Settings2,
  SquareActivity,
} from "lucide-react";
import Topbar from "./topbar";
import {
  fetchAssistantsAsync,
  fetchAssistantDetailsAsync,
  fetchProjectDetailsAsync,
} from "../features/Assistant/AssistantAction";
import { setSelectedAssistant } from "../features/Assistant/AssistantSlice";
import { getUserDetails } from "../features/UserData/userActions";
import Lottie from 'react-lottie'; 
import upgradeAnimation from '../assets/animation/upgrade.json'; 

const Shell = ({ children }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: upgradeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const { colorMode } = useColorMode();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { assistants, selectedAssistant } = useSelector(
    (state) => state.assistants
  );
  const { userDetails, loading, error } = useSelector((state) => state.user);
  const token = localStorage.getItem("token");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (!userDetails && !loading && !error) {
      dispatch(getUserDetails());
    }
  }, [dispatch]);

  useEffect(() => {
    if (token) {
      dispatch(fetchAssistantsAsync(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    if (assistants.length > 0 && !selectedAssistant) {
      const initialAssistant = assistants[0];
      dispatch(setSelectedAssistant(initialAssistant));
      dispatch(
        fetchAssistantDetailsAsync({
          projectId: initialAssistant.project_id,
          token,
        })
      );
      dispatch(
        fetchProjectDetailsAsync({
          projectId: initialAssistant.project_id,
          token,
        })
      );
    }
  }, [assistants, selectedAssistant, dispatch, token]);

  const handleAssistantChange = (assistant) => {
    dispatch(setSelectedAssistant(assistant));
    dispatch(
      fetchAssistantDetailsAsync({ projectId: assistant.project_id, token })
    );
    dispatch(
      fetchProjectDetailsAsync({ projectId: assistant.project_id, token })
    );
  };

  const handleCustomizeClick = () => {
    if (selectedAssistant) {
      navigate(`/project/${selectedAssistant.project_id}`, {
        state: { assistant: selectedAssistant },
      });
    }
  };

  const handleCreateAssistantClick = () => {
    if (assistants.length >= userDetails?.plan_details?.features?.assistantCount) {
      onOpen();
    } else {
      navigate("/new-assistant");
    }
  };

  const requests = userDetails?.plan_details?.features?.requests || 0;
  const chatCount = userDetails?.chat_count || 0;
  const remainingRequests = requests - chatCount;

  return (
    <Flex direction="column" h="100vh">
      <Topbar />
      <Flex flex="1" overflow="hidden" mt="64px"> {/* Adjust the top margin to avoid overlapping with Topbar */}
        <Box
          bg={colorMode === "light" ? "gray.200" : "gray.800"}
          color={colorMode === "light" ? "black" : "white"}
          w={{ base: "full", md: "250px" }}
          p="4"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          h={{ base: "auto", md: "100vh" }}
          overflowY="auto"
        >
          <VStack align="stretch" spacing="4">
            <Box mt={2}>
              <Menu>
                <MenuButton
                  as={Box}
                  p="4"
                  bg={colorMode === "light" ? "gray.300" : "gray.700"}
                  borderRadius="md"
                  cursor="pointer"
                  _hover={{
                    bg: colorMode === "light" ? "gray.400" : "gray.600",
                  }}
                >
                  <Flex alignItems="center">
                    <Avatar
                      size="sm"
                      name={
                        selectedAssistant ? selectedAssistant.name : ""
                      }
                      src={
                        selectedAssistant
                          ? selectedAssistant.widget_image
                          : "https://bit.ly/broken-link"
                      }
                      mr="2"
                    />
                    <Box flex="1">
                      <Text fontSize="xl" fontWeight="bold">
                        {selectedAssistant ? selectedAssistant.name : "Create"}
                      </Text>
                      <Text fontSize={"xs"}>
                        {selectedAssistant ? selectedAssistant.type : "Assistant"}
                      </Text>
                    </Box>
                    <Icon as={FiChevronDown} />
                  </Flex>
                </MenuButton>
                <MenuList
                  bg={colorMode === "light" ? "white" : "gray.700"}
                  color={colorMode === "light" ? "black" : "white"}
                >
                  <Box px="4" py="2">
                    <Text fontSize={"xs"}>Assistants</Text>
                    {assistants.map((assistant) => (
                      <MenuItem
                        key={assistant._id}
                        onClick={() => handleAssistantChange(assistant)}
                      >
                        <HStack>
                          {selectedAssistant &&
                            selectedAssistant._id === assistant._id && (
                              <Icon as={FiCheck} color="teal.500" />
                            )}
                          <Text>{assistant.name}</Text>
                        </HStack>
                      </MenuItem>
                    ))}
                  </Box>
                  <MenuDivider />
                  <MenuItem onClick={handleCustomizeClick}>
                    <Box mr={2}>
                      <Settings2 size={16} />
                    </Box>
                    Customize
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={handleCreateAssistantClick}>
                    <Box mr={2}>
                      <SquarePlus size={16} />
                    </Box>
                    Create New Assistant
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
            <Box mb={-2}>
              <Link
                as={RouterLink}
                to="/"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Box mr={2}>
                  <LayoutDashboard size={16} strokeWidth={2} />
                </Box>
                Home
              </Link>
            </Box>
            <Box mt="0">
              <Text
                fontWeight="bold"
                color={colorMode === "light" ? "gray.600" : "gray.400"}
                fontSize="sm"
                mb="2"
              >
                Workforce
              </Text>
              <Link
                as={RouterLink}
                to="/agents"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/agents"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Box mr={2}>
                  <Bot size={16} strokeWidth={2} />
                </Box>
                Agents
              </Link>
              <Link
                as={RouterLink}
                to="/resources"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/resources"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Box mr={2}>
                  <Boxes size={16} strokeWidth={1} />
                </Box>
                Resource Hub
              </Link>
              <Link
                as={RouterLink}
                to="/playground"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/playground"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={FiUsers} mr="2" /> Playground
              </Link>
              <Link
                as={RouterLink}
                to="/integrations"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/integrations"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={FiGrid} mr="2" /> Integrations
              </Link>
            </Box>
            <Box mt="0">
              <Text
                fontWeight="bold"
                color={colorMode === "light" ? "gray.600" : "gray.400"}
                fontSize="sm"
                mb="2"
              >
                Monitor
              </Text>
              <Link
                as={RouterLink}
                to="/history"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/history"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={FiCreditCard} mr="2" /> Chat history
              </Link>
              <Link
                as={RouterLink}
                to="/corrections"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/corrections"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Box mr={2}>
                  <SearchCheck size={16} />
                </Box>{" "}
                Corrections history
              </Link>
              {/* <Link
                as={RouterLink}
                to="/plans"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/plans"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={FiClipboard} mr="2" /> Evaluation
              </Link> */}
            </Box>
            <Box mt="0">
              <Text
                fontWeight="bold"
                color={colorMode === "light" ? "gray.600" : "gray.400"}
                fontSize="sm"
                mb="2"
              >
                Billing
              </Text>
              <Link
                as={RouterLink}
                to="/invoices"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/invoices"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={FiCreditCard} mr="2" /> Invoices
              </Link>
              <Link
                as={RouterLink}
                to="/plan"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/plan"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={FiFileText} mr="2" /> Plans
              </Link>
            </Box>
            <Divider mt={1} mb={0} borderColor="gray.300" />
            <Box>
              <Link
                as={RouterLink}
                to="https://www.youtube.com/@nextai_official"
                target="_blank"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/tutorials"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Box mr={2}>
                  <Play size={16} strokeWidth={2} />
                </Box>
                Tutorials
              </Link>
              <Link
                as={RouterLink}
                to="https://docs.searchgpt.dev/"
                target="_blank"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/settings"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={Book} mr="2" /> Docs
              </Link>
              <Link
                as={RouterLink}
                to="https://discord.gg/syaMajSJAJ"
                target="_blank"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/help"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={FiHelpCircle} mr="2" /> Help & Support
              </Link>
              <Link
                as={RouterLink}
                to="https://status.nextai.co.in/"
                target="_blank"
                display="flex"
                alignItems="center"
                p="1"
                borderRadius="md"
                fontSize="sm"
                _hover={{ bg: colorMode === "light" ? "gray.300" : "gray.700" }}
                bg={
                  location.pathname === "/help"
                    ? colorMode === "light"
                      ? "gray.300"
                      : "gray.700"
                    : "transparent"
                }
              >
                <Icon as={SquareActivity} mr="2" /> Health Status
              </Link>
            </Box>
          </VStack>
          <Box
            bg={colorMode === "light" ? "gray.100" : "gray.700"}
            p="4"
            borderRadius="md"
            mt="2"
            mb={16}
            display={{ base: "none", md: "block" }}
          >
            <Flex justifyContent="space-between" alignItems="center">
              <Box>
                <Text fontSize="sm" fontWeight="bold">
                  {(userDetails?.plan || "").toUpperCase()} PLAN
                </Text>
                <Link href="https://form.jotform.com/241614372283454" fontSize="xs" display="flex" alignItems="center">
                  Upgrade <Icon as={FiChevronRight} ml="1" />
                </Link>
              </Box>
              <Box textAlign="right">
                <Text fontSize="xl" fontWeight="bold">
                  {remainingRequests}
                </Text>
                <Text fontSize="xs">requests remaining</Text>
              </Box>
            </Flex>
          </Box>
        </Box>
        <Box flex="1" p="4" overflow="auto">
          {React.cloneElement(children, { assistant: selectedAssistant })}
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upgrade Plan</ModalHeader>
          <ModalCloseButton />
          <ModalBody mt={-4}>
            <Box mb={4}>
              <Lottie options={defaultOptions} height={150} width={150} />
            </Box>
            <Text color={'black'}>Please upgrade your plan to create more assistants.</Text>
          </ModalBody>
          <ModalFooter>
            <Button size={'sm'} onClick={onClose} colorScheme="teal" as={RouterLink} to="/plan" mr={3}>
              Upgrade Plan
            </Button>
            <Button size={'sm'} variant="ghost" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Shell;
