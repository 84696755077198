import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  HStack,
  ModalCloseButton,
  Badge,
  Tag,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Skeleton,
} from "@chakra-ui/react";
import { Edit3 } from "lucide-react";
import { useSelector } from "react-redux";
import { UpdateProjectDetails } from "../Update/ProjectUpdate";

export default function Body({ assistant }) {
  const borderColor = useColorModeValue("gray.300", "gray.700");
  const boxBg = useColorModeValue("gray.100", "gray.900");
  const iconColor = useColorModeValue("gray.400", "gray.500");
  const textColor = useColorModeValue("gray.900", "teal.200");
  const idTextColor = useColorModeValue("gray.500", "gray.100");

  const { project, selectedAssistant } = useSelector(
    (state) => state.assistants
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Box
      border="1px solid"
      borderColor={borderColor}
      borderRadius="md"
      p={6}
      bg={useColorModeValue("gray.50", "gray.800")}
      shadow="md"
      maxW="full"
      mx="auto"
      mt={2}
    >
      <Flex justify="space-between" mb={4}>
        <Box>
          <Heading as="h3" size="lg" mb={1}>
            Project Details
          </Heading>
          <Text fontSize="sm" color="gray.600">
            View & Modify the project details as per your needs.
          </Text>
        </Box>
        <Button
          display={{ base: "none", sm: "inline-flex" }}
          leftIcon={<Icon as={Edit3} w="5" h="5" textColor={iconColor} />}
          colorScheme="teal"
          variant="outline"
          size="sm"
          onClick={onOpen}
        >
          Edit
        </Button>
      </Flex>
      <Stack spacing={4}>
        <Box
          border="1px solid"
          borderColor={borderColor}
          borderRadius="md"
          p={4}
          bg={boxBg}
        >
          <Skeleton isLoaded={!isLoading}>
            <HStack mt="2" textColor={idTextColor} spacing="1" mr={2}>
              <Text color={textColor}>Temperature</Text>
              <Badge>{project?.temperature}</Badge>
            </HStack>
          </Skeleton>
          <Skeleton isLoaded={!isLoading}>
            <HStack mt="2" textColor={idTextColor} spacing="1" mr={2}>
              <Text color={textColor}>History</Text>
              <Tag>{project?.history ? "On" : "Off"}</Tag>
            </HStack>
          </Skeleton>
          <Skeleton isLoaded={!isLoading}>
            <HStack mt="2" textColor={idTextColor} spacing="1" mr={2}>
              <Text color={textColor}>Suggestions</Text>
              <Tag>{project?.suggestion_flag ? "On" : "Off"}</Tag>
            </HStack>
          </Skeleton>
          {(project?.type  === "agent") && (
          <Skeleton isLoaded={!isLoading}>
            <HStack mt="2" textColor={idTextColor} spacing="1" mr={2}>
              <Text color={textColor}>Summarization</Text>
              <Tag>{project?.summarization_flag ? "On" : "Off"}</Tag>
            </HStack>
          </Skeleton>
          )}
        </Box>
        <Box
          border="1px solid"
          borderColor={borderColor}
          borderRadius="md"
          p={4}
          bg={boxBg}
        >
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="bold" color={textColor} mb={1}>
              System Template
            </Text>
            <Text fontSize="sm" color={idTextColor}>
              {project?.system_template || "None"}
            </Text>
          </Skeleton>
        </Box>
        {(project?.type  === "agent" || project?.type  === "askgpt" ) && (
        <Box
          border="1px solid"
          borderColor={borderColor}
          borderRadius="md"
          p={4}
          bg={boxBg}
        >
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="bold" color={textColor} mb={1}>
              Followup Template
            </Text>
            <Text fontSize="sm" color={idTextColor}>
              {project?.followup_template || "None"}
            </Text>
          </Skeleton>
        </Box>
        )}
        <Box
          border="1px solid"
          borderColor={borderColor}
          borderRadius="md"
          p={4}
          bg={boxBg}
        >
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="bold" color={textColor} mb={1}>
              Default String
            </Text>
            <Text fontSize="sm" color={idTextColor}>
              {project?.default_string || "None"}
            </Text>
          </Skeleton>
        </Box>
        <Box
          border="1px solid"
          borderColor={borderColor}
          borderRadius="md"
          p={4}
          bg={boxBg}
        >
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="bold" color={textColor} mb={1}>
              Similarity Top K
            </Text>
            <Text fontSize="sm" color={idTextColor}>
              {project?.similarity_top_k || "None"}
            </Text>
          </Skeleton>
        </Box>
      </Stack>

      {/* Modal for updating project details */}
      <Modal isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Project Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <UpdateProjectDetails
              projectId={selectedAssistant?.project_id}
              onClose={onClose}
            />
          </ModalBody>
          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}
