import { Avatar, Box, Button, Card, Grid, HStack, Input, Text, useColorModeValue } from '@chakra-ui/react';
import Profile from '../assets/img/Asset1.png';
import React from 'react';
import { LuRefreshCw } from "react-icons/lu";
import { FaArrowRight } from 'react-icons/fa';

export const AssistantPreview = ({ name, suggestions, shape, image, welcomeMessage }) => {
  const cardBg = useColorModeValue('gray.100', 'gray.700');

  return (
    <Box flex='0 0 40%' px={4}>
      <Card maxW='700px' mx='auto' py={2} px={5} bg={cardBg}>
        <Avatar src={image ? image : Profile} size='sm' mx='auto' />
        <Text textAlign='center' fontSize='sm'>{name}</Text>
        <HStack align='flex-start'>
          <Avatar src={image ? image : Profile} size='2xs' />
          <Box mt='1px'>
            <Text fontSize='xs' fontWeight='600'>Askgpt</Text>
            <Text fontSize='xs'>{welcomeMessage}</Text>
          </Box>
        </HStack>

        {suggestions.length > 0 && (
          <>
            <Text fontSize='xs' mt={3} mb={2}>Some Examples to help:</Text>
            <Grid templateColumns='repeat(2, 1fr)' gap={2}>
              {suggestions.map((suggestion, index) => (
                <Button whiteSpace='normal' height='auto' p={1} key={index} size='xs' fontSize='0.7rem' variant='outline' colorScheme='teal'>
                  {suggestion}
                </Button>
              ))}
            </Grid>
          </>
        )}
        <HStack my={3} cursor='not-allowed'>
          <Button variant='disable' cursor='not-allowed' size='xs' bg='white' shadow='base'><LuRefreshCw /></Button>
          <Box position='relative' flexGrow={1}>
            <Input readOnly cursor='not-allowed' variant='filled' w='100%' size='xs' shadow='base' />
            <Button cursor='not-allowed' variant='disable' boxSize='10px' position='absolute' top='50%' right='1px' transform="translateY(-50%)"><FaArrowRight /></Button>
          </Box>
        </HStack>
      </Card>
      {shape === 'square' &&
        <Button float='right' mr={4} mt={6} size='md' flexDirection='column' colorScheme='blue' height='auto' p='5px'>
          <Avatar src={image ? image : Profile} size='xs' mb={1} />
          <Text>{name}</Text>
        </Button>
      }
      {shape === 'circular' &&
        <Button float='right' mr={4} mt={6} size='md' colorScheme='blue' padding='5px 7px' borderRadius='40px'>
          <Avatar src={image ? image : Profile} size='xs' mr={1} />{name}
        </Button>
      }
    </Box>
  )
}
