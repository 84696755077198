import { Box, Button, Grid, Text } from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux'; 

export const Suggestions = ({ setInputMessage }) => {
  const suggestions = useSelector(state => state.assistants.details?.suggestions || []);

  return (
    <Box flex='1'>
      <Text fontSize='sm' fontWeight='semibold' color='gray' pb={2}>Some examples to help:</Text>
      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={3} mb={7} fontSize='xs' fontWeight='500'>
        {suggestions.length > 0 ? (
          suggestions.map((suggestion, index) => (
            <Button
              key={index}
              size='sm'
              variant='outline'
              fontWeight='500'
              fontSize='xs'
              colorScheme='teal'
              onClick={() => setInputMessage(suggestion)}
            >
              {suggestion}
            </Button>
          ))
        ) : (
          <Text>No suggestions available.</Text>
        )}
      </Grid>
    </Box>
  );
};