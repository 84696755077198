import { Flex } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { InputArea } from "./InputArea";
import { Messages } from "./Messages";
import { Suggestions } from "./Suggestions";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

export const PrivatePanel = ({ setShowPanel }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cancelRequest, setCancelRequest] = useState(null);
  const [sessionId, setSessionId] = useState(uuidv4());

  const { details } = useSelector((state) => state.assistants);
  const assistantId = details?.project_id || "Default Name";
  const { agentConfigs } = useSelector((state) => state.agent);
  const agentId = agentConfigs.length > 0 ? agentConfigs[0].agent_id : "";
  const useCases = details?.type || "";

  const sendMessage = async (customMessage = "") => {
    setIsLoading(true);
    const controller = new AbortController();
    const { signal } = controller;
    setCancelRequest(() => controller.abort.bind(controller));
    const messageToSend = customMessage || inputMessage;
    if (!customMessage) {
      setMessages((old) => [...old, { from: "user", text: inputMessage }]);
      setInputMessage("");
    } else {
      setMessages((old) => [...old, { from: "user", text: customMessage }]);
    }
    const requestBody = {
      project_id: assistantId,
      session_id: sessionId,
      user_message: messageToSend,
      ...(useCases === "agent" && { agent_id: agentId }),
    };

    const endpoint =
      useCases === "agent"
        ? "https://cognitive.nextai.co.in/agent/message/"
        : "https://cognitive.nextai.co.in/askgpt/message/";

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
        signal,
      });

      if (response.status === 200) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let partialResponse = "";

        while (true) {
          const { value, done } = await reader.read();

          if (done) {
            break;
          }

          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split("\n");

          for (let i = 0; i < lines.length - 1; i++) {
            const line = lines[i].trim();

            if (line.startsWith("data:")) {
              const data = JSON.parse(line.slice(5));
              const newChunk = data.answer || "";

              setMessages((oldMessages) => {
                const lastMessage = oldMessages[oldMessages.length - 1];
                if (lastMessage && lastMessage.from === "bot") {
                  return [
                    ...oldMessages.slice(0, -1),
                    { ...lastMessage, text: lastMessage.text + newChunk, chat_id: data.chat_id, clarify: data.sources }
                  ];
                } else {
                  setIsLoading(false);
                  return [
                    ...oldMessages,
                    { from: "bot", text: newChunk, chat_id: data.chat_id, user_message: messageToSend, clarify: data.sources, suggestions: data.suggestions }
                  ];
                }
              });
              partialResponse += newChunk;
            }
          }
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        setMessages((old) => [
          ...old,
          { from: "bot", text: "Cancelled response generation." },
        ]);
      } else {
        console.error("Failed to send message:", error);
      }
    } finally {
      setIsLoading(false);
      setCancelRequest(null);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    sendMessage(suggestion);
  };

  return (
    <Flex direction="column" height={{ base: "100%", md: "auto" }} p={5} pt={0}>
      {messages.length > 0 ? (
        <Messages
          messages={messages}
          isLoading={isLoading}
          onSourceSelected={(source) => console.log("Selected source:", source)}
          sessionId={sessionId}
        />
      ) : (
        <Suggestions setInputMessage={setInputMessage} />
      )}
      <InputArea
        inputMessage={inputMessage}
        cancelRequest={cancelRequest}
        setInputMessage={setInputMessage}
        sendMessage={() => sendMessage()}
        setMessages={setMessages}
        isLoading={isLoading}
        setSessionId={setSessionId}
        placeholder={"Ask questions regarding our platform..."}
      />
    </Flex>
  );
};