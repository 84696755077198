import React, { useState, useEffect } from "react";
import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Spinner,
  Stack,
  Text,
  useBoolean,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  useColorMode,
} from "@chakra-ui/react";
import { Field, Formik, Form } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import GoogleIcon from "../../assets/img/google.svg";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import Lottie from "react-lottie";
import animationData from "../../assets/animation/login.json";
import { Blur } from "../../components/Design/Blur";
import { useDispatch } from "react-redux";
import { getUserDetails } from "../../features/UserData/userActions";

export default function Login() {
  const [isVisible, { toggle: toggleVisibility }] = useBoolean(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { colorMode } = useColorMode();

  const from = location.state?.from?.pathname || "/";

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get("token");
    if (token) {
      localStorage.setItem("token", token);
      handleLoginSuccess(token);
    }
  }, [location, login]);

  const fetchUserDetailsAndNavigate = async () => {
    try {
      setIsLoading(true);
      const userDetails = await dispatch(getUserDetails()).unwrap();
      const { assistant_count } = userDetails;
      setIsLoading(false);
      if (assistant_count === 0) {
        navigate("/get-started", { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      setIsLoading(false);
      setErrorMessage("Failed to fetch user details");
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = "https://cognitive.nextai.co.in/auth/login/google";
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleLoginSuccess = async (token) => {
    login(token);
    await fetchUserDetailsAndNavigate();
  };

  return (
    <Flex direction={"row-reverse"} justifyContent={"space-between"}>
      <Stack
        bg={"gray.300"}
        borderLeft={"1px solid #f4f4f4"}
        flex={[0, 0.5]}
        position="relative"
      >
        <Box mt={20} p={8} borderRadius="md">
          <Heading fontWeight="bold" textAlign="center" color="black">
            Great to See You Again
          </Heading>
          <Box mt={-10} mb={-10}>
            <Lottie options={defaultOptions} height={400} width={400} />
          </Box>
          <Text
            mt={4}
            fontWeight="bold"
            fontSize="lg"
            textAlign="center"
            color="black"
          >
            Your Gen-AI Bots Await
          </Text>
          <Text mt={2} fontSize="md" textAlign="center" color="black">
            Seamlessly manage and integrate your AI-driven solutions.
          </Text>
        </Box>
      </Stack>
      <Center height={"100vh"} flex={[1, 0.5]}>
        <Blur
          position="absolute"
          top={-40}
          left={-20}
          style={{ filter: "blur(70px)" }}
        />
        <Stack
          alignItems={"center"}
          gap={4}
          borderRadius={8}
          py={8}
          px={[2, 12]}
          w={["80vw", "35vw"]}
        >
          <Heading
            w={"full"}
            fontWeight={"semibold"}
            fontSize={28}
            variant={"h3"}
          >
            Sign in
          </Heading>
          <Formik
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              try {
                const response = await axios.post(
                  `${process.env.REACT_APP_API_URL}/auth/login`,
                  {
                    email: values.email,
                    password: values.password,
                  }
                );
                const { token } = response.data;
                await handleLoginSuccess(token);
              } catch (error) {
                const errorDetails = error.response.data.detail;
                if (Array.isArray(errorDetails)) {
                  setErrorMessage(errorDetails.map((e) => e.msg).join(", "));
                } else {
                  setErrorMessage(errorDetails || "An unexpected error occurred");
                }
                setSubmitting(false);
              }
            }}
            initialValues={{
              email: "",
              password: "",
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form
                style={{
                  width: "100%",
                }}
              >
                <Stack gap={2} w={"full"}>
                  <FormControl isInvalid={!!errors.email && touched.email}>
                    <FormLabel fontSize={"sm"}>Email address</FormLabel>
                    <Field
                      fontSize="sm"
                      validate={(value) => {
                        let error;
                        if (!value) {
                          return "Email address is required";
                        }
                        if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                            value
                          )
                        ) {
                          return "Invalid email address";
                        }
                        return error;
                      }}
                      as={Input}
                      name="email"
                      rounded={6}
                      placeholder="Enter your email address"
                      type="email"
                      borderColor="teal.500"
                    />
                    <FormErrorMessage fontSize={"small"}>
                      {errors.email}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.password && touched.password}
                  >
                    <FormLabel fontSize={"sm"}>Password</FormLabel>
                    <InputGroup>
                      <Field
                        fontSize="sm"
                        validate={(value) => {
                          let error;
                          if (!value) {
                            error = "Password is required";
                          }
                          return error;
                        }}
                        as={Input}
                        name="password"
                        rounded={6}
                        placeholder="Enter password"
                        type={isVisible ? "text" : "password"}
                        borderColor="teal.500"
                      />
                      <InputRightElement>
                        <IconButton
                          variant={"none"}
                          size={"sm"}
                          aria-label=""
                          icon={
                            isVisible ? (
                              <EyeOffIcon size={20} />
                            ) : (
                              <EyeIcon size={20} />
                            )
                          }
                          onClick={toggleVisibility}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage fontSize={"small"}>
                      {errors.password}
                    </FormErrorMessage>
                  </FormControl>
                </Stack>
                {errorMessage && (
                  <Alert status="error" mt={4} rounded={6}>
                    <AlertIcon />
                    <AlertTitle></AlertTitle>
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                )}
                <Button
                  fontWeight={"semibold"}
                  mt={4}
                  variant={"solid"}
                  colorScheme={"teal"}
                  rounded={8}
                  w={"full"}
                  type="submit"
                  isLoading={isSubmitting || isLoading}
                >
                  {isLoading ? <Spinner size="sm" /> : "Continue with email"}
                </Button>
              </Form>
            )}
          </Formik>
          <Box w={"full"} position="relative" padding="5">
            <Divider borderColor="gray.500" />
            <AbsoluteCenter bg={colorMode === 'light' ? 'gray.200' : 'gray.800'} fontSize={"small"} px="4">
              OR
            </AbsoluteCenter>
          </Box>
          <Button
            fontWeight={"semibold"}
            alignItems={"center"}
            leftIcon={
              <Image verticalAlign={"middle"} src={GoogleIcon} boxSize={4} />
            }
            variant={"outline"}
            rounded={8}
            colorScheme="teal"
            w={"full"}
            onClick={handleGoogleLogin}
          >
            Continue with Google
          </Button>
          <Text mt={4} fontSize={"sm"}>
            Don't have an account?{" "}
            <Link
              href="/signup"
              fontWeight={"semibold"}
              textDecor={"underline"}
              color="teal.400"
            >
              Create one
            </Link>
          </Text>
        </Stack>
      </Center>
    </Flex>
  );
}
