import React from 'react';
import { useLocation } from 'react-router-dom';
import Head from '../../components/Customization/Head';
import Body from '../../components/Customization/Body';

const Customization = () => {
  const location = useLocation();
  const assistant = location.state?.assistant;

  return (
    <>
      <Head assistant={assistant} />
      <Body assistant={assistant} />
    </>
  );
};

export default Customization;
