import { Box, Button, Flex, Input, useToast, IconButton, useColorMode, useColorModeValue } from "@chakra-ui/react";
import { SmallCloseIcon } from '@chakra-ui/icons';
import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { LuRefreshCw } from "react-icons/lu";
import { v4 as uuidv4 } from 'uuid';

export const InputArea = ({
  inputMessage,
  setInputMessage,
  sendMessage,
  setMessages,
  placeholder,
  setSessionId,
  isLoading,
  cancelRequest
}) => {
  const toast = useToast();
  const { colorMode, toggleColorMode } = useColorMode();
  const bgColor = useColorModeValue('gray.100', 'gray.800');

  const handleRefresh = () => {
    const newSessionId = uuidv4(); 
    if (setSessionId) { 
      setSessionId(newSessionId); 
    }
    setMessages([]);
    toast({
      title: 'New Session Started',
      status: 'success',
      duration: 3000,
      isClosable: true,
    }); 
  };

  return (
    <Flex
      position={{ base: "sticky", md: "relative" }}
      bottom={{ base: 0, md: "auto" }}
      bg={bgColor}
      py={{ base: 3, md: 0 }}
    >
      {setMessages && (
        <Button
          mr="10px"
          bg={bgColor}
          _hover={{ shadow: "md" }}
          fontSize="20px"
          onClick={() => {
            setMessages([]);
            handleRefresh();
          }}
        >
          <LuRefreshCw />
        </Button>
      )}
      <Box position="relative" flexGrow={1}>
        <Input
          variant="filled"
          pr="50px"
          placeholder={placeholder}
          value={inputMessage}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          onChange={(e) => setInputMessage(e.target.value)}
        />
        {isLoading ? (
          <IconButton
            icon={<SmallCloseIcon />}
            aria-label="Cancel request"
            position='absolute'
            right='5px'
            top='50%'
            size='sm'
            colorScheme='red'
            transform='translateY(-50%)'
            onClick={cancelRequest}
          />
        ) : (
          <Button
            position='absolute'
            right='5px'
            top='50%'
            size='sm'
            colorScheme='blue'
            transform='translateY(-50%)'
            cursor='pointer'
            isDisabled={inputMessage.trim().length <= 0}
            onClick={sendMessage}
          >
            <FaArrowRight />
          </Button>
        )}
      </Box>
    </Flex>
  );
};
