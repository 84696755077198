import React, { useRef, useEffect, useState } from 'react';
import { Avatar, Box, Flex, Text, HStack, Spinner } from '@chakra-ui/react';
import { AnswerButtons } from './AnswerButtons';
import { Carousel } from './Carousel';
import { SourcesGPT } from './SourceGpt'; // Correct path to Sources component
import { useSelector } from 'react-redux';
import CodeBlock from './CodeBlock'; // Correct path to CodeBlock component

export const MessagesSearch = ({ messages, setShowPanel, onSourceSelected, sessionId, isLoading }) => {
    const endOfMessagesRef = useRef();
    const [loadingText, setLoadingText] = useState("...");
    const assistantName = useSelector(state => state.assistants.details?.name || "GPT");
    const assistantImage = useSelector(state => state.assistants.details?.widget_image || "Bot_Avatar");

    useEffect(() => {

        const loadingTexts = [
            "Analyzing context...",
            "Generating response...",
        ];
        let index = 0;
        const interval = setInterval(() => {
            setLoadingText(loadingTexts[index]);
            index = (index + 1) % loadingTexts.length;
        }, 2000);

        return () => clearInterval(interval);
    }, [messages]);

    const handleSourceSelection = (selectedSource) => {
        onSourceSelected(selectedSource); 
    };

    const formatMessage = (text) => {
        const parts = text.split("```");
        return parts.map((part, index) => {
            if (index % 2 === 1) {
                return <CodeBlock code={part.trim()} key={index} />;
            } else {
                return (
                    <Text textStyle='hostStyleOverride' fontWeight="500" fontSize="0.9rem" mt={2} key={index}>
                        {part}
                    </Text>
                );
            }
        });
    };

    return (
        <Box flex="1">
            {messages.map((message, index) => (
                <Box key={index} mb={4}>
                    <Flex align="start">
                        <Avatar
                            size="xs"
                            mr={2}
                            src={message.from !== "user" ? assistantImage : undefined}
                        />
                        <Box minWidth={0} flexGrow={1}>
                            <Text fontWeight="bold" fontSize="0.9rem">
                                {message.from === "user" ? "You" : assistantName}
                            </Text>
                            <Box>{formatMessage(message.text)}</Box>
                            {message.clarify && (
                                <SourcesGPT
                                    sources={message.clarify} 
                                    onSourceSelected={handleSourceSelection} 
                                />
                            )}
                            {message.products && (
                                <Carousel 
                                    products={message.products} 
                                    setShowPanel={setShowPanel} 
                                />
                            )}
                            {message.from === "bot" && (
                                <AnswerButtons
                                    inputMessage={message.user_message}
                                    message={message.text}
                                    chatId={message.chat_id}
                                    sessionId={sessionId}
                                />
                            )}
                        </Box>
                    </Flex>
                    {message.from === "bot" && <hr style={{ marginBottom: "30px" }} />}
                </Box>
            ))}
            {isLoading && (
                <Flex mb={3} justifyContent="left" alignItems="center">
                    <Avatar size="xs" mr={2} src={assistantImage} />
                    <Box minWidth={0} flexGrow={1}>
                        <Text fontWeight="bold" fontSize="0.9rem">
                            {assistantName}
                        </Text>
                        <HStack spacing={2}>
                            <Spinner
                                size="sm"
                                color="purple.500"
                                thickness="3px"
                                emptyColor="gray.200"
                            />
                            <Text>{loadingText}</Text>
                        </HStack>
                    </Box>
                </Flex>
            )}
            <div ref={endOfMessagesRef}></div>
        </Box>
    );
};