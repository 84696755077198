import axios from "axios";
const url = process.env.REACT_APP_API_URL;
const authToken = localStorage.getItem('token');

export const generateNewApiKeyApi = async (data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${url}/auth/generate-new-api-key`,
      data,
      {
        headers: {
          authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("API Error:", error.response ? error.response.data : error.message);
    return thunkAPI.rejectWithValue({
      message: error.response?.data?.message || error.message,
      status: error.response?.status,
    });
  }
};

export const deleteApiKeyApi = async (api_key, thunkAPI) => {
    try {
      const response = await axios.delete(
        `${url}/auth/delete-api-key`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
          data: { api_key }
        }
      );
      return response.data;
    } catch (error) {
      console.error("API Error:", error.response ? error.response.data : error.message);
      return thunkAPI.rejectWithValue({
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
      });
    }
  };