import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const getAuthToken = () => localStorage.getItem('token');

const getAuthHeaders = () => ({
  'Authorization': `Bearer ${getAuthToken()}`,
  'Content-Type': 'application/json',
});

export const createAgentConfigApi = async (data) => {
  return axios.post(
    `${BASE_URL}/agent/agent_configs/`,
    data,
    { headers: getAuthHeaders() }
  );
};

export const getAgentConfigApi = async (projectId) => {
  return axios.get(
    `${BASE_URL}/agent/agent_configs/`,
    {
      headers: getAuthHeaders(),
      params: { project_id: projectId },
    }
  );
};

export const updateAgentConfigApi = async (agentId, data) => {
  return axios.put(
    `${BASE_URL}/agent/agent_configs/${agentId}`,
    data,
    { headers: getAuthHeaders() }
  );
};

export const deleteAgentConfigApi = async (agentId) => {
  return axios.delete(
    `${BASE_URL}/agent/agent_configs/${agentId}`,
    { headers: getAuthHeaders() }
  );
};
