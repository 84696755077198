import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchIngestionHistoryApi, fetchIngestionSourcesApi, updateSourceApi, deleteSourceApi , syncSourceApi} from "../../apis/DataIngestion";

export const fetchIngestionHistoryAsync = createAsyncThunk(
  "ingestsource/fetchIngestionHistory",
  async (projectId, thunkAPI) => {
    try {
      const response = await fetchIngestionHistoryApi(projectId);
      if (response.length === 0) {
        return thunkAPI.rejectWithValue("No sources found");
      }
      return response;
    } catch (error) {
      console.error("Thunk API Error:", error); 
    }
  }
);

export const fetchIngestionSourcesAsync = createAsyncThunk(
  "ingestsource/fetchIngestionSources",
  async (projectId, thunkAPI) => {
    try {
      const response = await fetchIngestionSourcesApi(projectId);
      if (response.length === 0) {
        return thunkAPI.rejectWithValue("No sources found");
      }
      return response;
    } catch (error) {
      console.error("Thunk API Error:", error); 
    }
  }
);

export const updateSourceAsync = createAsyncThunk(
  "ingestsource/updateSource",
  async ({ sourceId, payload }, thunkAPI) => {
    try {
      const response = await updateSourceApi(sourceId, payload);
      return response;
    } catch (error) {
      console.error("Thunk API Error:", error); 
    }
  }
);

export const syncSourceAsync = createAsyncThunk(
  "ingestsource/syncSource",
  async (collectionName, thunkAPI) => {
    try {
      const response = await syncSourceApi(collectionName);
      return response;
    } catch (error) {
      console.error("Thunk API Error:", error); 
    }
  }
);

export const deleteSourceAsync = createAsyncThunk(
  "ingestsource/deleteSource",
  async (collectionName, thunkAPI) => {
    try {
      const response = await deleteSourceApi(collectionName);
      return { collection_name: collectionName };
    } catch (error) {
      console.error("Thunk API Error:", error); 
    }
  }
);