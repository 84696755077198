import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Text, useToast } from "@chakra-ui/react";
import upgradeAnimation from '../../assets/animation/verify.json'; 
import Lottie from 'react-lottie';

const VerifyEmail = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: upgradeAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        toast({
          title: "Invalid verification link.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        navigate("/register");
        return;
      }

      try {
        await axios.get(
          `https://cognitive.nextai.co.in/auth/verify-email?token=${token}`
        );
        toast({
          title: "Email verified successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/login");
      } catch (error) {
        toast({
          title: "Email verified successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/login");
      }
    };

    verifyEmail();
  }, [token, toast, navigate]);

  return (
    <Box textAlign="center" mt="50px">
      <Box mb={4}>
        <Lottie options={defaultOption} height={150} width={150} />
      </Box>
      <Text color="green.500" fontSize="xl">
        Verifying your email...
      </Text>
    </Box>
  );
};

export default VerifyEmail;
