import { createSlice } from '@reduxjs/toolkit';
import { fetchIngestionHistoryAsync, fetchIngestionSourcesAsync, updateSourceAsync, deleteSourceAsync, syncSourceAsync } from "./DataIngestionAction";

const dataIngestionSlice = createSlice({
  name: "ingestsource",
  initialState: {
    ingestionHistory: [],
    loading: false,
    error: null,
    success: null,
  },
  reducers: {
    resetIngestsource: (state) => {
      state.ingestionHistory = [];
      state.loading = false;
      state.error = null;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIngestionHistoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchIngestionHistoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.ingestionHistory = action.payload;
      })
      .addCase(fetchIngestionHistoryAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(fetchIngestionSourcesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchIngestionSourcesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.ingestionHistory = action.payload;
      })
      .addCase(fetchIngestionSourcesAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(updateSourceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateSourceAsync.fulfilled, (state, action) => {
        state.loading = false;
        const updatedSource = action.payload;
        state.ingestionHistory = state.ingestionHistory.map(source => 
          source.id === updatedSource.id ? updatedSource : source
        );
      })
      .addCase(updateSourceAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(syncSourceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(syncSourceAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(syncSourceAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      })
      .addCase(deleteSourceAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteSourceAsync.fulfilled, (state, action) => {
        state.loading = false;
        const deletedSourceId = action.payload.collection_name;
        state.ingestionHistory = state.ingestionHistory.filter(source => source.collection_id !== deletedSourceId);
      })
      .addCase(deleteSourceAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; 
      });
  },
});

export const { resetIngestsource } = dataIngestionSlice.actions;
export default dataIngestionSlice.reducer;
