import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useToast,
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Textarea,
  Box,
  Switch,
  Text,
} from "@chakra-ui/react";
import { AssistantPreview } from "../AssistantPreview";
import {
  updateAssistantAsync,
  fetchAssistantDetailsAsync,
  fetchProjectDetailsAsync,
} from "../../features/Assistant/AssistantAction";

export const WidgetEdit = ({ projectId, onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const { details, loading, error } = useSelector((state) => state.assistants);

  const [suggestionInput, setSuggestionInput] = useState("");
  const [assistantName, setAssistantName] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [shape, setShape] = useState("");
  const [iconImage, setIconImage] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [changeTag, setChangeTag] = useState(null);
  const [assistantType, setAssistantType] = useState(""); 
  const token = localStorage.getItem("token");

  const inputRef = useRef(null);

  useEffect(() => {
    if (details) {
      setAssistantName(details.name);
      setWelcomeMessage(details.welcome_message);
      setSuggestions(details.suggestions || []);
      setShape(details.shape);
      setIconImage(details.widget_image);
      setAssistantType(details.type); 
    }
  }, [details]);

  useEffect(() => {
    const closeSuggestion = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setChangeTag(null);
      }
    };

    document.addEventListener("mousedown", closeSuggestion);

    return () => document.removeEventListener("mousedown", closeSuggestion);
  }, []);

  const changeSuggestion = (index, e) => {
    const newSuggestions = [...suggestions];
    newSuggestions[index] = e.target.value;
    setSuggestions(newSuggestions);
  };

  const removeSuggestion = (index) => {
    setSuggestions(suggestions.filter((_, i) => i !== index));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIconImage(URL.createObjectURL(file));
      setIconFile(file);
    }
  };

  const uploadImageToApi = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("http://localhost:8000/assistant/widget-image/", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        body: formData,
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Image upload failed");
      }

      return data.file_url; 
    } catch (error) {
      throw new Error("Image upload failed");
    }
  };

  const handleUpdate = async () => {
    if (!assistantName.trim() || !welcomeMessage.trim() || !suggestions.length) {
      toast({
        title: "Error",
        description: "All fields are required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      let imageURL = iconImage;

      if (iconFile) {
        toast({
          title: "Uploading Image",
          description: "Please wait while the image is being uploaded.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });

        imageURL = await uploadImageToApi(iconFile);
      }

      const assistantData = {
        name: assistantName,
        welcome_message: welcomeMessage,
        suggestions: suggestions,
        widget_image: imageURL,
        shape: shape,
        type: assistantType, 
      };

      dispatch(updateAssistantAsync({ projectId, assistantData }))
        .unwrap()
        .then(() => {
          toast({
            title: "Assistant Updated",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          dispatch(fetchProjectDetailsAsync(projectId));
          onClose();
          window.location.reload(); 
        })
        .catch((error) => {
          toast({
            title: "Error Updating Assistant",
            description: error.message || "Failed to update assistant details.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Image upload failed.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleTypeSelection = (type) => {
    setAssistantType(type);
  };

  return (
    <Box w="full" p={5} borderWidth="1px" borderRadius="lg">
      <Flex direction={{ base: "column", lg: "row" }} align="start" justify="space-between">
        <Box flex="1" p={4} w={{ base: "100%", lg: "50%" }}>
          <FormControl>
            <FormLabel>Assistant Name</FormLabel>
            <Input
              type="text"
              border="1px solid #dbdfea"
              value={assistantName}
              onChange={(e) => setAssistantName(e.target.value)}
            />
          </FormControl>
          <FormControl mt={5}>
            <FormLabel>Welcome Message</FormLabel>
            <Textarea
              type="text"
              border="1px solid #dbdfea"
              value={welcomeMessage}
              onChange={(e) => setWelcomeMessage(e.target.value)}
            />
          </FormControl>
          <FormControl mt={5}>
            <FormLabel>Add suggestions</FormLabel>
            <Stack mb={2}>
              {suggestions.map((suggestion, index) =>
                changeTag !== index ? (
                  <Tag
                    key={index}
                    width="fit-content"
                    _hover={{ cursor: "pointer" }}
                  >
                    <TagLabel onClick={() => setChangeTag(index)}>
                      {suggestion}
                    </TagLabel>
                    <TagCloseButton onClick={() => removeSuggestion(index)} />
                  </Tag>
                ) : (
                  <Input
                    size="sm"
                    ref={inputRef}
                    value={suggestions[index]}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setChangeTag(null);
                      }
                    }}
                    onChange={(e) => changeSuggestion(index, e)}
                  />
                )
              )}
            </Stack>
            <Input
              type="text"
              border="1px solid #dbdfea"
              onChange={(e) => setSuggestionInput(e.target.value)}
              value={suggestionInput}
              onKeyDown={(e) => {
                if (e.key === "Enter" && suggestionInput.trim()) {
                  setSuggestions((old) => [...old, suggestionInput]);
                  setSuggestionInput("");
                }
              }}
            />
          </FormControl>
          <FormControl mt={5}>
            <FormLabel>Widget Image</FormLabel>
            <HStack>
              {iconImage && <Avatar src={iconImage} />}
              <Button
                as="label"
                htmlFor="image-upload"
                colorScheme="teal"
                size="sm"
                ml={2}
              >
                Upload Image
              </Button>
              <Input
                type="file"
                accept="image/*"
                id="image-upload"
                display="none"
                onChange={handleImageChange}
              />
            </HStack>
          </FormControl>
          <FormControl mt={5}>
            <FormLabel>Widget Icon Shape</FormLabel>
            <RadioGroup value={shape} onChange={setShape}>
              <HStack>
                <Radio value="square">Square</Radio>
                <Radio value="circular">Circular</Radio>
              </HStack>
            </RadioGroup>
          </FormControl>
          {(details.type === "searchgpt" || details.type === "productgpt") && (
            <FormControl mt={5} mb={5}>
              <FormLabel>Assistant Type</FormLabel>
              <Flex align="center" justify="space-between" mt={2} p={4} bg="gray.100" borderRadius="md">
                <Text fontSize="sm" fontWeight="bold" color={assistantType === "productgpt" ? "teal.500" : "gray.600"}>ProductGPT</Text>
                <Switch
                  isChecked={assistantType === "searchgpt"}
                  onChange={() => handleTypeSelection(assistantType === "searchgpt" ? "productgpt" : "searchgpt")}
                  colorScheme="teal"
                  size="md"
                />
                <Text fontSize="sm" fontWeight="bold" color={assistantType === "searchgpt" ? "teal.500" : "gray.600"}>SearchGPT</Text>
              </Flex>
            </FormControl>
          )}
          <Button size="sm" mt={5} colorScheme="teal" onClick={handleUpdate}>
            Set Assistant Details
          </Button>
        </Box>
        <Box flex="1" p={4} w={{ base: "100%", lg: "50%" }}>
          <AssistantPreview
            name={assistantName}
            suggestions={suggestions}
            shape={shape}
            image={iconImage}
            welcomeMessage={welcomeMessage}
          />
        </Box>
      </Flex>
    </Box>
  );
};
