import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  Img,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
  HStack,
  Tag,
  TagLabel,
  TagCloseButton,
  Avatar,
  useToast,
} from "@chakra-ui/react";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BrainIcon,
  CodesandboxIcon,
  HelpCircleIcon,
} from "lucide-react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import Logo from "../../assets/img/icon.png";
import Asset1 from "../../assets/img/Asset1.png";
import Asset2 from "../../assets/img/Asset2.png";
import Asset3 from "../../assets/img/Asset1.png";
import { Formik, Form, Field } from "formik";
import Lottie from "lottie-react";
import LoadingAnimation from "../../assets/animation/loading.json";
import Timer from "../../components/Widget/timer/timer";
import { useNavigate } from "react-router-dom";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  createAssistantAsync,
  fetchAssistantsAsync,
} from "../../features/Assistant/AssistantAction";
import { getUserDetails } from "../../features/UserData/userActions";
import { AssistantPreview } from "../../components/AssistantPreview";

export default function GetStarted() {
  const dispatch = useDispatch();
  const [currentTab, setCurrentTab] = useState(0);
  const [currentTemplate, setCurrentTemplate] = useState(-1);
  const [countingStatus, setCountingStatus] = useState("pause");
  const { onOpen, onClose, isOpen } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();
  const token = localStorage.getItem("token");
  const userDetails = useSelector((state) => state.user.userDetails);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const statusHandler = (item) => {
    if (item.label === "short break") {
      navigate("/");
    }
  };

  const [assistantName, setAssistantName] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState(
    "Hey Welcome to my website!"
  );
  const [useCase, setUseCase] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionInput, setSuggestionInput] = useState("");
  const [iconImage, setIconImage] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [shape, setShape] = useState("square");
  const [changeTag, setChangeTag] = useState(-1);

  const removeSuggestion = (index) => {
    setSuggestions(suggestions.filter((_, i) => i !== index));
  };

  const changeSuggestion = (index, event) => {
    setSuggestions(
      suggestions.map((suggestion, i) =>
        i === index ? event.target.value : suggestion
      )
    );
  };

  const closeSuggestion = (event) => {
    setChangeTag(-1);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIconImage(URL.createObjectURL(file));
      setIconFile(file);
    }
  };

  const uploadImageToApi = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        "https://cognitive.nextai.co.in/assistant/widget-image/",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Image upload failed");
      }

      return data.file_url; // Return the file URL from the API response
    } catch (error) {
      throw new Error("Image upload failed");
    }
  };

  const handleSubmit = async (values, actions) => {
    const { assistantName, welcomeMessage, suggestions, iconFile, useCase } =
      values;

    if (
      !assistantName.trim() ||
      !welcomeMessage.trim() ||
      !useCase ||
      !suggestions.length
    ) {
      toast({
        title: "Error",
        description: "All fields are required.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    if (!iconFile) {
      toast({
        title: "Error",
        description: "Please add an image.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    if (userDetails) {
      const { plan_details, assistant_count } = userDetails;
      const maxAssistants = plan_details.features.assistantCount;

      if (assistant_count >= maxAssistants) {
        toast({
          title: "Limit Reached",
          description: `Your current plan allows up to ${maxAssistants} assistants. Please upgrade your plan to create more assistants.`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        return;
      }
    }

    try {
      let imageURL = iconImage;

      if (iconFile) {
        toast({
          title: "Uploading Image",
          description: "Please wait while the image is being uploaded.",
          status: "info",
          duration: 3000,
          isClosable: true,
          position: "bottom",
        });

        imageURL = await uploadImageToApi(iconFile);
      }

      const assistantData = {
        name: assistantName,
        welcome_message: welcomeMessage,
        suggestions,
        shape,
        use_case: useCase,
        widget_image: imageURL,
      };

      dispatch(createAssistantAsync({ assistantData, token })).then(() => {
        toast({
          title: "Assistant Created",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        dispatch(fetchAssistantsAsync(token));
        onOpen();
        setCurrentTab(3);
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Image upload failed.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  return (
    <Flex
      h="100vh"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
    >
      <Center flex={1} height={"100%"} overflowY="auto">
        {currentTab !== 3 ? (
          <Stack w={{ base: "90vw", md: "60vw" }} gap={0}>
            <Tabs
              isLazy
              index={currentTab}
              size={"md"}
              colorScheme="teal"
              onChange={(index) => setCurrentTab(index)}
            >
              <TabList mb={4} gap={4} borderBottom={0}>
                <Tab
                  isDisabled={currentTab !== 0}
                  fontSize={"sm"}
                  gap={2}
                  fontWeight={600}
                >
                  <CodesandboxIcon size={20} /> Onboarding
                </Tab>
                <Tab
                  isDisabled={currentTab !== 1}
                  fontSize={"sm"}
                  gap={2}
                  fontWeight={600}
                >
                  <CodesandboxIcon size={20} /> Choose Usecase
                </Tab>
                <Tab
                  isDisabled={currentTab !== 2}
                  fontSize={"sm"}
                  gap={2}
                  fontWeight={600}
                >
                  <BrainIcon size={20} /> Setup Assistant
                </Tab>
              </TabList>
              <TabPanels>
                <TabPanel p={0} h="calc(100vh - 150px)">
                  <Heading size={"lg"}>Welcome to SearchGPT</Heading>
                  <Text fontSize={"sm"} mb={10} mt={1}>
                    Deploy autonomous AI agents that reliably complete work.
                  </Text>
                  <Popover isOpen={isOpen} onClose={onClose}>
                    <PopoverTrigger>
                      <Button
                        size={"sm"}
                        colorScheme={"teal"}
                        rounded={4}
                        w={"fit-content"}
                        fontSize={"sm"}
                        onClick={() => {
                          setCurrentTab(currentTab + 1);
                          onOpen();
                        }}
                      >
                        Continue
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader>Welcome!</PopoverHeader>
                      <PopoverBody>
                        You're now ready to start using SearchGPT. Click
                        "Continue" to proceed.
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </TabPanel>
                <TabPanel p={0} h="calc(100vh - 150px)">
                  <Heading size={"lg"}>Select a Usecase</Heading>
                  <Text fontSize={"sm"} mb={5} mt={0}>
                    Select the type of AI Assistant bot you want to create.
                  </Text>
                  <Grid
                    gap={3}
                    gridTemplateColumns={{
                      base: "repeat(1, 1fr)",
                      md: "repeat(3, 1fr)",
                    }}
                  >
                    <Stack
                      pos={"relative"}
                      transition={"all 0.3s"}
                      cursor={"pointer"}
                      style={{
                        background: currentTemplate === 0 ? "#d7d4fd" : "white",
                        color: currentTemplate === 0 ? "black" : "black",
                      }}
                      onClick={() => {
                        setCurrentTemplate(0);
                        setUseCase("agent");
                      }}
                      border={`1px solid ${
                        currentTemplate === 0 ? "#d7d4fd" : "#e4e4e4"
                      }`}
                      bg={"white"}
                      p={4}
                      borderRadius={12}
                    >
                      <CheckCircleIcon
                        boxSize={5}
                        display={currentTemplate === 0 ? "block" : "none"}
                        pos={"absolute"}
                        top={4}
                        right={4}
                        color={"var(--chakra-colors-teal-500)"}
                      />
                      <Img w={16} h={16} src={Asset1} />
                      <Stack gap={1}>
                        <Heading size={"md"}>Agents</Heading>
                        <Text
                          fontSize={"sm"}
                          color={currentTemplate === 1 ? "black" : "gray"}
                        >
                          Equip them with tools and teach them dynamic
                          workflows.
                        </Text>
                      </Stack>
                    </Stack>
                    <Stack
                      pos={"relative"}
                      transition={"all 0.3s"}
                      cursor={"pointer"}
                      style={{
                        background: currentTemplate === 1 ? "#d7d4fd" : "white",
                        color: currentTemplate === 1 ? "black" : "black",
                      }}
                      onClick={() => {
                        setCurrentTemplate(1);
                        setUseCase("askgpt");
                      }}
                      border={`1px solid ${
                        currentTemplate === 1 ? "#d7d4fd" : "#e4e4e4"
                      }`}
                      bg={"white"}
                      p={4}
                      borderRadius={12}
                    >
                      <CheckCircleIcon
                        boxSize={5}
                        display={currentTemplate === 1 ? "block" : "none"}
                        pos={"absolute"}
                        top={4}
                        right={4}
                        color={"var(--chakra-colors-teal-500)"}
                      />
                      <Img w={16} h={16} src={Asset3} />
                      <Stack gap={1}>
                        <Heading size={"md"}>AskGPT</Heading>
                        <Text
                          fontSize={"sm"}
                          color={currentTemplate === 2 ? "black" : "gray"}
                        >
                          Information / Data from documents & sources.
                        </Text>
                      </Stack>
                    </Stack>
                    <Stack
                      pos={"relative"}
                      transition={"all 0.3s"}
                      cursor={"pointer"}
                      style={{
                        background: currentTemplate === 2 ? "#d7d4fd" : "white",
                        color: currentTemplate === 2 ? "black" : "black",
                      }}
                      onClick={() => {
                        setCurrentTemplate(2);
                        setUseCase("productgpt");
                      }}
                      border={`1px solid ${
                        currentTemplate === 2 ? "#d7d4fd" : "#e4e4e4"
                      }`}
                      bg={"white"}
                      p={4}
                      borderRadius={12}
                    >
                      <CheckCircleIcon
                        boxSize={5}
                        display={currentTemplate === 2 ? "block" : "none"}
                        pos={"absolute"}
                        top={4}
                        right={4}
                        color={"var(--chakra-colors-teal-500)"}
                      />
                      <Img w={16} h={16} src={Asset2} />
                      <Stack gap={1}>
                        <Heading size={"md"}>ProductGPT</Heading>
                        <Text
                          fontSize={"sm"}
                          color={currentTemplate === 2 ? "black" : "gray"}
                        >
                          For Product Discovery & Recommendation
                        </Text>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Flex justifyContent={"space-between"} w={"full"} mt={6}>
                    <Button
                      onClick={() => {
                        setCurrentTab(0);
                      }}
                      size={"sm"}
                      leftIcon={<ArrowLeftIcon size={16} />}
                    >
                      Prev
                    </Button>
                    <Button
                      onClick={() => {
                        setCurrentTab(2);
                      }}
                      isDisabled={currentTemplate < 0}
                      size={"sm"}
                      colorScheme={"teal"}
                      rightIcon={<ArrowRightIcon size={16} />}
                    >
                      Next
                    </Button>
                  </Flex>
                </TabPanel>
                <TabPanel p={0} h="calc(100vh - 150px)">
                  <Flex direction={{ base: "column", md: "row" }} h="100%">
                    <Box flex={1} overflowY="auto">
                      <Heading size={"lg"}>About assistant</Heading>
                      <Text fontSize={"sm"} mb={5} mt={0}>
                        Enter about your assistant details to get started.
                      </Text>
                      <Formik
                        initialValues={{
                          assistantName: "",
                          welcomeMessage: "",
                          suggestions: [],
                          iconFile: null,
                          useCase: useCase,
                        }}
                        validate={(values) => {
                          const errors = {};
                          if (!values.assistantName) {
                            errors.assistantName = "Required";
                          }
                          if (!values.welcomeMessage) {
                            errors.welcomeMessage = "Required";
                          }
                          if (!values.suggestions.length) {
                            errors.suggestions =
                              "At least one suggestion is required";
                          }
                          if (!values.iconFile) {
                            errors.iconFile = "Required";
                          }
                          return errors;
                        }}
                        onSubmit={(values, actions) => {
                          handleSubmit(values, actions);
                        }}
                      >
                        {({
                          errors,
                          touched,
                          values,
                          setFieldValue,
                          isValid,
                        }) => (
                          <Form style={{ width: "100%" }}>
                            <Stack gap={3} mt={0}>
                              <FormControl isRequired>
                                <FormLabel>Assistant Name</FormLabel>
                                <Input
                                  type="text"
                                  name="assistantName"
                                  value={values.assistantName}
                                  onChange={(e) => {
                                    setAssistantName(e.target.value);
                                    setFieldValue(
                                      "assistantName",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter assistant name"
                                />
                                {errors.assistantName &&
                                  touched.assistantName && (
                                    <Text color="red.500" fontSize="sm">
                                      {errors.assistantName}
                                    </Text>
                                  )}
                              </FormControl>
                              <FormControl isRequired>
                                <FormLabel>Welcome Message</FormLabel>
                                <Textarea
                                  name="welcomeMessage"
                                  value={values.welcomeMessage}
                                  onChange={(e) => {
                                    setWelcomeMessage(e.target.value);
                                    setFieldValue(
                                      "welcomeMessage",
                                      e.target.value
                                    );
                                  }}
                                  placeholder="Enter a welcome message"
                                />
                                {errors.welcomeMessage &&
                                  touched.welcomeMessage && (
                                    <Text color="red.500" fontSize="sm">
                                      {errors.welcomeMessage}
                                    </Text>
                                  )}
                              </FormControl>
                              <FormControl>
                                <FormLabel>Add Suggestions</FormLabel>
                                <Stack spacing={2}>
                                  {values.suggestions.map((suggestion, index) =>
                                    changeTag !== index ? (
                                      <Tag
                                        key={index}
                                        size="lg"
                                        variant="solid"
                                        colorScheme="teal"
                                      >
                                        <TagLabel
                                          onClick={() => setChangeTag(index)}
                                        >
                                          {suggestion}
                                        </TagLabel>
                                        <TagCloseButton
                                          onClick={() => {
                                            setSuggestions(
                                              values.suggestions.filter(
                                                (_, i) => i !== index
                                              )
                                            );
                                            setFieldValue(
                                              "suggestions",
                                              values.suggestions.filter(
                                                (_, i) => i !== index
                                              )
                                            );
                                          }}
                                        />
                                      </Tag>
                                    ) : (
                                      <Input
                                        size="sm"
                                        value={values.suggestions[index]}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            closeSuggestion(e);
                                          }
                                        }}
                                        onChange={(e) => {
                                          const updatedSuggestions = [
                                            ...values.suggestions,
                                          ];
                                          updatedSuggestions[index] =
                                            e.target.value;
                                          setSuggestions(updatedSuggestions);
                                          setFieldValue(
                                            `suggestions[${index}]`,
                                            e.target.value
                                          );
                                        }}
                                      />
                                    )
                                  )}
                                  <Input
                                    value={suggestionInput}
                                    onChange={(e) =>
                                      setSuggestionInput(e.target.value)
                                    }
                                    placeholder="Type a suggestion and press Enter"
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === "Enter" &&
                                        suggestionInput.trim()
                                      ) {
                                        e.preventDefault();
                                        const updatedSuggestions = [
                                          ...values.suggestions,
                                          suggestionInput,
                                        ];
                                        setSuggestions(updatedSuggestions);
                                        setFieldValue(
                                          "suggestions",
                                          updatedSuggestions
                                        );
                                        setSuggestionInput("");
                                      }
                                    }}
                                  />
                                </Stack>
                                {errors.suggestions && touched.suggestions && (
                                  <Text color="red.500" fontSize="sm">
                                    {errors.suggestions}
                                  </Text>
                                )}
                              </FormControl>
                              <FormControl isRequired>
                                <FormLabel>Widget Image</FormLabel>
                                <HStack>
                                  {iconImage && <Avatar src={iconImage} />}
                                  <Button
                                    as="label"
                                    htmlFor="image-upload"
                                    colorScheme="teal"
                                  >
                                    Upload Image
                                  </Button>
                                  <Input
                                    type="file"
                                    id="image-upload"
                                    accept="image/*"
                                    display="none"
                                    onChange={(e) => {
                                      handleImageChange(e);
                                      setFieldValue(
                                        "iconFile",
                                        e.target.files[0]
                                      );
                                    }}
                                  />
                                </HStack>
                                {errors.iconFile && touched.iconFile && (
                                  <Text color="red.500" fontSize="sm">
                                    {errors.iconFile}
                                  </Text>
                                )}
                              </FormControl>
                              <FormControl isRequired>
                                <FormLabel>Widget Icon Shape</FormLabel>
                                <HStack>
                                  <Button
                                    size="sm"
                                    onClick={() => setShape("square")}
                                    colorScheme={
                                      shape === "square" ? "teal" : "gray"
                                    }
                                  >
                                    Square
                                  </Button>
                                  <Button
                                    size="sm"
                                    onClick={() => setShape("circular")}
                                    colorScheme={
                                      shape === "circular" ? "teal" : "gray"
                                    }
                                  >
                                    Circular
                                  </Button>
                                </HStack>
                              </FormControl>
                              <Flex
                                justifyContent={"space-between"}
                                w={"full"}
                                mt={6}
                              >
                                <Button
                                  onClick={() => {
                                    setCurrentTab(1);
                                  }}
                                  size={"sm"}
                                  leftIcon={<ArrowLeftIcon size={16} />}
                                >
                                  Prev
                                </Button>

                                <Button
                                  type="submit"
                                  rounded={"full"}
                                  colorScheme={"teal"}
                                  isDisabled={!isValid}
                                >
                                  Finish Setup
                                </Button>
                              </Flex>
                            </Stack>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                    <Box
                      flex={1}
                      ml={{ base: 0, md: 6 }}
                      mt={{ base: 6, md: 0 }}
                      overflowY="auto"
                    >
                      <AssistantPreview
                        name={assistantName}
                        suggestions={suggestions}
                        shape={shape}
                        image={iconImage}
                        welcomeMessage={welcomeMessage}
                      />
                    </Box>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Stack>
        ) : (
          <Stack mt={-56} alignItems={"center"}>
            <Box position="fixed">
              <Timer
                statusHandler={statusHandler}
                countingStatus={countingStatus}
                setCountingStatus={setCountingStatus}
              />
              <Text mt={10} fontSize={"lg"} textAlign="center">
                Please wait while we are setting up your assistant
              </Text>
            </Box>
          </Stack>
        )}
      </Center>
    </Flex>
  );
}