import React, { useRef, useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Textarea,
  Heading,
  useToast,
  Card,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { AssistantPreview } from "../../components/AssistantPreview";
import { BiMeteor } from "react-icons/bi";
import { GoHubot } from "react-icons/go";
import { BiSolidShoppingBags } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { createAssistantAsync, fetchAssistantsAsync } from "../../features/Assistant/AssistantAction";
import { useNavigate } from "react-router-dom";
import Lottie from 'react-lottie';
import uploadAnimation from '../../assets/animation/created.json';
import { getUserDetails } from "../../features/UserData/userActions";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: uploadAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const NewAssistants = () => {
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = localStorage.getItem("token");
  const userDetails = useSelector((state) => state.user.userDetails);
  const [suggestionInput, setSuggestionInput] = useState("");
  const [assistantName, setAssistantName] = useState("AskGPT");
  const [welcomeMessage, setWelcomeMessage] = useState(
    "Warm greetings to you, How can I assist you today?"
  );
  const [suggestions, setSuggestions] = useState(["What is this about?"]);
  const [shape, setShape] = useState("square");
  const [useCase, setUseCase] = useState("");
  const [webURL, setWebURL] = useState("");
  const [iconImage, setIconImage] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [changeTag, setChangeTag] = useState(null);
  const inputRef = useRef(null);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  const handleUseCaseChange = (value) => {
    setUseCase(value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIconImage(URL.createObjectURL(file));
      setIconFile(file);
    }
  };

  const uploadImageToApi = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
  
    try {
      const response = await fetch("https://cognitive.nextai.co.in/assistant/widget-image/", {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
        body: formData,
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Image upload failed");
      }
  
      return data.file_url;
    } catch (error) {
      throw new Error("Image upload failed");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !assistantName.trim() ||
      !welcomeMessage.trim() ||
      !useCase ||
      ((useCase === "searchgpt" || useCase === "productgpt") && !webURL.trim()) ||
      !suggestions.length
    ) {
      toast({
        title: "Error",
        description: "All fields are required.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    if (!iconFile) {
      toast({
        title: "Error",
        description: "Please add an image.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    if (userDetails) {
      const { plan_details, assistant_count } = userDetails;
      const maxAssistants = plan_details.features.assistantCount;

      if (assistant_count >= maxAssistants) {
        toast({
          title: "Limit Reached",
          description: `Your current plan allows up to ${maxAssistants} assistants. Please upgrade your plan to create more assistants.`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        return;
      }
    }

    try {
      let imageURL = iconImage;

      if (iconFile) {
        toast({
          title: "Uploading Image",
          description: "Please wait while the image is being uploaded.",
          status: "info",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });

        imageURL = await uploadImageToApi(iconFile);
      }

      const assistantData = {
        name: assistantName,
        welcome_message: welcomeMessage,
        suggestions,
        shape,
        use_case: useCase,
        project_url: webURL,
        widget_image: imageURL,
      };

      dispatch(createAssistantAsync({ assistantData, token })).then(() => {
        toast({
          title: "Assistant Created",
          description: "Your assistant has been created successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        dispatch(fetchAssistantsAsync(token));
        onOpen();
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.message || "Image upload failed.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
  };

  const removeSuggestion = (index) => {
    setSuggestions(suggestions.filter((_, i) => i !== index));
  };

  const changeSuggestion = (index, e) => {
    const newSuggestions = [...suggestions];
    newSuggestions[index] = e.target.value;
    setSuggestions(newSuggestions);
  };

  const closeSuggestion = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setChangeTag(null);
    }
  };

  const useCaseOptions = {
    productgpt: "ProductGPT: Product Discovery & Recommendations",
    askgpt: "AskGPT: Information / Data from Documents",
    agent: "Agents: Autonomous actions",
  };

  const cardBgColor = useColorModeValue("gray.100", "gray.700");
  const cardSelectedBgColor = useColorModeValue("teal.300", "teal.600");
  const dividerColor = useColorModeValue("gray.600", "gray.200");

  const handleIngestSource = () => {
    onClose();
    navigate("/resources");
  };

  return (
    <Box minH="100vh" p={8}>
      <Flex direction={{ base: "column", md: "row" }} gap={10}>
        <Box flex="1">
          <Card bg={cardBgColor} p={8} borderRadius="md" border="1px solid">
            <form onSubmit={handleSubmit}>
              <Heading as="h2" size="lg" mb={6}>
                Create New Assistant
              </Heading>
              <Stack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Assistant Name</FormLabel>
                  <Input
                    type="text"
                    value={assistantName}
                    onChange={(e) => setAssistantName(e.target.value)}
                    placeholder="Enter assistant name"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Welcome Message</FormLabel>
                  <Textarea
                    value={welcomeMessage}
                    onChange={(e) => setWelcomeMessage(e.target.value)}
                    placeholder="Enter a welcome message"
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Use Case</FormLabel>
                  <Flex wrap="nowrap" gap={2}>
                    {Object.entries(useCaseOptions).map(([value, label]) => (
                      <Card
                        key={value}
                        bg={
                          useCase === value ? cardSelectedBgColor : cardBgColor
                        }
                        p={4}
                        borderRadius="md"
                        boxShadow="sm"
                        cursor="pointer"
                        onClick={() => handleUseCaseChange(value)}
                        width="32%"
                        textAlign="center"
                        borderWidth="1px"
                        borderColor={
                          useCase === value ? "teal.400" : "gray.500"
                        }
                      >
                        <HStack justifyContent="center">
                          {value === "productgpt" && <BiSolidShoppingBags />}
                          {value === "askgpt" && <BiMeteor />}
                          {value === "agent" && <GoHubot />}
                        </HStack>
                        <Text mt={2}>{label}</Text>
                      </Card>
                    ))}
                  </Flex>
                </FormControl>
                {(useCase === "searchgpt" || useCase === "productgpt") && (
                  <FormControl isRequired>
                    <FormLabel>Web URL</FormLabel>
                    <Input
                      type="url"
                      value={webURL}
                      onChange={(e) => setWebURL(e.target.value)}
                      placeholder="Enter web URL"
                    />
                  </FormControl>
                )}
                <FormControl>
                  <FormLabel>Add Suggestions</FormLabel>
                  <Stack spacing={2}>
                    {suggestions.map((suggestion, index) =>
                      changeTag !== index ? (
                        <Tag
                          key={index}
                          size="lg"
                          variant="solid"
                          colorScheme="teal"
                        >
                          <TagLabel onClick={() => setChangeTag(index)}>
                            {suggestion}
                          </TagLabel>
                          <TagCloseButton
                            onClick={() => removeSuggestion(index)}
                          />
                        </Tag>
                      ) : (
                        <Input
                          size="sm"
                          ref={inputRef}
                          value={suggestions[index]}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              closeSuggestion(e);
                            }
                          }}
                          onChange={(e) => changeSuggestion(index, e)}
                        />
                      )
                    )}
                    <Input
                      value={suggestionInput}
                      onChange={(e) => setSuggestionInput(e.target.value)}
                      placeholder="Type a suggestion and press Enter"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && suggestionInput.trim()) {
                          e.preventDefault();
                          setSuggestions([...suggestions, suggestionInput]);
                          setSuggestionInput("");
                        }
                      }}
                    />
                  </Stack>
                </FormControl>
                <FormControl>
                  <FormLabel>Widget Image</FormLabel>
                  <HStack>
                    {iconImage && <Avatar src={iconImage} />}
                    <Button
                      as="label"
                      htmlFor="image-upload"
                      colorScheme="teal"
                    >
                      Upload Image
                    </Button>
                    <Input
                      type="file"
                      id="image-upload"
                      accept="image/*"
                      display="none"
                      onChange={handleImageChange}
                    />
                  </HStack>
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Widget Icon Shape</FormLabel>
                  <HStack>
                    <Button
                      size="sm"
                      onClick={() => setShape("square")}
                      colorScheme={shape === "square" ? "teal" : "gray"}
                    >
                      Square
                    </Button>
                    <Button
                      size="sm"
                      onClick={() => setShape("circular")}
                      colorScheme={shape === "circular" ? "teal" : "gray"}
                    >
                      Circular
                    </Button>
                  </HStack>
                </FormControl>
                <Button
                  type="submit"
                  colorScheme="teal"
                  size="lg"
                >
                  Create Assistant
                </Button>
              </Stack>
            </form>
          </Card>
        </Box>
        <Divider orientation="vertical" borderColor={dividerColor} />
        <AssistantPreview
          name={assistantName}
          suggestions={suggestions}
          shape={shape}
          image={iconImage}
          welcomeMessage={welcomeMessage}
        />
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assistant Created</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb={4}>
              <Lottie options={defaultOptions} height={150} width={150} />
            </Box>
            <p>Your assistant has been created successfully!</p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleIngestSource}>
              Ingest Source
            </Button>
            <Button variant="ghost" onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default NewAssistants;