import { createSlice } from '@reduxjs/toolkit';
import {
  ingestWeb,
  ingestDirectory,
  ingestGithub,
  ingestPostgres,
  ingestConfluence,
  ingestNotion,
  ingestShopify,
} from './IngestionAction';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const ingestionSlice = createSlice({
  name: 'ingestion',
  initialState,
  reducers: {
    resetIngestionState: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Handling web ingestion
    builder.addCase(ingestWeb.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ingestWeb.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(ingestWeb.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || action.error.message;
    });
    // Handling directory ingestion
    builder.addCase(ingestDirectory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ingestDirectory.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(ingestDirectory.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Handling GitHub ingestion
    builder.addCase(ingestGithub.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ingestGithub.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(ingestGithub.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Handling Postgres ingestion
    builder.addCase(ingestPostgres.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ingestPostgres.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(ingestPostgres.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Handling Confluence ingestion
    builder.addCase(ingestConfluence.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ingestConfluence.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(ingestConfluence.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Handling Notion ingestion
    builder.addCase(ingestNotion.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ingestNotion.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
    });
    builder.addCase(ingestNotion.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Handling Shopify ingestion
    builder.addCase(ingestShopify.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(ingestShopify.fulfilled, (state, action) => {
      state.loading = false;
      state.data.push(action.payload);
      state.success = true;
    });
    builder.addCase(ingestShopify.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    });
  },
});

export const { resetIngestionState } = ingestionSlice.actions;
export default ingestionSlice.reducer;
