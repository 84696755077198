import { Box, Circle, CircularProgress, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import CompletionAnimation from "../../../assets/animation/Agent.json";

const Timer = ({ timerData, statusHandler, countingStatus, setCountingStatus }) => {
  const pomodoroDuration = 0.08333; 
  const shortBreakDuration = 5;
  const longBreakDuration = 15;

  const [countDownTime, setCountDownTime] = useState(pomodoroDuration * 60 * 1000);
  const [intervalId, setIntervalId] = useState(0);
  const [tempPomodoro, setTempPomodoro] = useState(pomodoroDuration);
  const [tempShort, setTempShort] = useState(shortBreakDuration);
  const [tempLong, setTempLong] = useState(longBreakDuration);
  const [timerLabel, setTimerLabel] = useState(5);
  const [timerStep, setTimerStep] = useState(0);

  const startCountDown = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setCountingStatus("pause");
      setIntervalId(0);
      return;
    }

    if (countingStatus === "end") {
      setCountDownTime(tempPomodoro * 60 * 1000);
      setCountingStatus("pause");
      return;
    }

    if (timerData === 'shortBreak' && timerStep % 2 === 0) {
      setTimerStep(timerStep + 1);
    } else if (timerData === 'longBreak' && timerStep !== 7) {
      setTimerStep(7);
    }
    startNewTimer();
  };

  const startNewTimer = () => {
    const newIntervalId = setInterval(() => {
      setCountDownTime(prevTime => prevTime - 1000);
    }, 1000);
    setIntervalId(newIntervalId);
    setCountingStatus("counting");
  };

  useEffect(() => {
    if (countDownTime <= 0) {
      clearInterval(intervalId);
      setIntervalId(0);
      setCountingStatus("completed");
    }
  }, [countDownTime, intervalId]);

  useEffect(() => {
    if (countingStatus !== "end") {
      if (timerData === 'pomodoro') {
        setTimerLabel(tempPomodoro);
        setCountDownTime(tempPomodoro * 60 * 1000);
      } else if (timerData === 'shortBreak') {
        setTimerLabel(tempShort);
        setCountDownTime(tempShort * 60 * 1000);
      } else if (timerData === 'longBreak') {
        setTimerLabel(tempLong);
        setCountDownTime(tempLong * 60 * 1000);
      }
    }
  }, [timerData, tempPomodoro, tempShort, tempLong, countingStatus]);

  useEffect(() => {
    if (timerLabel !== tempPomodoro && countingStatus !== "counting") {
      if (timerData === 'pomodoro') {
        setTimerLabel(tempPomodoro);
        setCountDownTime(tempPomodoro * 60 * 1000);
      } else if (timerData === 'shortBreak') {
        setTimerLabel(tempShort);
        setCountDownTime(tempShort * 60 * 1000);
      } else if (timerData === 'longBreak') {
        setTimerLabel(tempLong);
        setCountDownTime(tempLong * 60 * 1000);
      }
    }
  }, [timerLabel, tempPomodoro, tempShort, tempLong, countingStatus, timerData]);

  useEffect(() => {
    startCountDown();
  }, []);

  return (
    <Box>
      {countingStatus === "completed" ? (
        <Lottie
          style={{ height: "200px" }}
          animationData={CompletionAnimation}
          loop={false}
          onComplete={() => window.location.href = "/resources"}
        />
      ) : (
        <Circle
          mx="auto"
          w={["150px", "150px"]}
          h={["150px", "150px"]}
          mt={["48px", "109px", "45px"]}
          bgGradient="linear-gradient(315deg, #2E325A 0%, #0E112A 100%)"
        >
          <Circle
            w={["267.805px", "366px"]}
            h={["267.805px", "366px"]}
            bg="transparent"
          >
            <CircularProgress
              size={["123.475px", "223px"]}
              value={(countDownTime / 60 / 1000 / timerLabel) * 100}
              color="teal"
              thickness={3.5}
              capIsRound
            />
            <Box pos="absolute">
              <Text
                textStyle="h1"
                color="white"
                fontWeight={"bold"}
                fontSize={"4xl"}
                position="relative"
                textAlign="Center"
              >
                {Math.floor(countDownTime / 1000 / 60 / 10).toString() +
                  Math.floor((countDownTime / 1000 / 60) % 10).toString() +
                  ":" +
                  Math.floor(((countDownTime / 1000) % 60) / 10).toString() +
                  Math.floor(((countDownTime / 1000) % 60) % 10).toString()}
              </Text>
            </Box>
          </Circle>
        </Circle>
      )}
    </Box>
  );
};

export default Timer;
