import React from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  useColorModeValue,
  Switch,
  useToast,
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ingestConfluence } from '../../../features/Ingestion/IngestionAction';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object({
  sourceName: Yup.string().required('Source Name is required'),
  workspaceUrl: Yup.string().url('Invalid URL').required('URL of workspace is required'),
  confluenceUserName: Yup.string().required('Confluence User Name is required'),
  apiKey: Yup.string().required('API Key is required'),
  spaceKey: Yup.string().required('Space Key is required'),
  pageId: Yup.string().required('Page ID is required'),
  includeAttachments: Yup.boolean().required('Include Attachments is required'),
});

const ConfluenceForm = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const toast = useToast();
  const projectId = useSelector((state) => state.assistants.details?.project_id || "Bot_ID");
  const authToken = localStorage.getItem('token');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Box
      maxW={'full'}
      mt={4}
      p={6}
      bg={bgColor}
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      boxShadow="sm"
    >
      <Heading size="lg" mb={4}>
        Confluence Source
      </Heading>
      <Box mb={0} fontSize={'xs'} color={textColor}>
        Fill the below fields to ingest Confluence data.
      </Box>
      <Divider borderColor={borderColor} mb={6} />
      <Formik
        initialValues={{
          sourceName: '',
          workspaceUrl: '',
          confluenceUserName: '',
          apiKey: '',
          spaceKey: '',
          pageId: '',
          includeAttachments: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const formData = {
            project_id: projectId,
            confluence_url: values.workspaceUrl,
            confluence_username: values.confluenceUserName,
            confluence_api_key: values.apiKey,
            source_name: values.sourceName,
            confluence_space_key: values.spaceKey,
            confluence_page_ids: [values.pageId],
            include_attachments: values.includeAttachments,
          };

          fetch('https://cognitive.nextai.co.in/ingestion/ingest/confluence', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify(formData),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response.json();
            })
            .then((data) => {
              setSubmitting(false);
              resetForm();
              toast({
                title: 'Ingestion Successful',
                description: 'Your Confluence data has been successfully ingested.',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              });
              navigate('/resources');
            })
            .catch((error) => {
              setSubmitting(false);
              toast({
                title: 'Ingestion Failed',
                description: `Error: ${error.message}`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              });
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Field name="sourceName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.sourceName && form.touched.sourceName} mt={4}>
                  <FormLabel htmlFor="sourceName">Source Name</FormLabel>
                  <Input {...field} id="sourceName" placeholder="Source Name" />
                  <FormErrorMessage>{form.errors.sourceName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="workspaceUrl">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.workspaceUrl && form.touched.workspaceUrl} mt={4}>
                  <FormLabel htmlFor="workspaceUrl">URL of workspace</FormLabel>
                  <Input {...field} id="workspaceUrl" placeholder="URL of workspace" />
                  <FormErrorMessage>{form.errors.workspaceUrl}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="confluenceUserName">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.confluenceUserName && form.touched.confluenceUserName} mt={4}>
                  <FormLabel htmlFor="confluenceUserName">Confluence User Name</FormLabel>
                  <Input {...field} id="confluenceUserName" placeholder="Confluence User Name" />
                  <FormErrorMessage>{form.errors.confluenceUserName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="apiKey">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.apiKey && form.touched.apiKey} mt={4}>
                  <FormLabel htmlFor="apiKey">API Key</FormLabel>
                  <Input {...field} id="apiKey" placeholder="API Key" />
                  <FormErrorMessage>{form.errors.apiKey}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="spaceKey">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.spaceKey && form.touched.spaceKey} mt={4}>
                  <FormLabel htmlFor="spaceKey">Space Key</FormLabel>
                  <Input {...field} id="spaceKey" placeholder="Space Key" />
                  <FormErrorMessage>{form.errors.spaceKey}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="pageId">
              {({ field, form }) => (
                <FormControl isInvalid={form.errors.pageId && form.touched.pageId} mt={4}>
                  <FormLabel htmlFor="pageId">Page ID</FormLabel>
                  <Input {...field} id="pageId" placeholder="Page ID" />
                  <FormErrorMessage>{form.errors.pageId}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="includeAttachments">
              {({ field, form }) => (
                <FormControl
                  display="flex"
                  alignItems="center"
                  isInvalid={form.errors.includeAttachments && form.touched.includeAttachments}
                  mt={4}
                >
                  <FormLabel htmlFor="includeAttachments" mb="0">
                    Include Attachments
                  </FormLabel>
                  <Switch {...field} id="includeAttachments" isChecked={field.value} />
                  <FormErrorMessage>{form.errors.includeAttachments}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex mt={6} justifyContent="flex-end">
              <Button variant="outline" mr={4} onClick={() => console.log('Cancel')}>
                Cancel
              </Button>
              <Button type="submit" colorScheme="teal" isLoading={isSubmitting}>
                Submit
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ConfluenceForm;