import { configureStore } from "@reduxjs/toolkit";
import metricsReducer from "./features/metrics/metricsSlice";
import historyReducer from "./features/history/historySlice";
import AssistantSlice from "./features/Assistant/AssistantSlice";
import IngestionSlice from "./features/Ingestion/IngestionSlice";
import DataIngestionSlice from "./features/DataIngestion/DataIngestionSlice";
import EvalutionSlice from "./features/Evalution/EvalutionSlice";
import AnswersSlice from "./features/Answers/AnswersSlice";
import apiKeySlice from "./features/Api/apiKeySlice";
import AgentsSlice from "./features/Agents/AgentsSlice";
import userSlice from "./features/UserData/userSlice";

const store = configureStore({
  reducer: {
    metrics: metricsReducer,
    history: historyReducer,
    assistants: AssistantSlice,
    ingestion: IngestionSlice,
    ingestsource: DataIngestionSlice,
    evaluation: EvalutionSlice,
    answer: AnswersSlice,
    apiKey: apiKeySlice,
    agent: AgentsSlice,
    user: userSlice,

  },
});

export default store;