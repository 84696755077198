import axios from "axios";
const url = process.env.REACT_APP_API_URL;

export const fetchIngestionHistoryApi = async (projectId) => {
  try {
    const authToken = localStorage.getItem('token');
    const response = await axios.get(`${url}/ingestion/ingestion-history/${projectId}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("API Error:", error.response); 
    throw error; 
  }
};

export const fetchIngestionSourcesApi = async (projectId) => {
  try {
    const authToken = localStorage.getItem('token');
    const response = await axios.get(`${url}/ingestion/sources/${projectId}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("API Error:", error.response); 
    throw error; 
  }
};

export const updateSourceApi = async (sourceId, payload) => {
  try {
    const authToken = localStorage.getItem('token');
    const response = await axios.put(`${url}/ingestion/update-source/${sourceId}`, { payload }, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
    });
    return response.data;
  } catch (error) {
    console.error("API Error:", error.response); 
    throw error; 
  }
};

export const syncSourceApi = async (collectionName) => {
  try {
    const authToken = localStorage.getItem('token');
    const response = await axios.post(`${url}/ingestion/retrigger-ingestion/${collectionName}`, {}, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
    });
    return response.data;
  } catch (error) {
    console.error("API Error:", error.response); 
    throw error; 
  }
};

export const deleteSourceApi = async (collectionName) => {
  try {
    const authToken = localStorage.getItem('token');
    const response = await axios.delete(`${url}/ingestion/delete/${collectionName}`, {
      headers: {
        'Authorization': `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
    });
    return response.data;
  } catch (error) {
    console.error("API Error:", error.response); 
    throw error; 
  }
};