import { createAsyncThunk } from "@reduxjs/toolkit";
import { generateNewApiKeyApi , deleteApiKeyApi } from "../../apis/ApiKeyApi";

export const generateNewApiKeyAsync = createAsyncThunk(
  "apiKey/generateNewApiKey",
  async (data, thunkAPI) => {
    const response = await generateNewApiKeyApi(data, thunkAPI);
    return response;
  }
);

export const deleteApiKeyAsync = createAsyncThunk(
    "apiKey/deleteApiKey",
    async (api_key, thunkAPI) => {
      const response = await deleteApiKeyApi(api_key, thunkAPI);
      return response;
    }
  );