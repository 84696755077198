import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Switch,
  Textarea,
  useToast,
  Collapse,
  Box,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateProjectDetailsAsync,
  fetchAssistantDetailsAsync,
  fetchProjectDetailsAsync,
} from "../../features/Assistant/AssistantAction";

export const UpdateProjectDetails = ({ projectId, onClose }) => {
  const dispatch = useDispatch();
  const toast = useToast();
  const token = localStorage.getItem("token");
  const { details, project, loading, error } = useSelector(
    (state) => state.assistants
  );

  const [temperature, setTemperature] = useState(project.temperature);
  const [systemTemplate, setSystemTemplate] = useState(project.system_template);
  const [followupTemplate, setFollowUpTemplate] = useState(project.followup_template);
  const [historyEnabled, setHistoryEnabled] = useState(project.history);
  const [similarity_top_k, setTopK] = useState(project.similarity_top_k);
  const [defaultString, setDefaultString] = useState(project.default_string);
  const [suggestionFlag, setSuggestionFlag] = useState(project.suggestion_flag);

  const [productLimit, setProductLimit] = useState(project.product_limit || 9);
  const [agentPrompt, setAgentPrompt] = useState(project.agent_prompt || "");
  const [clarifyOptions, setClarifyOptions] = useState(project.clarify_options || []);
  const [systemPrompt, setSystemPrompt] = useState(project.system_prompt || "");
  const [cutoffScore, setCutoffScore] = useState(project.cutoff_score || 0.55);
  const [clarifyPrompt, setClarifyPrompt] = useState(project.clarify_prompt || "");
  const [clarifyQuestion, setClarifyQuestion] = useState(project.clarify_question || "");
  const [projectURL, setURL] = useState(project.project_url || "");

  const [entityDescription, setEntityDescription] = useState(project.entity_description || "");
  const [supportDescription, setSupportDescription] = useState(project.support_description || "");
  const [supportSystemTemplate, setSupportSystemTemplate] = useState(project.clarify_description || "");

  const [summarizationEnabled, setSummarizationEnabled] = useState(project.summarization_flag);

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const generateSystemTemplate = async () => {
    setIsLoading(true);
    const response = await fetch('https://cognitive.nextai.co.in/embed/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        user_message: userMessage
      })
    });

    if (response.ok) {
      const data = await response.json();
      const generatedText = data.generated_message[0].text;
      setSystemTemplate(generatedText);
      toast({
        title: "Template Generated",
        description: "New System template has been successfully generated.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "bottom",
      });
    } else {
      toast({
        title: "Error Generating Template",
        description: "Failed to generate system template.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
    }
    setIsLoading(false);
  };

  const handleUpdate = () => {
    const projectDetails = {
      temperature,
      similarity_top_k,
      system_template: systemTemplate,
      followup_template: followupTemplate,
      history: historyEnabled,
      default_string: defaultString,
      suggestion_flag: suggestionFlag,
      summarization_flag: summarizationEnabled,
      ...((details?.type === "searchgpt" || details?.type === "productgpt") && {
        product_limit: productLimit,
        agent_prompt: agentPrompt,
        clarify_options: clarifyOptions,
        system_prompt: systemPrompt,
        cutoff_score: cutoffScore,
        clarify_prompt: clarifyPrompt,
        clarify_question: clarifyQuestion,
        entity_description: entityDescription,
        support_description: supportDescription,
        clarify_description: supportSystemTemplate,
        project_url: projectURL,
      }),
    };

    dispatch(
      updateProjectDetailsAsync({
        projectId,
        projectData: projectDetails,
        token,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(fetchAssistantDetailsAsync({ projectId, token }));
        dispatch(fetchProjectDetailsAsync(projectId));
        toast({
          title: "Project Updated",
          description: "Project details have been successfully updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
        onClose();
        window.location.reload();
      })
      .catch((error) => {
        toast({
          title: "Error Updating Project",
          description: error.message || "Failed to update project details.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
        });
      });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleUpdate();
      }}
    >
      <Flex justify="space-between" wrap="wrap">
        <FormControl w="100%" mt={0}>
          <FormLabel fontSize="sm" fontWeight="600">
            Temperature
          </FormLabel>
          <NumberInput
            size="sm"
            step={0.1}
            min={0}
            max={1}
            value={temperature}
            onChange={(value) => setTemperature(parseFloat(value))}
          >
            <NumberInputField borderRadius="5px" border="1px solid #dbdfea" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl w="100%" mt={3}>
          <FormLabel fontSize="sm" fontWeight="600">
            Similarity top K
          </FormLabel>
          <NumberInput
            size="sm"
            step={1}
            min={1}
            max={10}
            value={similarity_top_k}
            onChange={(value) => setTopK(parseFloat(value))}
          >
            <NumberInputField borderRadius="5px" border="1px solid #dbdfea" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <FormControl mt={3}>
          <FormLabel fontSize="sm" fontWeight="600">
            System Template
          </FormLabel>
          <Textarea
            value={systemTemplate}
            onChange={(e) => setSystemTemplate(e.target.value)}
          />
          <Input
            mt={2}
            size="sm"
            placeholder="Enter your query to generate system template"
            value={userMessage}
            onChange={(e) => setUserMessage(e.target.value)}
          />
          <Button
            mt={2}
            size="xs"
            colorScheme="teal"
            onClick={generateSystemTemplate}
            isLoading={isLoading}
          >
            Generate Template
          </Button>
        </FormControl>
        <FormControl mt={3}>
          <FormLabel fontSize="sm" fontWeight="600">
            Followup Template
          </FormLabel>
          <Textarea
            value={followupTemplate}
            onChange={(e) => setFollowUpTemplate(e.target.value)}
          />
        </FormControl>
        <FormControl mt={3}>
          <FormLabel fontSize="sm" fontWeight="600">
            Default String
          </FormLabel>
          <Textarea
            value={defaultString}
            onChange={(e) => setDefaultString(e.target.value)}
          />
        </FormControl>
        <FormControl mt={3} display="flex" alignItems="center">
          <Switch
            id="suggestion_flag"
            colorScheme="purple"
            mr={4}
            isChecked={suggestionFlag}
            onChange={(e) => setSuggestionFlag(e.target.checked)}
          />
          <FormLabel htmlFor="suggestion_flag" mb="0">
            Enable Suggestions
          </FormLabel>
        </FormControl>
        <FormControl mt={3} display="flex" alignItems="center">
          <Switch
            colorScheme="purple"
            id="history"
            mr={4}
            isChecked={historyEnabled}
            onChange={(e) => setHistoryEnabled(e.target.checked)}
          />
          <FormLabel htmlFor="history" mb="0">
            Enable History
          </FormLabel>
        </FormControl>
        {details?.type === "agent" && (
          <FormControl mt={3} display="flex" alignItems="center">
            <Switch
              colorScheme="purple"
              id="summarization"
              mr={4}
              isChecked={summarizationEnabled}
              onChange={(e) => setSummarizationEnabled(e.target.checked)}
            />
            <FormLabel htmlFor="summarization" mb="0">
              Enable Summarization
            </FormLabel>
          </FormControl>
        )}
        {(details?.type === "searchgpt" || details?.type === "productgpt") && (
          <>
            <FormControl mt={3}>
              <FormLabel fontSize="sm" fontWeight="600">
                Project URL
              </FormLabel>
              <Input
                value={projectURL}
                onChange={(e) => setURL(e.target.value)}
              />
            </FormControl>
            <FormControl w="100%" mt={3}>
              <FormLabel fontSize="sm" fontWeight="600">
                Product Limit
              </FormLabel>
              <NumberInput
                size="sm"
                step={1}
                min={1}
                value={productLimit}
                onChange={(value) => setProductLimit(parseInt(value))}
              >
                <NumberInputField
                  borderRadius="5px"
                  border="1px solid #dbdfea"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl mt={3}>
              <FormLabel fontSize="sm" fontWeight="600">
                Agent Prompt
              </FormLabel>
              <Textarea
                value={agentPrompt}
                onChange={(e) => setAgentPrompt(e.target.value)}
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel fontSize="sm" fontWeight="600">
                Clarify Options
              </FormLabel>
              <Textarea
                value={clarifyOptions}
                onChange={(e) => setClarifyOptions(e.target.value.split(","))}
                placeholder="Enter options separated by commas"
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel fontSize="sm" fontWeight="600">
                System Prompt
              </FormLabel>
              <Textarea
                value={systemPrompt}
                onChange={(e) => setSystemPrompt(e.target.value)}
              />
            </FormControl>
            <FormControl w="100%" mt={5}>
              <FormLabel fontSize="sm" fontWeight="600">
                Cutoff Score
              </FormLabel>
              <NumberInput
                size="sm"
                step={0.01}
                min={0}
                max={1}
                value={cutoffScore}
                onChange={(value) => setCutoffScore(parseFloat(value))}
              >
                <NumberInputField
                  borderRadius="5px"
                  border="1px solid #dbdfea"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </FormControl>
            <FormControl mt={3}>
              <FormLabel fontSize="sm" fontWeight="600">
                Clarifying template
              </FormLabel>
              <Textarea
                value={clarifyPrompt}
                onChange={(e) => setClarifyPrompt(e.target.value)}
              />
            </FormControl>
            <FormControl mt={3}>
              <FormLabel fontSize="sm" fontWeight="600">
                Clarify Question
              </FormLabel>
              <Textarea
                value={clarifyQuestion}
                onChange={(e) => setClarifyQuestion(e.target.value)}
              />
            </FormControl>
            <Button
              mt={7}
              onClick={() => setShowAdvanced(!showAdvanced)}
              colorScheme="teal"
              variant="outline"
              size={"sm"}
            >
              {showAdvanced ? "Hide Advanced Options" : "Show Advanced Options"}
            </Button>
            <Collapse in={showAdvanced} animateOpacity>
              <Box mt={5} p={5} border="1px solid #dbdfea" borderRadius="5px">
                <HStack spacing={4}>
                  <FormControl>
                    <FormLabel fontSize="sm" fontWeight="600">
                      Entity Description
                    </FormLabel>
                    <Textarea
                      value={entityDescription}
                      onChange={(e) => setEntityDescription(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize="sm" fontWeight="600">
                      Support Description
                    </FormLabel>
                    <Textarea
                      value={supportDescription}
                      onChange={(e) => setSupportDescription(e.target.value)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize="sm" fontWeight="600">
                      Support System Template
                    </FormLabel>
                    <Textarea
                      value={supportSystemTemplate}
                      onChange={(e) => setSupportSystemTemplate(e.target.value)}
                    />
                  </FormControl>
                </HStack>
              </Box>
            </Collapse>
          </>
        )}
        <HStack spacing={6} mt={7}>
          <Button size={"sm"} colorScheme="teal" type="submit">
            Set Project Details
          </Button>
          <Button size={"sm"} onClick={onClose}>
            Cancel
          </Button>
        </HStack>
      </Flex>
    </form>
  );
};
