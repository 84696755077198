import React, { useState } from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { FaChevronRight } from 'react-icons/fa';
import { CarouselProducts } from './CarouselProducts';
import { ProductListingModal } from './ProductListingModal';

export const Carousel = ({ products }) => {
    const [showModal, setShowModal] = useState(false);

    const initialProducts = products ?? [];

    return (
        <Box overflowX="scroll" sx={{ "::-webkit-scrollbar": { display: "none" } }}>
            <Flex py={5} justify="space-between">
                {initialProducts.slice(0, 5).map((product, index) => (
                    <CarouselProducts key={index} image={product.src} name={product.title} price={product.price} link={product.product_url} />
                ))}
                {initialProducts.length > 7 && ( 
                    <Flex direction="column" align="center" alignSelf="center">
                        <Button p={0} variant="outline" borderRadius="50%" colorScheme="red" onClick={() => setShowModal(true)}>
                            <FaChevronRight />
                        </Button>
                        <Text fontSize="sm">View More</Text>
                    </Flex>
                )}
            </Flex>
            {showModal && <ProductListingModal isOpen={showModal} onClose={() => setShowModal(false)} products={products} />}
        </Box>
    );
};
