import React, { useRef, useEffect, useState } from 'react';
import { Avatar, Box, Flex, Text, HStack, Spinner, useColorMode } from '@chakra-ui/react';
import { AnswerButtons } from './AnswerButtons';
import { Carousel } from './Carousel';
import { Sources } from './Source';
import { useSelector } from 'react-redux';
import CodeBlock from './CodeBlock';

export const Messages = ({ messages, setShowPanel, onSourceSelected, sessionId, isLoading }) => {
    const endOfMessagesRef = useRef();
    const [loadingText, setLoadingText] = useState("...");
    const assistantName = useSelector(state => state.assistants.details?.name || "GPT");
    const assistantImage = useSelector(state => state.assistants.details?.widget_image || "Bot_Avatar");
    const { colorMode } = useColorMode();

    useEffect(() => {
        const loadingTexts = [
            "Analyzing context...",
            "Generating response...",
        ];
        let index = 0;
        const interval = setInterval(() => {
            setLoadingText(loadingTexts[index]);
            index = (index + 1) % loadingTexts.length;
        }, 2000);

        return () => clearInterval(interval);
    }, [messages]);

    const handleSourceSelection = (selectedSource) => {
        onSourceSelected(selectedSource);
    };

    const formatMessage = (text, colorMode) => {
        const bgColor = { light: "gray.100", dark: "gray.700" };
        const regex = /(```[\s\S]*?```)|(`.*?`)|(\*\*.*?\*\*)|(\*.*?\*)|(__.*?__)|(\~\~.*?\~\~)|(\>.*?\n)|(\#.*?\n)|(- .*?\n)|(\* .*?\n)|(\d+\.\s.*?\n)|(\n)/g;
        const parts = text.split(regex).filter(Boolean);

        return parts.map((part, index) => {
            if (part.startsWith("```") && part.endsWith("```")) {
                return <CodeBlock code={part.slice(3, -3).trim()} key={index} />;
            } else if (part.startsWith("`") && part.endsWith("`")) {
                return (
                    <Text as="span" key={index} fontFamily="monospace" bg={bgColor[colorMode]} p={1} display="inline">
                        {part.slice(1, -1)}
                    </Text>
                );
            } else if (part.startsWith("**") && part.endsWith("**")) {
                return (
                    <Text as="b" key={index} fontWeight="bold" display="inline">
                        {part.slice(2, -2)}
                    </Text>
                );
            } else if (part.startsWith("*") && part.endsWith("*")) {
                return (
                    <Text as="i" key={index} fontStyle="italic" display="inline">
                        {part.slice(1, -1)}
                    </Text>
                );
            } else if (part.startsWith("__") && part.endsWith("__")) {
                return (
                    <Text as="u" key={index} textDecoration="underline" display="inline">
                        {part.slice(2, -2)}
                    </Text>
                );
            } else if (part.startsWith("~~") && part.endsWith("~~")) {
                return (
                    <Text as="s" key={index} textDecoration="line-through" display="inline">
                        {part.slice(2, -2)}
                    </Text>
                );
            } else if (part.startsWith("> ")) {
                return (
                    <Text as="blockquote" key={index} pl={4} borderLeft="1px solid gray">
                        {part.slice(2).trim()}
                    </Text>
                );
            } else if (part.startsWith("# ")) {
                return (
                    <Text as="h1" key={index} fontSize="1.25rem" fontWeight="bold" mt={2}>
                        {part.slice(2).trim()}
                    </Text>
                );
            } else if (part.startsWith("- ") || part.startsWith("* ")) {
                return (
                    <Text as="li" key={index} ml={4} listStyleType="disc">
                        {part.slice(2).trim()}
                    </Text>
                );
            } else if (/\d+\.\s/.test(part)) {
                return (
                    <Text as="li" key={index} ml={0} mb={-1} mt={1} fontSize={'0.92rem'} listStyleType="decimal">
                        {part.slice(part.indexOf(' ') + 1).trim()}
                    </Text>
                );
            } else {
                return (
                    <Text textStyle='hostStyleOverride' fontWeight="500" fontSize="0.9rem" mt={2} key={index} display="inline">
                        {part}
                    </Text>
                );
            }
        });
    };

    return (
        <Box flex="1" overflowY="auto" maxHeight="100vh">
            {messages.map((message, index) => (
                <Box key={index} mb={4}>
                    <Flex align="start">
                        <Avatar
                            size="xs"
                            mr={2}
                            src={message.from !== "user" ? assistantImage : undefined}
                        />
                        <Box minWidth={0} flexGrow={1}>
                            <Text fontWeight="bold" fontSize="0.9rem">
                                {message.from === "user" ? "You" : assistantName}
                            </Text>
                            <Box>{formatMessage(message.text, colorMode)}</Box>
                            {message.clarify && (
                                <Sources
                                    sources={message.clarify}
                                    onSourceSelected={handleSourceSelection}
                                />
                            )}
                            {message.products && (
                                <Carousel
                                    products={message.products}
                                    setShowPanel={setShowPanel}
                                />
                            )}
                            {message.from === "bot" && (
                                <AnswerButtons
                                    inputMessage={message.user_message}
                                    message={message.text}
                                    chatId={message.chat_id}
                                    sessionId={sessionId}
                                />
                            )}
                        </Box>
                    </Flex>
                    {message.from === "bot" && <hr style={{ marginBottom: "30px" }} />}
                </Box>
            ))}
            {isLoading && (
                <Flex mb={3} justifyContent="left" alignItems="center">
                    <Avatar size="xs" mr={2} src={assistantImage} />
                    <Box minWidth={0} flexGrow={1}>
                        <Text fontWeight="bold" fontSize="0.9rem">
                            {assistantName}
                        </Text>
                        <HStack spacing={2}>
                            <Spinner
                                size="sm"
                                color="purple.500"
                                thickness="3px"
                                emptyColor="gray.200"
                            />
                            <Text>{loadingText}</Text>
                        </HStack>
                    </Box>
                </Flex>
            )}
            <div ref={endOfMessagesRef}></div>
        </Box>
    );
};