import React from 'react';
import {
  Box,
  Flex,
  Heading,
  HStack,
  Text,
  IconButton,
  useColorModeValue,
  SimpleGrid,
  Divider,
  Image,
  useColorMode
} from '@chakra-ui/react';
import { ArrowUpRight } from 'lucide-react';
import Github from "../../assets/img/github.png";
import Notion from "../../assets/img/notion.png";
import Postgress from "../../assets/img/postgres.svg";
import Confluence from "../../assets/img/confluence.png";
import Documents from "../../assets/img/file_icon.svg";
import Website from "../../assets/img/internet-icon.png";
import Shopify from "../../assets/img/shopify_icon.svg";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const data = [
  {
    name: 'GitHub',
    imageUrl: Github,
    email: 'Ask about your code & upgrades',
    textColor: 'text-fuchsia-800 dark:text-fuchsia-500',
    bgColor: 'bg-fuchsia-100 dark:bg-fuchsia-500/20',
    href: '/sources/github',
    type: ['askgpt'],
  },
  {
    name: 'Notion',
    imageUrl: Notion,
    email: 'Knowledge, answers, ideas. One click away.',
    textColor: 'text-blue-800 dark:text-blue-500',
    bgColor: 'bg-blue-100 dark:bg-blue-500/20',
    href: '/sources/notion',
    type: ['askgpt'],
  },
  {
    name: 'Postgres',
    imageUrl: Postgress,
    email: 'Q/A with your database!',
    textColor: 'text-pink-800 dark:text-pink-500',
    bgColor: 'bg-pink-100 dark:bg-pink-500/20',
    href: '/sources/postgres',
    type: ['developer'],
  },
  {
    name: 'Confluence',
    imageUrl: Confluence,
    email: 'Use AI To Drive Action and Teamwork',
    textColor: 'text-emerald-800 dark:text-emerald-500',
    bgColor: 'bg-emerald-100 dark:bg-emerald-500/20',
    href: '/sources/confluence',
    type: ['askgpt'],
  },
  {
    name: 'Website',
    imageUrl: Website,
    email: 'Scrape website content via url, do NOT make up urls',
    textColor: 'text-orange-800 dark:text-orange-500',
    bgColor: 'bg-orange-100 dark:bg-orange-500/20',
    href: '/sources/website',
    type: ['askgpt'],
  },
  {
    name: 'Documents',
    imageUrl: Documents,
    email: 'Data extraction from multiple types of files.',
    textColor: 'text-indigo-800 dark:text-indigo-500',
    bgColor: 'bg-indigo-100 dark:bg-indigo-500/20',
    href: '/sources/documents',
    type: ['askgpt', 'agent', 'productgpt', 'searchgpt'],
  },
  {
    name: 'Shopify',
    imageUrl: Shopify,
    email: 'Make your store products GenAI Recommendable',
    textColor: 'text-yellow-800 dark:text-yellow-500',
    bgColor: 'bg-yellow-100 dark:bg-yellow-500/20',
    href: '/sources/shopify',
    type: ['searchgpt', 'productgpt'],
  },
];

export default function SourcesCard() {
  const { selectedAssistant } = useSelector((state) => state.assistants);
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const iconColor = useColorModeValue('gray.600', 'gray.400');
  const iconHoverColor = useColorModeValue('gray.800', 'white');
  const countBgColor = useColorModeValue('gray.100', 'gray.700');
  const { colorMode } = useColorMode();

  // Ensure `type` is always treated as an array
  const normalizedData = data.map((member) => ({
    ...member,
    types: Array.isArray(member.type) ? member.type : [member.type],
  }));

  // Filter data based on selectedAssistant.type
  const filteredData = normalizedData.filter((member) => member.types.includes(selectedAssistant.type));

  return (
    <>
      <HStack spacing={2} mt={4} alignItems="center">
        <Heading size="md">Sources</Heading>
        <Box
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
          h={6}
          w={6}
          borderRadius="full"
          bg={countBgColor}
          color={textColor}
          fontWeight="medium"
        >
        </Box>
      </HStack>
      <Box mt={4} mb={4}>
        <Divider borderColor={borderColor} />
      </Box>
      <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={6}>
        {filteredData.map((member, index) => {
          return (
            <Box
              key={member.name}
              bg={colorMode === 'light' ? 'gray.100' : 'gray.800'}
              border="1px solid"
              borderColor={borderColor}
              borderRadius="md"
              p={4}
              position="relative"
              _hover={{ boxShadow: 'md' }}
              as={Link}
              to={member.href}
            >
              <Flex alignItems="center" spaceX={4}>
                <Image
                  src={member.imageUrl}
                  alt={member.name}
                  h={12}
                  w={12}
                  borderRadius="full"
                  objectFit="cover"
                />
                <Box flex="1" ml={4} isTruncated>
                  <Text fontWeight="bold" isTruncated>
                    <a href={member.href}>{member.name}</a>
                  </Text>
                  <Text fontSize="sm" color={textColor} isTruncated>
                    {member.email}
                  </Text>
                </Box>
                <IconButton
                  icon={<ArrowUpRight />}
                  as={Link}
                  to={member.href}
                  aria-label="More"
                  variant="ghost"
                  position="absolute"
                  top={4}
                  right={4}
                  color={iconColor}
                  _hover={{ color: iconHoverColor }}
                />
              </Flex>
            </Box>
          );
        })}
      </SimpleGrid>
    </>
  );
}