import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Spinner,
  Alert,
  AlertIcon,
  Tooltip,
  Button,
  Text,
  Box,
  Flex,
  IconButton,
  Select,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  Heading,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { FiSearch, FiTrash2, FiEdit3, FiCalendar } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <Button onClick={onClick} ref={ref} leftIcon={<FiCalendar />} colorScheme="gray" variant="outline">
    {value}
  </Button>
));

const CorrectionsPage = () => {
  const [corrections, setCorrections] = useState([]);
  const [status, setStatus] = useState('idle'); 
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const projectId = useSelector(state => state.assistants.details?.project_id || "Bot_ID");

  useEffect(() => {
    const fetchCorrections = async () => {
      setStatus('loading');
      try {
        const response = await axios.get(`https://cognitive.nextai.co.in/correction/corrections/${projectId}`);
        setCorrections(response.data.corrections);
        setStatus('succeeded');
      } catch (err) {
        setError(err.message);
        setStatus('failed');
      }
    };

    fetchCorrections();
  }, [projectId]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = corrections.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(corrections.length / itemsPerPage);

  const handlePreviousPage = () => {
    setCurrentPage(prevPage => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));
  };

  if (status === 'loading') {
    return (
      <Flex justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  if (status === 'failed') {
    return (
      <Alert status="error">
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Box mt={4} p={5} borderWidth="1px" borderRadius="md" borderColor="gray.300">
      <Box mb={4}>
        <HStack justifyContent="space-between">
          <Heading size="lg">Corrections History</Heading>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray.300" />
            </InputLeftElement>
            <Input type="text" placeholder="Search" />
          </InputGroup>
        </HStack>
        <HStack mt={4} spacing={4}>
          <Box>
            <Text mb={2}>Start Date</Text>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<CustomInput />}
            />
          </Box>
          <Box>
            <Text mb={2}>End Date</Text>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<CustomInput />}
            />
          </Box>
          <Button size={'sm'} bg={'cyan.900'} _hover={{ bg: "cyan.700" }} color={'white'} ml={4}>Filter</Button>
        </HStack>
      </Box>
      {corrections.length === 0 ? (
        <Box textAlign="center" mt={10}>
          <Text>No Corrections done yet</Text>
        </Box>
      ) : (
        <>
          <Table variant="simple" size="md">
            <Thead>
              <Tr>
                <Th>Question</Th>
                <Th>Answer</Th>
                <Th>Feedback</Th>
                <Th>Timestamp</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((correction) => (
                <Tr key={correction._id}>
                  <Tooltip bg='gray.300' color='black' fontSize={'11'} label={correction.question}>
                    <Td fontSize={12} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="200px">{correction.question}</Td>
                  </Tooltip>
                  <Tooltip bg='gray.300' color='black' fontSize={'11'} label={correction.answer}>
                    <Td fontSize={12} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="200px">{correction.answer}</Td>
                  </Tooltip>
                  <Tooltip label={correction.feedback}>
                    <Td fontSize={12} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="200px">{correction.feedback}</Td>
                  </Tooltip>
                  <Tooltip label={new Date(correction.timestamp).toLocaleString()}>
                    <Td fontSize={12} whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="200px">{new Date(correction.timestamp).toLocaleString()}</Td>
                  </Tooltip>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Flex justify="space-between" align="center" mt={4}>
            <Text>Showing {indexOfFirstItem + 1} to {Math.min(indexOfLastItem, corrections.length)} of {corrections.length} results</Text>
            <HStack>
              <Button onClick={handlePreviousPage} isDisabled={currentPage === 1}>Previous</Button>
              <Select value={currentPage} onChange={(e) => setCurrentPage(Number(e.target.value))}>
                {Array(totalPages).fill('').map((_, i) => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </Select>
              <Button onClick={handleNextPage} isDisabled={currentPage === totalPages}>Next</Button>
            </HStack>
          </Flex>
        </>
      )}
    </Box>
  );
};

export default CorrectionsPage;
