import React, { useEffect } from 'react';
import {
  Box,
  Heading,
  Button,
  Flex,
  Text,
  SimpleGrid,
  VStack,
  HStack,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Center,
  Progress,
  useColorModeValue
} from '@chakra-ui/react';
import { EditIcon, ExternalLinkIcon, ArrowUpIcon } from '@chakra-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getMetrics } from '../../features/metrics/metricsAction';
import { getUserDetails } from '../../features/UserData/userActions';
import { FaPage4 } from 'react-icons/fa';
import { Cloud } from 'lucide-react';

const PlanAndBilling = () => {
  const dispatch = useDispatch();
  const { metrics, graphData, loading, error } = useSelector(
    (state) => state.metrics
  );
  const { userDetails } = useSelector((state) => state.user);
  const selectedAssistant = useSelector((state) => state.assistants.selectedAssistant);

  useEffect(() => {
    if (selectedAssistant) {
      dispatch(getMetrics(selectedAssistant.project_id));
    }
  }, [dispatch, selectedAssistant]);

  useEffect(() => {
    if (!userDetails && !loading && !error) {
      dispatch(getUserDetails());
    }
  }, [dispatch]);

  const requests = userDetails?.plan_details?.features?.requests || 0;
  const chatCount = userDetails?.chat_count || 0;
  const remainingRequests = requests - chatCount;

  return (
    <Box p={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading>Plan & Billings</Heading>
        <Button size="sm" leftIcon={<EditIcon />}>
          {userDetails?.plan} Plan
        </Button>
      </Flex>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6} mb={6}>
        <Box p={5} borderWidth="1px" borderRadius="md" borderColor="gray.400">
          <VStack align="start">
            <Heading size="md">Credits</Heading>
            <Text>Credits available</Text>
            <Text fontSize="2xl" fontWeight="bold">{remainingRequests}</Text>
          </VStack>
        </Box>
        <Box p={5} borderWidth="1px" borderRadius="md" borderColor="gray.400">
          <VStack align="start">
            <Heading size="md">Pages</Heading>
            <VStack align="start">
            <Text>Count</Text>
            <Text fontSize="2xl" fontWeight={'bold'}>
              {userDetails?.pages_count} / {userDetails?.plan_details?.features?.pagesStored}
            </Text>
          </VStack>
            {/* <Button mt={2} colorScheme="green">Pay by invoice</Button> */}
          </VStack>
        </Box>
        <Box p={5} borderWidth="1px" borderRadius="md" borderColor="gray.400">
          <VStack align="start">
            <Heading size="md">Assistants</Heading>
            <Text>Count</Text>
            <Text fontSize="2xl" fontWeight="bold">{userDetails?.assistant_count}/{userDetails?.plan_details?.features?.assistantCount}</Text>
          </VStack>
        </Box>
      </SimpleGrid>

      <Box mb={6} p={5} borderWidth="1px" borderRadius="md" borderColor="gray.400">
        <Heading size="md" mb={4}>Plan Summary</Heading>
        <Text mb={4}>Your current plan and usage across this billing cycle</Text>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <HStack>
            <Icon as={ArrowUpIcon} />
            <Text>Plan</Text>
          </HStack>
          <Button size="sm" rightIcon={<ArrowUpIcon />}>Upgrade</Button>
        </Flex>
        <Text mb={2}> {userDetails?.chat_count} of {userDetails?.plan_details?.features?.requests} Monthly Requests </Text>
        <Progress max={userDetails?.plan_details?.features?.requests} value={userDetails?.chat_count} size="sm" colorScheme="teal" mb={4} />
        <Text mb={2}>{userDetails?.assistant_count} of {userDetails?.plan_details?.features?.assistantCount} Assistants Created</Text>
        <Progress max={userDetails?.plan_details?.features?.assistantCount} value={userDetails?.assistant_count} size="sm" colorScheme="teal" />
      </Box>

      <Box>
        <Flex justifyContent="space-between" alignItems="center" mb={4}>
          <Heading size="md">Credits used</Heading>
          <HStack spacing={4}>
            <Select size="sm">
              <option>User</option>
              <option>User 1</option>
              <option>User 2</option>
            </Select>
            <Select size="sm">
              <option>Past 6 months</option>
              <option>Past 3 months</option>
              <option>Past 1 month</option>
            </Select>
          </HStack>
        </Flex>

        <Box borderWidth="1px" borderRadius="md" borderColor="gray.400" overflow="hidden">
          <Table variant="simple">
            <Thead bg={useColorModeValue('gray.100', 'gray.700')}>
              <Tr>
                <Th>Items</Th>
                <Th>Credits</Th>
                <Th>Run by</Th>
                <Th>Date/time</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td colSpan={4} textAlign="center">
                  No results...
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Box>
    </Box>
  );
};

export default PlanAndBilling;
