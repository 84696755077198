import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  IconButton,
  useColorModeValue,
  Center,
  Icon,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  TableContainer,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, RepeatIcon, ViewIcon } from '@chakra-ui/icons';
import { FaDatabase } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { CheckCircle, XCircle } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIngestionSourcesAsync, deleteSourceAsync, syncSourceAsync } from '../../features/DataIngestion/DataIngestionAction';

const ResourceHubPage = () => {
  const dispatch = useDispatch();
  const { selectedAssistant } = useSelector((state) => state.assistants);
  const { ingestionHistory, loading, error } = useSelector((state) => state.ingestsource);
  const borderColor = useColorModeValue('gray.400', 'gray.700');
  const tableBgColor = useColorModeValue('gray.100', 'gray.700');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [selectedPayload, setSelectedPayload] = useState(null);

  useEffect(() => {
    if (selectedAssistant) {
      dispatch(fetchIngestionSourcesAsync(selectedAssistant.project_id));
    }
  }, [dispatch, selectedAssistant]);

  const handleDelete = (collectionName) => {
    dispatch(deleteSourceAsync(collectionName))
      .unwrap()
      .then(() => {
        toast({
          title: 'Success Deleted',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        dispatch(fetchIngestionSourcesAsync(selectedAssistant.project_id));
      })
      .catch((error) => {
        toast({
          title: 'Error',
          description: error?.response?.data?.detail || error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      });
  };

  const handleSync = (collectionName) => {
    dispatch(syncSourceAsync(collectionName));
  };

  const handleView = (payload) => {
    setSelectedPayload(payload);
    onOpen();
  };

  const renderPayloadTable = (payload) => {
    return (
      <TableContainer>
        <Table variant="simple">
          <Tbody>
            {Object.entries(payload).map(([key, value]) => (
              <Tr key={key}>
                <Th>{key}</Th>
                <Td>{typeof value === 'object' ? JSON.stringify(value, null, 2) : value}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box p={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading>Resource Hub</Heading>
        <Button colorScheme="teal" as={Link} to="/sources" leftIcon={<AddIcon />}>
          Add Source
        </Button>
      </Flex>

      {loading ? (
        <Center h="60vh">
          <Spinner size="xl" />
        </Center>
      ) : error ? (
        <Center h="60vh">
          <Text fontSize="xl" color="red.500">Error: {error}</Text>
        </Center>
      ) : (ingestionHistory?.length > 0) ? (
        <Box borderWidth="1px" borderRadius="md" overflow="hidden">
          <Table variant="simple">
            <Thead bg={tableBgColor}>
              <Tr>
                <Th>NAME</Th>
                <Th>CONNECTOR</Th>
                <Th>STATUS</Th>
                <Th>LAST UPDATED</Th>
                <Th>SYNC</Th>
                <Th>DELETE</Th>
                <Th>VIEW</Th>
              </Tr>
            </Thead>
            <Tbody>
              {ingestionHistory.map((resource, index) => (
                <Tr key={index}>
                  <Td>{resource?.payload.source_name}</Td>
                  <Td>{resource?.source}</Td>
                  <Td>
                    {resource.status === 'active' && (
                      <Icon as={CheckCircle} boxSize={6} color="green.500" />
                    )}
                    {resource.status === 'failed' && (
                      <Icon as={XCircle} boxSize={6} color="red.500" />
                    )}
                    {resource.status === 'uploading' && <Spinner size="sm" />}
                  </Td>
                  <Td>{new Date(resource?.timestamp).toLocaleString()}</Td>
                  <Td>
                    <IconButton 
                      icon={<RepeatIcon />} 
                      aria-label="Sync" 
                      size="sm" 
                      onClick={() => handleSync(resource.source_id)} 
                    />
                  </Td>
                  <Td>
                    <IconButton 
                      icon={<DeleteIcon />} 
                      aria-label="Delete" 
                      size="sm" 
                      onClick={() => handleDelete(resource.source_id)} 
                    />
                  </Td>
                  <Td>
                    <IconButton
                      icon={<ViewIcon />}
                      aria-label="View"
                      size="sm"
                      onClick={() => handleView(resource.payload)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      ) : (
        <Center h="60vh" flexDirection="column">
          <Icon as={FaDatabase} boxSize={12} mb={4} />
          <Text fontSize="xl" fontWeight="bold">You haven't added any knowledge</Text>
          <Text mb={4}> Add knowledge that can be given to your agents via custom tools.</Text>
          <Button colorScheme="teal" leftIcon={<AddIcon />} as={Link} to="/sources">Add Resource</Button>
        </Center>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Source Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedPayload ? renderPayloadTable(selectedPayload) : <Text>No payload data available</Text>}
          </ModalBody>
          <ModalFooter>
            <Button size={'sm'} colorScheme="teal" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ResourceHubPage;