import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, SimpleGrid, Flex, Text, Stat, StatLabel, StatNumber, StatHelpText, useColorModeValue } from '@chakra-ui/react';
import { FiUsers } from 'react-icons/fi';
import { BsChatQuote } from "react-icons/bs";
import { GrLike, GrDislike } from "react-icons/gr";
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { faker } from '@faker-js/faker';
import { getMetrics, getGraphData } from '../../features/metrics/metricsAction';
import { getUserDetails } from '../../features/UserData/userActions';
import { Facebook, Twitter, Instagram, Linkedin } from 'lucide-react';
import { WidgetInstallation } from '../../components/Home/Installation';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { selectedAssistant, userDetails } = useSelector((state) => state.assistants);
  const { metrics, graphData, loading, error } = useSelector((state) => state.metrics);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (!userDetails) {
          const userDetails = await dispatch(getUserDetails()).unwrap();
          const { assistant_count } = userDetails;
          setIsLoading(false);
          if (assistant_count === 0) {
            navigate("/get-started", { replace: true });
          } else if (selectedAssistant) {
            dispatch(getMetrics(selectedAssistant.project_id));
            dispatch(getGraphData(selectedAssistant.project_id));
          }
        } else {
          const { assistant_count } = userDetails;
          setIsLoading(false);
          if (assistant_count === 0) {
            navigate("/get-started", { replace: true });
          } else if (selectedAssistant) {
            dispatch(getMetrics(selectedAssistant.project_id));
            dispatch(getGraphData(selectedAssistant.project_id));
          }
        }
      } catch (error) {
        console.error("Failed to fetch user details:", error);
        setIsLoading(false);
      }
    };

    fetchUserDetails();
  }, [dispatch, navigate, selectedAssistant, userDetails]);

  const borderColor = useColorModeValue('gray.300', 'gray.700');

  const chartData = {
    labels: graphData.labels.length ? graphData.labels : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: graphData.label,
        data: graphData.data.length ? graphData.data : Array.from({ length: 12 }, () => faker.finance.amount(0, 0, 0)),
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
    ],
  };

  if (isLoading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box p={5}>
      <SimpleGrid columns={{ sm: 2, md: 4 }} spacing="6" mb="6">
        <StatCard
          icon={BsChatQuote}
          title="Chat Messages"
          value={metrics.chats_count.toLocaleString()}
          change={metrics.chats_percentage_change}
          changeColor={metrics.chats_percentage_change >= 0 ? 'green.500' : 'red.500'}
          borderColor={borderColor}
        />
        <StatCard
          icon={FiUsers}
          title="Chat Sessions"
          value={metrics.sessions_count.toLocaleString()}
          change={metrics.sessions_percentage_change}
          changeColor={metrics.sessions_percentage_change >= 0 ? 'green.500' : 'red.500'}
          borderColor={borderColor}
        />
        <StatCard
          icon={GrLike}
          title="Helpful Answers"
          value={metrics.positive_feedback_count.toLocaleString()}
          change={metrics.positive_feedback_percentage_change}
          changeColor={metrics.positive_feedback_percentage_change >= 0 ? 'green.500' : 'red.500'}
          borderColor={borderColor}
        />
        <StatCard
          icon={GrDislike}
          title="Disliked Answers"
          value={metrics.negative_feedback_count.toLocaleString()}
          change={metrics.negative_feedback_percentage_change}
          changeColor={metrics.negative_feedback_percentage_change >= 0 ? 'green.500' : 'red.500'}
          borderColor={borderColor}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing="6" mb="6">
        <Box p={5} borderWidth="1px" borderRadius="md" borderColor={borderColor}>
          <Text fontSize="xl" mb="4">Overview</Text>
          <Bar data={chartData} />
        </Box>
        <Box p={5} borderWidth="1px" borderRadius="md" borderColor={borderColor}>
          <Text fontSize="xl" mb="3">Integration</Text>
          <Text mb="4">Copy and paste the script below into your HTML page to seamlessly integrate the widget.</Text>
          <WidgetInstallation />
        </Box>
      </SimpleGrid>

      <Footer />
    </Box>
  );
};

const StatCard = ({ icon, title, value, change = 0, changeColor, borderColor }) => {
  const formattedChange = isNaN(change) ? '0% from last week' : `${change >= 0 ? '+' : ''}${parseFloat(change).toFixed(2)}% from last week`;

  return (
    <Box p={5} borderWidth="1px" borderRadius="md" textAlign="center" borderColor={borderColor}>
      <Stat>
        <StatLabel display="flex" alignItems="center" justifyContent="center">
          {React.createElement(icon, { size: '24px', style: { marginRight: '8px' } })}
          {title}
        </StatLabel>
        <StatNumber>{value}</StatNumber>
        <StatHelpText color={changeColor}>{formattedChange}</StatHelpText>
      </Stat>
    </Box>
  );
};

const Footer = () => {
  const borderColor = useColorModeValue('gray.300', 'gray.700');
  return (
    <Box
      as="footer"
      role="contentinfo"
      mt={10}
      py="6"
      px={{ base: '4', md: '8' }}
      borderTopWidth="1px"
      borderColor={borderColor}
      textAlign="center"
    >
      <Text mb="2">Follow us on</Text>
      <Flex justify="center" mb="4">
        <Box as="a" href="https://facebook.com" mx="2">
          <Facebook />
        </Box>
        <Box as="a" href="https://x.com/nextai_official" mx="2">
          <Twitter />
        </Box>
        <Box as="a" href="https://www.instagram.com/nextaiofficial?igsh=aXUxYzdvejdhOGQ3" mx="2">
          <Instagram />
        </Box>
        <Box as="a" href="https://www.linkedin.com/company/nextaii" mx="2">
          <Linkedin />
        </Box>
      </Flex>
      <Text fontSize="sm">© {new Date().getFullYear()} SearchGPT. All rights reserved.</Text>
    </Box>
  );
};

export default HomePage;