import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

export const fetchEvaluationMetricsApi = async ({ project_id, startDate, endDate }, thunkAPI) => {
  try {
    const authToken = localStorage.getItem('token');
    const url = new URL(`${BASE_URL}/metrics/evaluation-metrics/${project_id}`);
    
    if (startDate) url.searchParams.append('start_date', startDate);
    if (endDate) url.searchParams.append('end_date', endDate);

    const response = await axios.get(url.toString(), {
      headers: {
        'Authorization': `Bearer ${authToken}`,
      },
    });
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
};