import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAssistantsAsync,
  createAssistantAsync,
  fetchAssistantDetailsAsync,
  fetchProjectDetailsAsync,
  updateAssistantAsync,
  deleteAssistantAsync,
  updateProjectDetailsAsync,
} from './AssistantAction';

const initialState = {
  assistants: [],
  selectedAssistant: null,
  details: null,
  project: null,
  loading: false,
  error: null,
  createStatus: null,
  updateStatus: null,
};

const assistantsSlice = createSlice({
  name: 'assistants',
  initialState,
  reducers: {
    setSelectedAssistant: (state, action) => {
      state.selectedAssistant = action.payload;
    },
    clearSelectedAssistant: (state) => {
      state.selectedAssistant = null;
      state.details = null;
      state.project = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssistantsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAssistantsAsync.fulfilled, (state, action) => {
        state.assistants = action.payload;
        state.loading = false;
      })
      .addCase(fetchAssistantsAsync.rejected, (state, action) => {
        state.error = action.payload || 'Failed to fetch assistants';
        state.loading = false;
      })
      .addCase(createAssistantAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createAssistantAsync.fulfilled, (state, action) => {
        state.assistants.push(action.payload);
        state.createStatus = action.payload.message;
        state.loading = false;
      })
      .addCase(createAssistantAsync.rejected, (state, action) => {
        state.error = action.payload.message || 'Failed to create assistant';
        state.loading = false;
      })
      .addCase(fetchAssistantDetailsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAssistantDetailsAsync.fulfilled, (state, action) => {
        state.details = action.payload;
        state.loading = false;
      })
      .addCase(fetchAssistantDetailsAsync.rejected, (state, action) => {
        state.error = action.payload || 'Failed to fetch assistant details';
        state.loading = false;
      })
      .addCase(fetchProjectDetailsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProjectDetailsAsync.fulfilled, (state, action) => {
        state.project = action.payload;
        state.loading = false;
      })
      .addCase(fetchProjectDetailsAsync.rejected, (state, action) => {
        state.error = action.payload || 'Failed to fetch project details';
        state.loading = false;
      })
      .addCase(updateAssistantAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAssistantAsync.fulfilled, (state, action) => {
        state.updateStatus = 'success';
        state.loading = false;
      })
      .addCase(updateAssistantAsync.rejected, (state, action) => {
        state.error = action.payload || 'Failed to update assistant';
        state.updateStatus = 'failed';
        state.loading = false;
      })
      .addCase(deleteAssistantAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAssistantAsync.fulfilled, (state, action) => {
        state.assistants = state.assistants.filter(
          assistant => assistant.id !== action.meta.arg
        );
        state.details = null;
        state.loading = false;
      })
      .addCase(deleteAssistantAsync.rejected, (state, action) => {
        state.error = action.payload || 'Failed to delete assistant';
        state.loading = false;
      })
      .addCase(updateProjectDetailsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateProjectDetailsAsync.fulfilled, (state, action) => {
        state.projectDetails = { ...state.projectDetails, ...action.payload };
        state.updateStatus = 'success';
        state.loading = false;
      })
      .addCase(updateProjectDetailsAsync.rejected, (state, action) => {
        state.error = action.payload || 'Failed to update project details';
        state.updateStatus = 'failed';
        state.loading = false;
      });
  },
});

export const { setSelectedAssistant, clearSelectedAssistant } = assistantsSlice.actions;
export default assistantsSlice.reducer;
