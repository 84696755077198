import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  Tag,
  Badge,
  IconButton,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Skeleton,
} from "@chakra-ui/react";
import { Edit3, Trash2 } from "lucide-react";
import { FaRobot } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAssistantAsync,
  fetchAssistantsAsync,
  fetchAssistantDetailsAsync,
} from "../../features/Assistant/AssistantAction";
import { useNavigate } from "react-router-dom";
import { WidgetEdit } from "../Update/AssistantUpdate";

export default function Head() {
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const textColor = useColorModeValue("gray.900", "teal.200");
  const iconColor = useColorModeValue("gray.400", "gray.500");
  const idTextColor = useColorModeValue("gray.500", "gray.100");
  const toast = useToast();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { selectedAssistant } = useSelector((state) => state.assistants);

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const cancelRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const onAlertOpen = () => setIsAlertOpen(true);
  const onAlertClose = () => setIsAlertOpen(false);

  const onModalOpen = () => setIsModalOpen(true);
  const onModalClose = () => {
    setIsModalOpen(false);
    dispatch(fetchAssistantDetailsAsync({ projectId: selectedAssistant?.project_id, token }));
  };

  const handleDeleteAssistant = async () => {
    try {
      await dispatch(deleteAssistantAsync(selectedAssistant.assistant_id)).unwrap();
      toast({
        title: "Delete successful",
        description: `Assistant ${selectedAssistant.name} has been deleted successfully.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onAlertClose();
      navigate("/");
      await dispatch(fetchAssistantsAsync(token));
    } catch (error) {
      toast({
        title: "Delete failed",
        description: `Failed to delete assistant ${selectedAssistant.name}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      border="1px solid"
      borderColor={borderColor}
      borderRadius="md"
      p={6}
      bg={bgColor}
      mt={4}
    >
      <Flex
        direction={{ base: "column", lg: "row" }}
        align={{ lg: "center" }}
        justify="space-between"
      >
        <Box flex="1" minW="0">
          <HStack>
            <Flex
              height="50px"
              width="50px"
              bg="gray.100"
              borderRadius="10px"
              mr={1}
              align="center"
              justify="center"
            >
              <Icon as={FaRobot} boxSize="20px" color={iconColor} />
            </Flex>
            <Skeleton isLoaded={!isLoading} flex="1">
              <Heading
                as="h2"
                size="xl"
                fontWeight="bold"
                isTruncated
                color={textColor}
              >
                {selectedAssistant?.name}
              </Heading>
            </Skeleton>
          </HStack>
          <Flex
            mt="4"
            direction={{ base: "column", sm: "row" }}
            flexWrap="wrap"
            spacing="6"
          >
            <Skeleton isLoaded={!isLoading} flex="1">
              <HStack mt="2" textColor={idTextColor} spacing="1" mr={2}>
                <Text color={textColor}>ID</Text>
                <Text>{selectedAssistant?.project_id}</Text>
              </HStack>
            </Skeleton>
            <Skeleton isLoaded={!isLoading} flex="1">
              <HStack mt="2" textColor={idTextColor} spacing="1" mr={2}>
                <Text color={textColor}>Type</Text>
                <Text>{selectedAssistant?.type}</Text>
              </HStack>
            </Skeleton>
            <Skeleton isLoaded={!isLoading} flex="1">
              <HStack mt="2" textColor={idTextColor} spacing="1" mr={2}>
                <Text color={textColor}>Shape</Text>
                <Text>{selectedAssistant?.shape}</Text>
              </HStack>
            </Skeleton>
            <Skeleton isLoaded={!isLoading} flex="1">
              <HStack mt="2" textColor={idTextColor} spacing="1" mr={2}>
                <Text color={textColor}>Project URL</Text>
                <Tag>{selectedAssistant?.project_url || "None"}</Tag>
              </HStack>
            </Skeleton>
          </Flex>
        </Box>
        <Flex mt={{ base: "5", lg: "0" }} ml={{ lg: "4" }}>
          <Button
            display={{ base: "none", sm: "inline-flex" }}
            leftIcon={<Icon as={Edit3} w="5" h="5" textColor={iconColor} />}
            colorScheme="teal"
            variant="outline"
            size="sm"
            onClick={onModalOpen}
          >
            Edit
          </Button>
          <IconButton
            ml="3"
            colorScheme="red"
            size="sm"
            icon={<Trash2 size={"16"} />}
            onClick={onAlertOpen}
          />
        </Flex>
      </Flex>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={onAlertClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Assistant
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete assistant {selectedAssistant?.name}? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDeleteAssistant} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* Modal for WidgetEdit */}
      <Modal isOpen={isModalOpen} onClose={onModalClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Assistant</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <WidgetEdit projectId={selectedAssistant?.project_id} onClose={onModalClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
