import { createSlice } from '@reduxjs/toolkit';
import { getMetrics, getGraphData } from './metricsAction'; 

const initialState = {
  metrics: {
    chats_count: 0,
    sessions_count: 0,
    positive_feedback_count: 0,
    negative_feedback_count: 0,
  },
  graphData: {
    labels: [],
    data: [],
    label: "Chat Messages"
  },
  loading: false,
  error: null
};

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    resetMetrics(state) {
      state.metrics = initialState.metrics;
    },
    resetGraphData(state) {
      state.graphData = initialState.graphData;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMetrics.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMetrics.fulfilled, (state, action) => {
        state.loading = false;
        state.metrics = action.payload;
      })
      .addCase(getMetrics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getGraphData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGraphData.fulfilled, (state, action) => {
        state.loading = false;
        state.graphData = action.payload;
      })
      .addCase(getGraphData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const { resetMetrics, resetGraphData } = metricsSlice.actions;
export default metricsSlice.reducer;
