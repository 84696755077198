import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
  Heading,
  Text,
  IconButton,
  InputGroup,
  Flex,
  Input,
  InputLeftElement,
  Badge,
  TableCaption,
  Button,
  Select,
  Spinner,
  Center,
  Tooltip,
  TableContainer,
} from '@chakra-ui/react';
import { FiSearch, FiTrash2, FiEdit3, FiCalendar } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { getCorrections } from '../../features/history/historyAction';

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <Button onClick={onClick} ref={ref} leftIcon={<FiCalendar />} colorScheme="gray" variant="outline">
    {value}
  </Button>
));

const ChatHistory = () => {
  const dispatch = useDispatch();
  const { corrections, loading, error } = useSelector((state) => state.history);
  const { selectedAssistant } = useSelector((state) => state.assistants);
  
  const [startDate, setStartDate] = useState(new Date(new Date().setMonth(new Date().getMonth() - 1)));
  const [endDate, setEndDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    if (selectedAssistant) {
      dispatch(getCorrections({ projectId: selectedAssistant.project_id, startDate, endDate }));
    }
  }, [dispatch, selectedAssistant, startDate, endDate]);

  const handlePageChange = (page) => setCurrentPage(page);

  const handleFilter = () => {
    if (selectedAssistant) {
      dispatch(getCorrections({ projectId: selectedAssistant.project_id, startDate, endDate }));
      setCurrentPage(1);
    }
  };

  const paginatedData = corrections.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  if (loading) {
    return (
      <Center h="60vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  // if (error) {
  //   return (
  //     <Center h="60vh">
  //       <Text fontSize="xl" color="red.500">Error: {error}</Text>
  //     </Center>
  //   );
  // }

  return (
    <Box mt={4} p={5} borderWidth="1px" borderRadius="md" borderColor="gray.300">
      <Box mb={4}>
        <HStack justifyContent="space-between">
          <Heading size="lg">Chat History</Heading>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <FiSearch color="gray.300" />
            </InputLeftElement>
            <Input type="text" placeholder="Search" />
          </InputGroup>
        </HStack>
        <HStack mt={4} spacing={4}>
          <Box>
            <Text mb={2}>Start Date</Text>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              customInput={<CustomInput />}
            />
          </Box>
          <Box>
            <Text mb={2}>End Date</Text>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              customInput={<CustomInput />}
            />
          </Box>
          <Button size={'sm'} bg={'cyan.900'} _hover={{ bg: "cyan.700" }} color={'white'} ml={4} onClick={handleFilter}>Filter</Button>
        </HStack>
      </Box>
      <TableContainer>
        {loading ? (
          <Box textAlign="center" p={6}>
            <Text fontSize="lg" fontWeight="bold">
              Loading... <Spinner size={"sm"} />
            </Text>
          </Box>
        // ) : error ? (
        //   <Box textAlign="center" p={6}>
        //     <Text fontSize="lg" fontWeight="bold">
        //       {error}
        //     </Text>
        //   </Box>
        ) : corrections.length > 0 ? (
          <Table variant="simple" size={"md"}>
            <TableCaption>Chat history</TableCaption>
            <Thead>
              <Tr>
                <Th>Query</Th>
                <Th>Reply</Th>
                <Th>Date</Th>
                <Th>Feedback</Th>
                {/* <Th>Actions</Th> */}
              </Tr>
            </Thead>
            <Tbody>
              {paginatedData.map((correction, index) => (
                <Tr key={index}>
                  <Td fontSize={12}>
                    <Tooltip hasArrow label={correction.question} placement="top">
                      <Box
                        fontSize={12}
                        maxWidth="200px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        _hover={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        {correction.question}
                      </Box>
                    </Tooltip>
                  </Td>
                  <Td fontSize={12}>
                    <Tooltip hasArrow label={correction.correction} placement="top">
                      <Box
                        fontSize={12}
                        maxWidth="300px"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        _hover={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                      >
                        <Text fontSize={12}>{correction.correction}</Text>
                      </Box>
                    </Tooltip>
                  </Td>
                  <Td fontSize={12}>{correction.dateCreated ? new Date(correction.dateCreated).toLocaleDateString() : 'No date'}</Td>
                  <Td fontSize={12}>
                    <Box
                      bg={
                        correction.feedback === "Positive"
                          ? "green.300"
                          : correction.feedback === "Negative"
                          ? "red.300"
                          : "gray.300"
                      }
                      borderRadius="md"
                      color={
                        correction.feedback === "Positive"
                          ? "green.800"
                          : correction.feedback === "Negative"
                          ? "red.800"
                          : "gray.800"
                      }
                      fontSize={12}
                      fontWeight="bold"
                      px={2}
                      py={1}
                      textAlign="center"
                      whiteSpace="nowrap"
                    >
                      {correction.feedback}
                    </Box>
                  </Td>
                  {/* <Td>
                    <IconButton icon={<FiEdit3 />} aria-label="Edit" size="sm" mr="2" />
                  </Td> */}
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          !loading && (
            <Box textAlign="center" p={6}>
              <Text fontSize="lg" fontWeight="bold">
                No conversations found.
              </Text>
            </Box>
          )
        )}
      </TableContainer>
      <Flex justify="space-between" align="center" mt={4}>
        <Text>Showing {currentPage * itemsPerPage - itemsPerPage + 1} to {Math.min(currentPage * itemsPerPage, corrections.length)} of {corrections.length} results</Text>
        <HStack>
          <Button onClick={() => handlePageChange(currentPage - 1)} isDisabled={currentPage === 1}>Previous</Button>
          <Select value={currentPage} onChange={(e) => handlePageChange(Number(e.target.value))}>
            {Array(Math.ceil(corrections.length / itemsPerPage))
              .fill('')
              .map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
          </Select>
          <Button onClick={() => handlePageChange(currentPage + 1)} isDisabled={currentPage === Math.ceil(corrections.length / itemsPerPage)}>Next</Button>
        </HStack>
      </Flex>
    </Box>
  );
};

export default ChatHistory;
