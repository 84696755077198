import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Shell from './layout/shell';
import Home from './pages/app/Home';
import ResourceHub from './pages/app/ResourcePage';
import AgentsHub from './pages/app/AgentsHub';
import ChatHistory from './pages/app/ChatHistory';
import CorrectionsPage from './pages/app/Corrections';
import IntegrationPage from './pages/app/IntegrationsPage';
import { Playground } from './pages/app/PlaygroundPage';
import PlanAndBilling from './pages/app/PlanPage';
import InvoicesPage from './pages/app/InvoicePage';
import SettingsPage from './pages/app/Settings';
import LoginPage from './pages/auth/Login';
import RegisterPage from './pages/auth/Register';
import GetStarted from './pages/onboarding/GetStarded';
import NewAssistants from './pages/app/NewAssistant';
import { AuthProvider } from './contexts/AuthContext';
import ProjectDetail from './pages/app/Customization';
import AddSources from './pages/app/AddSources';
import NotionForm from './components/AddSources/SourceForms/NotionForm';
import WebsiteForm from './components/AddSources/SourceForms/WebsiteForm';
import ConfluenceForm from './components/AddSources/SourceForms/ConfluenceForm';
import ShopifyForm from './components/AddSources/SourceForms/ShopifyForm';
import GitHubForm from './components/AddSources/SourceForms/GitHubForm';
import PostgresForm from './components/AddSources/SourceForms/PostgresForm';
import DocumentForm from './components/AddSources/SourceForms/DocumentForm';
import PostgresAgentForm from './components/Agents/AgentsForm/PostgresForm';
import AgentType from './pages/app/AgentTypes';
import theme from './theme';
import AuthenticatedRoute from './contexts/AuthenticatedRoute';
import PublicRoute from './contexts/PublicRoute';
import VerifyEmail from './pages/auth/EmailVerify';
import useClarity from './components/Tracking/useClarity';

const App = () => {
  useClarity();
  
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<PublicRoute element={<LoginPage />} />} />
            <Route path="/signup" element={<PublicRoute element={<RegisterPage />} />} />
            <Route path="/auth/verify-email" element={<PublicRoute element={<VerifyEmail />} />} />
            <Route
              path="/*"
              element={
                <AuthenticatedRoute
                  element={
                    <Shell>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/get-started" element={<GetStarted />} />
                        <Route path="/resources" element={<ResourceHub />} />
                        <Route path="/agents" element={<AgentsHub />} />
                        <Route path="/history" element={<ChatHistory />} />
                        <Route path="/corrections" element={<CorrectionsPage />} />
                        <Route path="/integrations" element={<IntegrationPage />} />
                        <Route path="/playground" element={<Playground />} />
                        <Route path="/plan" element={<PlanAndBilling />} />
                        <Route path="/invoices" element={<InvoicesPage />} />
                        <Route path="/settings" element={<SettingsPage />} />
                        <Route path="/new-assistant" element={<NewAssistants />} />
                        <Route path="/project/:id" element={<ProjectDetail />} />
                        <Route path="/sources" element={<AddSources />} />
                        <Route path="/sources/github" element={<GitHubForm />} />
                        <Route path="/sources/notion" element={<NotionForm />} />
                        <Route path="/sources/confluence" element={<ConfluenceForm />} />
                        <Route path="/sources/website" element={<WebsiteForm />} />
                        <Route path="/sources/documents" element={<DocumentForm />} />
                        <Route path="/sources/shopify" element={<ShopifyForm />} />
                        <Route path="/sources/postgres" element={<PostgresForm />} />
                        <Route path="/agents/new-agent" element={<AgentType />} />
                        <Route path="/agents/new-agent/postgres" element={<PostgresAgentForm />} />
                      </Routes>
                    </Shell>
                  }
                />
              }
            />
          </Routes>
        </AuthProvider>
      </Router>
    </ChakraProvider>
  );
};

export default App;
