import { createSlice } from '@reduxjs/toolkit';
import { getCorrections } from './historyAction';

const initialState = {
  corrections: [],
  loading: false,
  error: null,
};

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCorrections.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.corrections = []; // Clear previous data while loading new data
      })
      .addCase(getCorrections.fulfilled, (state, action) => {
        state.corrections = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCorrections.rejected, (state, action) => {
        state.error = action.payload || action.error.message;
        state.loading = false;
      });
  },
});

export default historySlice.reducer;
