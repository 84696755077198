import axios from "axios";
const url = process.env.REACT_APP_API_URL;
const authToken = localStorage.getItem('token');

export const getCorrectedAnswerApi = async (projectId, thunkAPI) => {
    try {
      const response = await axios.get(
        `${url}/correction/corrections/${projectId}`,
        {
          headers: {
            authorization: `Bearer ${authToken}`,
          },
        }
      );
      return response.data; // Assuming response.data is the array of corrections
    } catch (error) {
      console.error("API Error:", error.response ? error.response.data : error.message);
      return thunkAPI.rejectWithValue({
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
      });
    }
  };

export const updateRightQuestionToAnswerApi = async (data, thunkAPI) => {
  try {
    const state = thunkAPI.getState();
    const response = await axios.post(
      `${url}/correction/update/`,
      data,
      {
        headers: {
          authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data.response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response);
  }
};
