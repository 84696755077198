import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  Button,
  Input,
  VStack,
  HStack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  Divider,
  Spinner,
  Avatar,
  Alert,
  AlertIcon,
  useColorMode,
} from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserDetails, uploadProfileImage, getUserDetails } from '../../features/UserData/userActions';
import ApiKeyGeneration from '../../components/Settings/ApiKey';

const ProfileSettings = () => {
  const { colorMode } = useColorMode();
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => handleImageUpload(acceptedFiles[0]),
  });
  const dispatch = useDispatch();
  const { userDetails, loading, error } = useSelector(state => state.user);

  const [formValues, setFormValues] = useState({
    username: userDetails?.username,
    email: userDetails?.email,
    current_role: userDetails?.current_role,
  });

  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    dispatch(getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    if (userDetails) {
      setFormValues({
        username: userDetails.username,
        email: userDetails.email,
        current_role: userDetails.current_role,
      });
    }
  }, [userDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleImageUpload = (file) => {
    dispatch(uploadProfileImage(file));
    dispatch(getUserDetails());
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const response = await dispatch(updateUserDetails(formValues));
    if (response.meta.requestStatus === 'fulfilled') {
      setSuccessMessage("User details updated successfully");
    } else {
      setSuccessMessage("");
    }
    dispatch(getUserDetails());
  };

  return (
    <Box p={5} borderWidth="1px" borderRadius="md" borderColor="gray.300" bg={colorMode === 'light' ? 'gray.100' : 'gray.800'}>
      <Heading size="md" mb={4}>Profile Settings</Heading>
      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      {successMessage && (
        <Alert status="success" mb={4}>
          <AlertIcon />
          {successMessage}
        </Alert>
      )}
      <VStack spacing={4} align="start" as="form" onSubmit={handleFormSubmit}>
        <FormControl>
          <FormLabel>Profile Picture</FormLabel>
          <Box
            {...getRootProps()}
            p={6}
            borderWidth="2px"
            borderRadius="md"
            borderStyle="dashed"
            borderColor="gray.300"
            textAlign="center"
            color="gray.500"
            cursor="pointer"
          >
            <input {...getInputProps()} />
            <Avatar src={userDetails?.profile_image_url} size="xl" mb={4} />
            <Text>Drop or drag file</Text>
            <Text fontSize="sm">jpg, jpeg, png</Text>
            <Text fontSize="xs">max size: 12mb</Text>
          </Box>
        </FormControl>
        <FormControl>
          <FormLabel>Username</FormLabel>
          <Input
            name="username"
            placeholder="Username"
            value={formValues.username}
            onChange={handleInputChange}
          />
          <Text fontSize="xs" color="gray.500">Your username uniquely identifies you on the platform</Text>
        </FormControl>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            name="email"
            placeholder="Email"
            value={formValues.email}
            isReadOnly
          />
          <Text fontSize="xs" color="gray.500">You cannot edit email.</Text>
        </FormControl>
        <FormControl>
          <FormLabel>Role</FormLabel>
          <Input
            name="current_role"
            placeholder="Role"
            value={formValues.current_role}
            onChange={handleInputChange}
          />
          <Text fontSize="xs" color="gray.500">Update your role in this company</Text>
        </FormControl>
        <Divider my={6} />
        <HStack spacing={4} justify="end">
          <Button variant="outline">Cancel</Button>
          <Button colorScheme="teal" type="submit">Update</Button>
        </HStack>
      </VStack>
    </Box>
  );
};

const SettingsPage = () => {
  return (
    <Box p={5}>
      <Heading mb={6}>Settings</Heading>
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Profile Settings</Tab>
          <Tab>API Keys</Tab>
          <Tab>Security</Tab>
          <Tab>Language and Region</Tab>
          <Tab>Data and Privacy</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ProfileSettings />
          </TabPanel>
          <TabPanel>
            <Box p={5} borderWidth="1px" borderRadius="md" borderColor="gray.300">
              <ApiKeyGeneration/>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box p={5} borderWidth="1px" borderRadius="md" borderColor="gray.300">
              <Text>Security content goes here...</Text>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box p={5} borderWidth="1px" borderRadius="md" borderColor="gray.300">
              <Text>Language and Region content goes here...</Text>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box p={5} borderWidth="1px" borderRadius="md" borderColor="gray.300">
              <Text>Data and Privacy content goes here...</Text>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default SettingsPage;
