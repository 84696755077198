import React from 'react';
import {
  Box,
  Center,
  useColorModeValue,
  Heading,
  Text,
  Stack,
  Image,
} from '@chakra-ui/react';

const ProductCard = ({ product }) => {
  const bgColor = useColorModeValue('white', 'gray.900');

  if (!product || Object.keys(product).length === 0) {
    return <Box>No product information available</Box>;
  }

  const handleButtonClick = () => {
    window.open(product.product_url, '_blank');
  };

  const {
    src: URL,
    title: Description,
    brand: BrandName,
    price: DiscountPrice,
    avg_rating: Ratings,
    product_url: link,
  } = product;

  const truncatedDescription = Description.length > 10 ? `${Description.substring(0, 10)}...` : Description;

  const formattedRatings = Ratings ? parseFloat(Ratings).toFixed(1) : 'N/A';

  return (
    <Center py={4} px={2} maxWidth={'400px'} marginX={'auto'}>
      <Box
        role={'group'}
        p={3}
        w={'full'}
        bg={bgColor}
        boxShadow={'lg'}
        rounded={'sm'}
        overflow={'hidden'}
        position={'relative'}
        _hover={{
          boxShadow: 'xl',
        }}
        onClick={handleButtonClick} 
        cursor={'pointer'} 
      >
        <Box
          rounded={'lg'}
          overflow={'hidden'}
          position={'relative'}
          height={'200px'}
        >
          <Image
            rounded={'sm'}
            height={'100%'}
            width={'100%'}
            objectFit={'cover'}
            src={URL || 'default-image-url'}
            alt={Description}
          />
        </Box>
        <Stack pt={4} align={'center'}>
        <Text mt={2} px={1} fontSize='0.85rem' textAlign={'center'} color='#808080' overflow='hidden' whiteSpace='nowrap' width="100%" textOverflow='ellipsis'>The Indian Garage Co.</Text>
          {/* <Text
            color={'gray.500'}
            fontSize={'sm'}
            textTransform={'uppercase'}
            fontWeight={600}
          >
            {BrandName}
          </Text> */}
          <Heading
            fontSize={'lg'}
            textAlign={'center'}
            fontFamily={'body'}
            fontWeight={700}
            lineHeight={1.2}
            mt={0}
          >
            {truncatedDescription}
          </Heading>
          <Text fontWeight={500} fontSize={'lg'} mt={0}>
            {`₹${DiscountPrice}`}
          </Text>
        </Stack>
      </Box>
    </Center>
  );
};

export default ProductCard;
