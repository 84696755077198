import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Heading,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Flex,
  IconButton,
  useColorModeValue,
  Center,
  Icon,
  Spinner,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, RepeatIcon, EditIcon } from '@chakra-ui/icons';
import { FaDatabase } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentConfig, deleteAgentConfig } from '../../features/Agents/AgentsAction';
import EditAgentModal from '../../components/Agents/EditAgent';

const truncateText = (text, length) => {
  if (text.length <= length) return text;
  return text.substring(0, length) + '...';
};

const AgentHub = () => {
  const dispatch = useDispatch();
  const { agentConfigs, loading, error } = useSelector((state) => state.agent);
  const selectedAssistant = useSelector((state) => state.assistants.selectedAssistant);
  const authToken = localStorage.getItem('token');
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const [modalContent, setModalContent] = useState('');
  const [modalHeader, setModalHeader] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAlertOpen,
    onOpen: onAlertOpen,
    onClose: onAlertClose,
  } = useDisclosure();
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose,
  } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  useEffect(() => {
    if (selectedAssistant?.type === 'askgpt' || selectedAssistant?.type === 'agent') {
      dispatch(getAgentConfig({ projectId: selectedAssistant.project_id, authToken }));
    }
  }, [dispatch, selectedAssistant, authToken]);

  const handleEditClick = (agent) => {
    setSelectedAgent(agent);
    onOpen();
  };

  const handleDeleteClick = (agent) => {
    setAgentToDelete(agent);
    onAlertOpen();
  };

  const confirmDelete = () => {
    dispatch(deleteAgentConfig({ agentId: agentToDelete.agentId, authToken }))
      .unwrap()
      .then(() => {
        toast({
          title: 'Success',
          description: `Agent ${agentToDelete.agentId.slice(0, 8)}... deleted successfully.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        dispatch(getAgentConfig({ projectId: selectedAssistant.project_id, authToken }));
        onAlertClose();
      })
      .catch((error) => {
        console.error(error);
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      });
  };

  const handleCellDoubleClick = (content, columnName) => {
    setModalContent(content);
    setModalHeader(columnName);
    onModalOpen();
  };

  const borderColor = useColorModeValue('gray.400', 'gray.700');
  const tableBgColor = useColorModeValue('gray.100', 'gray.700');

  const agents =
    agentConfigs?.map((agent) => ({
      agentId: agent.agent_id,
      type: agent.agent_type,
      uri: agent.postgres_uri,
      schemaTemplate: agent.extract_info_from_schema_template,
      textToSqlTemplate: agent.text_to_sql_template,
      sqlOutputPrompt: agent.sql_to_final_output_prompt,
      status: agent.status,
    })) || [];

  const noAgentsMessage =
    selectedAssistant?.type === 'agent'
      ? "Let's create a new agent!"
      : 'Please create an assistant which supports Agents.';

  return (
    <Box p={5}>
      <Flex justifyContent='space-between' alignItems='center' mb={6}>
        <Heading>Agents Hub</Heading>
        {(selectedAssistant?.type === 'agent' || selectedAssistant?.type === 'agent') && (
          <Button colorScheme='teal' as={Link} to='new-agent' leftIcon={<AddIcon />}>
            Add Agent
          </Button>
        )}
      </Flex>

      {loading ? (
        <Center h='60vh'>
          <Spinner size='xl' />
        </Center>
      ) : agents.length === 0 ? (
        <Center h='60vh' flexDirection='column'>
          <Icon as={FaDatabase} boxSize={12} mb={4} />
          <Text fontSize='xl' fontWeight='bold'>
            You haven't created any agents yet.
          </Text>
          <Text mb={4}>{noAgentsMessage}</Text>
          {(selectedAssistant?.type === 'agent' || selectedAssistant?.type === 'agent') && (
            <Button colorScheme='teal' as={Link} to='new-agent' leftIcon={<AddIcon />}>
              Create Agent
            </Button>
          )}
        </Center>
      ) : (
        <Box borderWidth='1px' borderRadius='md' overflow='hidden'>
          <Table variant='simple'>
            <Thead bg={tableBgColor}>
              <Tr>
                <Th>ID</Th>
                <Th>Type</Th>
                <Th>URI</Th>
                <Th>Schema Template</Th>
                <Th>Text to SQL Template</Th>
                <Th>SQL Output Prompt</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {agents.map((agent, index) => (
                <Tr key={index}>
                  <Td onDoubleClick={() => handleCellDoubleClick(agent.agentId, 'ID')}>
                    {truncateText(agent.agentId, 8)}
                  </Td>
                  <Td onDoubleClick={() => handleCellDoubleClick(agent.type, 'Type')}>
                    {truncateText(agent.type, 20)}
                  </Td>
                  <Td onDoubleClick={() => handleCellDoubleClick(agent.uri, 'URI')}>
                    {truncateText(agent.uri, 20)}
                  </Td>
                  <Td onDoubleClick={() => handleCellDoubleClick(agent.schemaTemplate, 'Schema Template')}>
                    {truncateText(agent.schemaTemplate, 20)}
                  </Td>
                  <Td onDoubleClick={() => handleCellDoubleClick(agent.textToSqlTemplate, 'Text to SQL Template')}>
                    {truncateText(agent.textToSqlTemplate, 20)}
                  </Td>
                  <Td onDoubleClick={() => handleCellDoubleClick(agent.sqlOutputPrompt, 'SQL Output Prompt')}>
                    {truncateText(agent.sqlOutputPrompt, 20)}
                  </Td>
                  <Td>
                    <IconButton
                      icon={<EditIcon />}
                      size='xs'
                      colorScheme='teal'
                      onClick={() => handleEditClick(agent)}
                      mr={2}
                    />
                    <IconButton
                      icon={<DeleteIcon />}
                      size='xs'
                      colorScheme='red'
                      onClick={() => handleDeleteClick(agent)}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
      {selectedAgent && (
        <EditAgentModal isOpen={isOpen} onClose={onClose} agent={selectedAgent} />
      )}
      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Delete Agent
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete agent {agentToDelete?.agentId}? This action cannot be undone.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button size='sm' ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button size='sm' colorScheme='red' onClick={confirmDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Modal isOpen={isModalOpen} onClose={onModalClose} size='2xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH='400px' overflowY='auto'>
            <Box whiteSpace='pre-wrap' wordWrap='break-word' fontSize='14px'>
              {modalContent}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button size={'sm'} colorScheme='teal' mr={3} onClick={onModalClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default AgentHub;
